import { createStore, select, withProps } from '@ngneat/elf';
import { withRequestsCache, withRequestsStatus } from '@ngneat/elf-requests';
import { Injectable } from "@angular/core";
import { RestService } from "../../../shared/services/rest.service";
import { AnimalMediaModel } from "../models/animal-media.model";
import { BehaviorSubject, Observable, of } from "rxjs";
import { switchMap, tap, catchError, take, finalize } from "rxjs/operators";
import { TranslocoService } from '@jsverse/transloco';
import { ToastService } from 'src/app/shared/services/toast.service';
import { AnimalRepository } from './animal.repository';

export interface AnimalImageState {
  images: Record<number, AnimalMediaModel[]>;
}

export const store = createStore(
  { name: 'animalImages' },
  withProps<AnimalImageState>({ images: {} }),
  withRequestsCache<'animalImages'>(),
  withRequestsStatus<'animalImages'>()
);

@Injectable({ providedIn: 'root' })
export class AnimalImagesRepository {
  private readonly _imageAddedSubject = new BehaviorSubject<AnimalMediaModel | null>(null);
  imageAdded$ = this._imageAddedSubject.asObservable();

  constructor(
    private readonly _restService: RestService,
    private readonly _translateService: TranslocoService,
    private readonly _toast: ToastService,
    private readonly _animalRepository: AnimalRepository
  ) {}

  getImages(animalId: number): Observable<AnimalMediaModel[]> {
    return store.pipe(
      select(state => state.images[animalId] ?? null),
      take(1),
      switchMap(cachedImages => {
        if (cachedImages !== null) {
          return of(cachedImages);
        }
        return this._restService.httpGet(`/animal_images?animal.id=${animalId}`).pipe(
          tap(response => {
            store.update(state => ({
              ...state,
              images: {
                ...state.images,
                [animalId]: response
              }
            }));
          }),
          catchError(() => of([]))
        );
      })
    );
  }


  getImageById(imageId: number): Observable<AnimalMediaModel | null> {
    return store.pipe(
      select(state => Object.values(state.images).flat().find(img => img.id === imageId) ?? null),
      take(1),
      switchMap(image => {
        if (image) return of(image);
        return this._restService.httpGet(`/animal_images/${imageId}`).pipe(
          tap(fetchedImage => {
            store.update(state => ({
              ...state,
              images: {
                ...state.images,
                [fetchedImage.animalId]: [
                  ...(state.images[fetchedImage.animalId] || []),
                  fetchedImage
                ]
              }
            }));
          }),
          catchError(() => of(null))
        );
      })
    );
  }

  addImage(image: FormData, animalId: number): Observable<AnimalMediaModel> {
    return this._restService.httpPost('/animal_images', image).pipe(
      tap(data => {
        store.update(state => ({
          ...state,
          images: {
            ...state.images,
            [animalId]: [...(state.images[animalId] || []), data]
          }
        }));
        this._imageAddedSubject.next(data);
        this._animalRepository.updateAnimalStore(animalId, { updatedAt: new Date(), hasFullData: false });
      })
    );
  }

  updateImage(imageId: number, image: Partial<AnimalMediaModel>, animalId: number): void {
    this._restService.httpPut(`/animal_images/${imageId}`, image).pipe(take(1)).subscribe(updatedImage => {
      store.update(state => ({
        ...state,
        images: {
          ...state.images,
          [animalId]: state.images[animalId]?.map(img => img.id === imageId ? { ...img, ...image } : img) || []
        }
      }));
      if (image.preview && animalId) {
        this._animalRepository.updateAnimalStore(animalId, { updatedAt: new Date(), hasFullData: false });
      }
      this._toast.showSuccess(this._translateService.translate('toast.saveSuccess'));
    });
  }

  deleteImage(imageId: number, animalId: number): Observable<void> {
    return this._restService.httpDelete(`/animal_images/${imageId}`).pipe(
      tap(() => {
        store.update(state => ({
          ...state,
          images: {
            ...state.images,
            [animalId]: state.images[animalId]?.filter(img => img.id !== imageId) || []
          }
        }));
        this._animalRepository.updateAnimalStore(animalId, { updatedAt: new Date(), hasFullData: false });
      }),
      finalize(() => this._toast.showSuccess(this._translateService.translate('toast.deleteSuccess')))
    );
  }

  clearImageAdded(): void {
    this._imageAddedSubject.next(null);
  }
}
