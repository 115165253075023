import {Route} from '@angular/router';
import {AuthGuard} from "../../shared/auth/auth.guard";
import { ContactManagementComponent } from './components/contact-management/contact-management.component';

export const ContactsRoutes: Route[] = [
  {
    path: 'kontakte',
    component: ContactManagementComponent,
    canActivate: [AuthGuard]
  }
];
