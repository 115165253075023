import { createStore, select, withProps } from '@ngneat/elf';
import { withRequestsCache, withRequestsStatus } from '@ngneat/elf-requests';
import { Injectable } from "@angular/core";
import { RestService } from "../../../shared/services/rest.service";
import { AnimalMediaModel } from "../models/animal-media.model";
import { BehaviorSubject, Observable, of } from "rxjs";
import { switchMap, tap, catchError, take, finalize } from "rxjs/operators";
import { TranslocoService } from '@jsverse/transloco';
import { ToastService } from 'src/app/shared/services/toast.service';
import { AnimalRepository } from './animal.repository';

export interface AnimalVideoState {
  videos: Record<number, AnimalMediaModel[]>; // ✅ Map: animalId → Video-Liste
}

export const store = createStore(
  { name: 'animalVideos' },
  withProps<AnimalVideoState>({ videos: {} }),
  withRequestsCache<'animalVideos'>(),
  withRequestsStatus<'animalVideos'>()
);

@Injectable({ providedIn: 'root' })
export class AnimalVideoRepository {
  private readonly _videoAddedSubject = new BehaviorSubject<AnimalMediaModel | null>(null);
  videoAdded$ = this._videoAddedSubject.asObservable();

  constructor(
    private readonly _restService: RestService,
    private readonly _translateService: TranslocoService,
    private readonly _toast: ToastService,
    private readonly _animalRepository: AnimalRepository
  ) {}

  getVideos(animalId: number): Observable<AnimalMediaModel[]> {
    return store.pipe(
      select(state => state.videos[animalId] ?? null),
      take(1),
      switchMap(cachedVideos => {
        if (cachedVideos !== null) {
          return of(cachedVideos);
        }
        return this._restService.httpGet(`/animal_videos?animal.id=${animalId}`).pipe(
          tap(response => {
            store.update(state => ({
              ...state,
              videos: {
                ...state.videos,
                [animalId]: response
              }
            }));
          }),
          catchError(() => of([]))
        );
      })
    );
  }

  getVideoById(videoId: number): Observable<AnimalMediaModel | null> {
    return store.pipe(
      select(state => Object.values(state.videos).flat().find(v => v.id === videoId) ?? null),
      take(1),
      switchMap(video => {
        if (video) return of(video);
        return this._restService.httpGet(`/animal_videos/${videoId}`).pipe(
          tap(fetchedVideo => {
            store.update(state => ({
              ...state,
              videos: {
                ...state.videos,
                [fetchedVideo.animalId]: [
                  ...(state.videos[fetchedVideo.animalId] || []),
                  fetchedVideo
                ]
              }
            }));
          }),
          catchError(() => of(null))
        );
      })
    );
  }

  addVideo(video: FormData, animalId: number): Observable<AnimalMediaModel> {
    return this._restService.httpPost('/animal_videos', video).pipe(
      tap(data => {
        store.update(state => ({
          ...state,
          videos: {
            ...state.videos,
            [animalId]: [...(state.videos[animalId] || []), data]
          }
        }));
        this._videoAddedSubject.next(data);
        this._animalRepository.updateAnimalStore(animalId, { updatedAt: new Date() });
      })
    );
  }


  updateVideo(videoId: number, video: Partial<AnimalMediaModel>, animalId: number): void {
    this._restService.httpPut(`/animal_videos/${videoId}`, video).pipe(take(1)).subscribe(updatedVideo => {
      store.update(state => ({
        ...state,
        videos: {
          ...state.videos,
          [animalId]: state.videos[animalId]?.map(v => v.id === videoId ? { ...v, ...video } : v) || []
        }
      }));
      if (video.preview && animalId) {
        this._animalRepository.updateAnimalStore(animalId, { updatedAt: new Date() });
      }
      this._toast.showSuccess(this._translateService.translate('toast.saveSuccess'));
    });
  }

  deleteVideo(videoId: number, animalId: number): Observable<void> {
    return this._restService.httpDelete(`/animal_videos/${videoId}`).pipe(
      tap(() => {
        store.update(state => ({
          ...state,
          videos: {
            ...state.videos,
            [animalId]: state.videos[animalId]?.filter(v => v.id !== videoId) || []
          }
        }));
        this._animalRepository.updateAnimalStore(animalId, { updatedAt: new Date() });
      }),
      finalize(() => this._toast.showSuccess(this._translateService.translate('toast.deleteSuccess')))
    );
  }

  clearImageAdded(): void {
    this._videoAddedSubject.next(null);
  }
}
