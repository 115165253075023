import {AnimalMediaModel} from '../../../modules/animals/models/animal-media.model';
import {ChangeDetectorRef, Component, Input, OnChanges, OnInit} from '@angular/core';
import {take} from 'rxjs';
import {AnimalModel} from 'src/app/modules/animals/models/animal.model';
import {AnimalCategory} from "../../../modules/animals/models/animal-category.enum";
import {AnimalVideoRepository} from "../../../modules/animals/states/animal-video.repository";
import {AssetService} from "../../services/asset.service";

@Component({
  selector: 'app-animal-video-container',
  templateUrl: './animal-video-container.component.html',
  styleUrls: ['./animal-video-container.component.scss']
})
export class AnimalVideoContainerComponent implements OnInit, OnChanges {
  @Input() id?: number | undefined;
  @Input() animal: AnimalModel | undefined;
  @Input() iconBadge: boolean = true;

  videoUrl: string | null | undefined = '';
  thumbnailUrl: string | null | undefined = '';
  isLoading: boolean = false;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly _animalVideosRepository: AnimalVideoRepository,
    private readonly _assetService: AssetService
  ) {
  }


  ngOnInit(): void {
    if (this.animal) {
      this._animalVideosRepository.getVideos(this.animal.id).pipe(take(1)).subscribe(() => {
        if (this.id) {
          this.getAnimalImage(this.id);
        }
      });
    }
  }


  ngOnChanges(): void {
    if (!this.id) {
      return;
    }

    this.getAnimalImage(this.id);
  }

  getAnimalImage(videoId: number) {
    if (!videoId) {
      return;
    }

    this._animalVideosRepository.getVideoById(videoId).pipe(take(1)).subscribe((video: AnimalMediaModel | null) => {
      if (video) {
        if (video.screenshotUrl) {
          this.thumbnailUrl = this._assetService.getAssetUrl(video.screenshotUrl);
        }

        if (!video.variants['app-preview'].url && !this.isLoading) {
          this.isLoading = true;
        } else {
          this.videoUrl = this._assetService.getAssetUrl(video.variants['app-preview'].url);
        }
      }

      this.cdr.detectChanges();
    });
  }
}

