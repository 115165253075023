// media-download.service.ts
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environment/environment';

@Injectable({ providedIn: 'root' })
export class MediaDownloadService {
  constructor(private http: HttpClient) {}

  downloadMedia(url: string, filename: string, delay = 0): void {
    setTimeout(() => {
      const baseUrl = environment.apiUrl.replace(/\/+$/, '');
      const cleanedPath = url.replace(/^\/+/, '');
  
      let fullUrl = `${baseUrl}/${cleanedPath}`;

      fullUrl = fullUrl.replace(/\/api\/api\//, '/api/');
  
      this.http.get(fullUrl, { responseType: 'blob' }).subscribe({
        next: (blob) => {
          const blobUrl = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = blobUrl;
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(blobUrl);
        },
        error: (err) => {
        }
      });
    }, delay);
  }
  
  
  
  
}
