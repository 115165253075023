import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {AnimalModel} from '../models/animal.model';
import {catchError, combineLatest, finalize, forkJoin, map, Observable, of, switchMap, take, takeWhile, throwError} from 'rxjs';
import {AnimalOriginModel} from '../models/animal-origin.model';
import {CompetentAuthoritiesRepository} from 'src/app/shared/states/competent-authorities.repository';
import {TranslocoService} from '@jsverse/transloco';
import {AnimalOriginRepository} from '../states/animal-origin.repository';
import {AnimalOriginReasonForTransfer} from "../models/animal-origin-reason-for-transfer.enum";
import {CompetentAuthorityModel} from 'src/app/shared/models/competent-authority.model';
import {ContactRepository} from "../../contact/states/contact.repository";
import {ContactModel} from "../../contact/models/contact.model";
import {HelperService} from "../../../shared/services/helper.service";
import {PersonTitleModel} from 'src/app/shared/models/person-title.model';
import { AnimalRepository } from '../states/animal.repository';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-animal-details-origin',
  templateUrl: './animal-details-origin.component.html',
  styleUrls: ['./animal-details-origin.component.scss']
})
export class AnimalDetailsOriginComponent implements OnInit, OnDestroy {
  @Input() animal!: AnimalModel;
  alive: boolean = true;
  animalOrigItems: { [id: number]: AnimalOriginModel | any } = {} as { [id: number]: AnimalOriginModel | any};
  cityName: { [id: string]: string | undefined } = {};
  isEditingMode: boolean = false;
  originReasonForTransfer: { name: string; value: string; }[] = [];
  selectedOptionReasonTransfer: { name: string; value: string } | null = null;
  originSubmittedPersonSalutation: PersonTitleModel[] = [];
  contacts: { [id: string]: ContactModel } = {};
  originCompetentAuthorityOptions: CompetentAuthorityModel[] = [];
  editType: any;
  editDialogContactKey: any;
  editDialogOriginKey: number = 0;
  openEdit: boolean = false;
  editingItem: any;

  contact: ContactModel = {} as ContactModel;
  existingContact: boolean = false;
  existingContactUpdated: boolean = false;
  validationStatusChanged: boolean = true;
  
  constructor(
    private competentAuthoritiesRepository: CompetentAuthoritiesRepository,
    private _translocoService: TranslocoService,
    private _animalOriginRepository: AnimalOriginRepository,
    private _animalRepository: AnimalRepository,
    private cdr: ChangeDetectorRef,
    private _contactRepository: ContactRepository,
    private _helperService: HelperService,
    private _toast: ToastService,
  ) {
    //Silence is golden
  }

  ngOnInit(): void {
    this.originSubmittedPersonSalutation = this._helperService.getSalutationOptions();
    this.getOrigins();
    
  }

  getOrigins(){
    combineLatest([
      this._animalOriginRepository.getByAnimalId(this.animal?.id),
      this.competentAuthoritiesRepository.getCompetentAuthorities(),
    ]).pipe(take(1)).subscribe(([animalOrigins, competentAuthorities]) => {
      if (competentAuthorities.length) {
        this.originCompetentAuthorityOptions = competentAuthorities;
        competentAuthorities.forEach((competentAuthority: CompetentAuthorityModel) => {
          this.cityName[competentAuthority.id] = competentAuthority.name;
        });
      }

      if (animalOrigins?.length) {
        this.setAnimalOrigins(animalOrigins);
      }
    });
  }


  setAnimalOrigins(animalOrigins: AnimalOriginModel[]) {
    this.setReasonsForTransferOptions();
    const contactObservables = animalOrigins.flatMap((animalOrigin) => {
      const observables = [];
      if (animalOrigin.givingContact && animalOrigin.givingContact.id) {
        observables.push(this._contactRepository.getById(animalOrigin.givingContact.id).pipe(take(1)));
      } else {
        observables.push(of(undefined));
      }
  
      if (animalOrigin.owningContact && animalOrigin.owningContact.id) {
        observables.push(this._contactRepository.getById(animalOrigin.owningContact.id).pipe(take(1)));
      } else {
        observables.push(of(undefined));
      }
      return observables;
    });

    forkJoin(contactObservables).subscribe((contacts: (ContactModel | undefined)[]) => {
      animalOrigins.forEach((animalOrigin) => {
        const givingContact = contacts.find(contact => contact?.id === animalOrigin.givingContact?.id);
        const owningContact = contacts.find(contact => contact?.id === animalOrigin.owningContact?.id);
        if (givingContact) {
          this.contacts[givingContact.id] = givingContact;
          const birthday = this.contacts[givingContact.id]?.birthday;
          if (birthday) {
            this.contacts[givingContact.id].birthday = new Date(birthday);
          }
        }
        if (owningContact) {
          this.contacts[owningContact.id] = owningContact;
          const birthday = this.contacts[owningContact.id]?.birthday;
          if (birthday) {
            this.contacts[owningContact.id].birthday = new Date(birthday);
          }
        }
        this.processAnimalOrigin(animalOrigin, givingContact, owningContact);
      });
      this.cdr.detectChanges();
    });
  }

  private processAnimalOrigin(animalOrigin: AnimalOriginModel, givingContact?: ContactModel, owningContact?: ContactModel) {
    if (!animalOrigin.id) {
      return;
    }
    const id = animalOrigin.id;

    animalOrigin.dateOfReceipt = animalOrigin.dateOfReceipt 
      ? new Date(animalOrigin.dateOfReceipt) 
      : null;
  
    this.animalOrigItems[id] = { ...animalOrigin };

    if (givingContact) {
      this.contacts[givingContact.id] = givingContact;
    }
    if (owningContact) {
      this.contacts[owningContact.id] = owningContact;
    }
    this.selectedOptionReasonTransfer = this.originReasonForTransfer.find(
      option => option.value === this.animalOrigItems[id]?.reasonForGiving
    ) || null;
  }

  setReasonsForTransferOptions() {
    for (const reasonForTransfer in AnimalOriginReasonForTransfer) {
      this.originReasonForTransfer.push({
        name: this._translocoService.translate(`animalAdd.origin.reasonForTransferSelect.${reasonForTransfer}`),
        value: valueOf(AnimalOriginReasonForTransfer, reasonForTransfer)
      });
    }
    function valueOf<T>(e: T, v: string): T[keyof T] {
      return e[v as keyof T];
    }
  }

  toggleEditDialog(open: boolean, editType: string, originKey: number, contactKey?: string) {
    if (open) {
      if (editType === 'general') {
        this.editingItem = JSON.parse(JSON.stringify(this.animalOrigItems[originKey]));
        this.editingItem.competentAuthority = this.editingItem.competentAuthority ? { id: this.editingItem.competentAuthority.id } : null;
        this.editingItem.dateOfReceipt = this.editingItem.dateOfReceipt 
        ? new Date(this.editingItem.dateOfReceipt) 
        : null;
      } else if (editType === 'givingContact' || editType === 'owningContact') {
        if (contactKey && this.contacts[contactKey]) {
          this.editingItem = JSON.parse(JSON.stringify(this.contacts[contactKey]));
          this.existingContact = Object.values(this.editingItem).some(value => value !== null && value !== undefined);
        } else {
          this.editingItem = {} as ContactModel;
          this.existingContact = false;
        }
      }
      this.editType = editType;
      this.editDialogContactKey = contactKey;
      this.editDialogOriginKey = originKey;
      this.isEditingMode = true;
      this.openEdit = open
      
    } else {
      this.openEdit = open;
      this.editDialogContactKey = '';
      this.editDialogOriginKey = 0;
      this.editingItem = null;
      this.isEditingMode = false;
    }
  }

  onContactUpdate($event: any) {
    this.contact = $event;
  }
  onValidationStatusChanged($event: boolean) {
    this.validationStatusChanged = $event;
  }
  onExistingContactUpdated($event: boolean) {
    this.existingContactUpdated = $event;
  }
  onExistingContactSelected($event: ContactModel) {
    this.contact = $event;
    this.existingContact = Object.keys($event).length ? true : false;
  }

  validateGeneralForm(): void {
    this.editingItem.reasonForGiving = this.selectedOptionReasonTransfer?.value;
    if (this.editingItem.reasonForGiving !== 'found-animal' && this.editingItem.reasonForGiving !== 'take-away') {
      this.editingItem.competentAuthority = null;
    } else {
      this.editingItem.competentAuthority = this.editingItem.competentAuthority ? { id: this.editingItem.competentAuthority.id } : { id: null };
    }
    this.validationStatusChanged = !!(this.editingItem.dateOfReceipt && this.editingItem.reasonForGiving);
  }

  private updateContactAndOrigin(
    contactPayload: ContactModel,
    originPayload: AnimalOriginModel
  ): Observable<AnimalOriginModel> {
    return this._contactRepository.update(
      contactPayload,
      contactPayload.id
    ).pipe(
      switchMap(() => this._animalOriginRepository.update(
        originPayload.id,
        originPayload,
        this.animal.id
      )),
      take(1)
    );
  }

  private createContactAndUpdateOrigin(
    contactPayload: ContactModel,
    originPayload: AnimalOriginModel,
    key: number,
    editingType: string
  ): Observable<AnimalOriginModel> {
    return this._contactRepository.create(contactPayload).pipe(
      map(response => {
        const updatedPayload = { ...originPayload };
  
        if (editingType === 'owningContact') {
          updatedPayload.owningContact = { id: response.id };
        } else if (editingType === 'givingContact') {
          updatedPayload.givingContact = { id: response.id };
        }
  
        return updatedPayload;
      }),
      switchMap(updatedPayload =>
        this._animalOriginRepository.update(key, updatedPayload, this.animal.id)
      ),
      take(1)
    );
  }
  

  save(animalOriginItem: any) {
    if (this.editType === 'general') {
      this.updateAnimalOrigin(animalOriginItem);
    } else if (this.editType === 'givingContact' || this.editType === 'owningContact') {
      if (this.existingContact && this.existingContactUpdated) {
        this.updateContactAndOrigin(this.contact, this.animalOrigItems[this.editDialogOriginKey])
          .pipe(
            take(1),
            catchError((error) => {
              this._toast.showError(this._translocoService.translate('toast.saveError'));
              console.error('Fehler beim Speichern des Kontakts:', error);
              return throwError(() => error);
            }),
            finalize(() => {
              this.cdr.detectChanges(); // UI immer aktualisieren
            })
          )
          .subscribe(() => {
            this.contacts[this.editDialogContactKey] = this.editingItem;
            this.getOrigins();
            this.toggleEditDialog(false, '', 0);
            this._animalRepository.updateAnimalStore(this.animal.id, { updatedAt: new Date() });
            this._toast.showSuccess(this._translocoService.translate('toast.saveSuccess'));
          });
  
      } else if (this.existingContact && !this.existingContactUpdated) {
        this.contacts[this.editDialogContactKey] = this.contact;
  
        const originPayload: AnimalOriginModel = {
          ...this.animalOrigItems[this.editDialogOriginKey],
          [this.editType]: { id: this.contact.id }
        };
  
        this._animalOriginRepository.update(this.editDialogOriginKey, originPayload, this.animal.id)
          .pipe(
            take(1),
            catchError((error) => {
              this._toast.showError(this._translocoService.translate('toast.saveError'));
              console.error('Fehler beim Aktualisieren des Herkunft:', error);
              return throwError(() => error);
            }),
            finalize(() => {
              this.cdr.detectChanges(); // UI immer aktualisieren
            })
          )
          .subscribe(() => {
            if (this.animalOrigItems[this.editDialogOriginKey]) {
              this.animalOrigItems[this.editDialogOriginKey] = {
                ...this.animalOrigItems[this.editDialogOriginKey],
                ...originPayload
              };
            }
            this.getOrigins();
            this._animalRepository.updateAnimalStore(this.animal.id, { updatedAt: new Date() });
            this._toast.showSuccess(this._translocoService.translate('toast.saveSuccess'));
          });
  
        this.toggleEditDialog(false, '', 0);
  
      } else if (this.contact) {
        this.createContactAndUpdateOrigin(this.contact, animalOriginItem, this.editDialogOriginKey, this.editType)
          .pipe(
            take(1),
            catchError((error) => {
              this._toast.showError(this._translocoService.translate('toast.saveError'));
              console.error('Fehler beim Erstellen des Kontakts und Speichern des Ursprungs:', error);
              return throwError(() => error);
            }),
            finalize(() => {
              this.cdr.detectChanges(); // UI immer aktualisieren
            })
          )
          .subscribe(() => {
            this.contacts[this.editDialogContactKey] = this.editingItem;
            this.toggleEditDialog(false, '', 0);
            this.getOrigins();
            this._animalRepository.updateAnimalStore(this.animal.id, { updatedAt: new Date() });
            this._toast.showSuccess(this._translocoService.translate('toast.saveSuccess'));
          });
      }
    }
    this.toggleEditDialog(false, '', 0);
  }
  

  updateAnimalOrigin(animalOriginItem: AnimalOriginModel | any): void {
    let dateOfReceipt = null;
    if (animalOriginItem.dateOfReceipt) {
      dateOfReceipt = this._helperService.determineDateOfReceipt(
        this.animalOrigItems[this.editDialogOriginKey].dateOfReceipt,
        animalOriginItem.dateOfReceipt
      );
    }
    const originPayload: AnimalOriginModel | any = {
      ...animalOriginItem,
      dateOfReceipt,
      animal: { id: this.animal.id },
      reasonForGiving: animalOriginItem.reasonForGiving,
      specialInformation: animalOriginItem.specialInformation,
      location: animalOriginItem.location,
      otherComments: animalOriginItem.otherComments,
      conditionOfCare: animalOriginItem.conditionOfCare,
      nutritionalStatus: animalOriginItem.nutritionalStatus,
    };

    if (animalOriginItem.competentAuthority) {
      originPayload.competentAuthority = {id: animalOriginItem.competentAuthority.id};
    } 
    
    if (animalOriginItem.reasonForGiving !== 'found-animal' && animalOriginItem.reasonForGiving !== 'take-away') {
      originPayload.competentAuthority = null;
    }

    this._animalOriginRepository.update(this.editDialogOriginKey, originPayload, this.animal.id)
    .pipe(
      take(1),
      catchError((error) => {
        this._toast.showError(this._translocoService.translate('toast.saveError'));
        console.error('Fehler beim Aktualisieren des Herkunft:', error);
        return throwError(() => error);
      }),
      finalize(() => {
        this.cdr.detectChanges();
      })
    )
    .subscribe(() => {
      if (this.animalOrigItems[this.editDialogOriginKey]) {
        this.animalOrigItems[this.editDialogOriginKey] = {
          ...this.animalOrigItems[this.editDialogOriginKey],
          ...originPayload
        };
      }
      this.getOrigins();
      this._animalRepository.updateAnimalStore(this.animal.id, { updatedAt: new Date() });
      this._toast.showSuccess(this._translocoService.translate('toast.saveSuccess'));
    });

  }

  lastEntryFirst = (a: any, b: any) => {
    return b.value.id - a.value.id;
  };

  ngOnDestroy(): void {
    this.alive = false;
  }
}
