<div class="card">
  <div class="flex justify-content-between">
    <h2>{{ 'animalDetails.media.videos.headline' | transloco }}</h2>
    <button 
        *ngIf="selectedVideos.length > 0"
        pButton 
        type="button" 
        icon="pi pi-download"
        class="p-button-sm flex justify-content-end"
        label="{{ 'download.dowloaden' | transloco }} - ({{ selectedVideos.length }})"
        (click)="downloadSelectedVideos()"
    ></button>
  </div>
  <div class="grid gap-6 justify-content-center align-items-center p-3">
    <div class="col-3 max-w-20rem" *ngIf="!isEditingMode">
      <app-video-upload [animal]="animal"></app-video-upload>
    </div>
    <div class="col-4 max-w-30rem" *ngFor="let video of videos">
      <div class="card flex flex-column min-h-full">
        <span>{{video.date | date: 'dd.MM.yyyy'}}</span>
        <div class="text-center p-3 flex-grow-1">
          <div class="blurred-image-container" [class.cursor-pointer]="!isEditingMode">
            <div class="blurred flex align-items-center justify-content-center">
              <app-animal-video-container [animal]="animal" [id]="video.id"></app-animal-video-container>
            </div>
          </div>
        </div>
        <div class="flex flex-row flex-wrap gap-3 align-items-center justify-content-center icon-container">
          <div class="icon-wrapper">
            <i class="pi pi-pencil cursor-pointer" (click)="openDialog(video)"></i>
          </div>
          <div class="icon-wrapper" [ngClass]="video.preview ? 'active' : ''"
            (click)="clickTogglePreview(video)"
            pTooltip="{{ 'tooltip.togglePreview' | transloco }}"
            tooltipPosition="top"
          >
            <i class="pi pi-image cursor-pointer"></i>
          </div>
          <div class="icon-wrapper" [ngClass]="video.syncWithSharedFolder ? 'active' : ''"
            (click)="clickFileExport(video)"
            pTooltip="{{ 'tooltip.syncWithSharedFolder' | transloco }}"
            tooltipPosition="top"
          >
            <i class="pi pi-file-export cursor-pointer"></i>
          </div>
          <div class="icon-wrapper" [ngClass]="video.syncWithWebsite ? 'active' : ''"
               (click)="clickFileSync(video)"
               pTooltip="{{ 'tooltip.syncWithWebsite' | transloco }}"
               tooltipPosition="top"
          >
            <i class="pi pi-desktop cursor-pointer"></i>
          </div>
          <div
            class="icon-wrapper delete"
            [ngClass]="selectedImgToDelete.includes(video.id) ? 'delete' : ''"
            (click)="deleteVideo(video)"
            pTooltip="{{ 'tooltip.delete' | transloco }}"
            tooltipPosition="top"
          >
            <i class="pi pi-trash cursor-pointer"></i>
          </div>
        </div>
        <div class="flex flex-row flex-wrap gap-2 align-items-center justify-content-center labels-container">
          <div
            class="label-wrapper"
          >
            <i class="pi pi-file-edit mr-2"></i> {{video.description}}
          </div>
          <div
            class="label-wrapper"
          >
            <i class="pi pi-tags mr-2"></i> {{video.translatedCategories}}
          </div>
          <div
            class="label-wrapper"
          >
            <i class="pi pi-file-export mr-2"></i> {{video.externalDescription}}
          </div>
          <div class="p-2">
            <p-checkbox 
              [binary]="true"
              [(ngModel)]="selectedVideosMap[video.id]"
              (onChange)="onVideoSelectChange(video)"
              inputId="img-{{ video.id }}"
            ></p-checkbox>
            <label for="img-{{ video.id }}" class="ml-2">{{ 'download.downloadSelect' | transloco }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<p-dialog
  [(visible)]="displayDialog"
  [modal]="true"
  [style]="{ width: '50vw', height: '75vh' }"
  [closable]="true"
  [draggable]="false"
  [resizable]="false"
  *ngIf="selectedVideo"
  #dialog
>
  <div class="flex flex-wrap justify-content-center align-items-center relative">
    <div class="image-container">
      <app-animal-video-container [animal]="animal" [id]="selectedVideo.id"></app-animal-video-container>
    </div>
    </div>
  <div>
    <div class="flex flex-row flex-wrap gap-3 align-items-center justify-content-center icon-container" >
      <div class="icon-wrapper" [ngClass]="selectedVideo.preview ? 'active' : ''"
        (click)="selectedVideo.preview = !selectedVideo.preview"
        pTooltip="{{ 'tooltip.togglePreview' | transloco }}"
        tooltipPosition="top"
      >
        <i class="pi pi-image cursor-pointer"></i>
      </div>
      <div class="icon-wrapper" [ngClass]="selectedVideo.syncWithSharedFolder ? 'active' : ''"
        (click)="selectedVideo.syncWithSharedFolder = !selectedVideo.syncWithSharedFolder"
        pTooltip="{{ 'tooltip.syncWithSharedFolder' | transloco }}"
        tooltipPosition="top"
      >
        <i class="pi pi-file-export cursor-pointer"></i>
      </div>
      <div class="icon-wrapper" [ngClass]="selectedVideo.syncWithWebsite ? 'active' : ''"
           (click)="selectedVideo.syncWithWebsite = !selectedVideo.syncWithWebsite"
           pTooltip="{{ 'tooltip.syncWithWebsite' | transloco }}"
           tooltipPosition="top"
      >
        <i class="pi pi-desktop cursor-pointer"></i>
      </div>
    </div>
    <div class="flex justify-content-center align-items-center mt-3">
      <p-multiSelect
        class="p-mutliselect-sm flex"
        [options]="mediaCategoryOptions"
        [(ngModel)]="selectedVideo.categories"
        optionLabel="name"
        optionValue="value"
        placeholder="{{ 'placeholder.selectCategory' | transloco }}"
        [appendTo]="dialog"
      ></p-multiSelect>
    </div>
    <div class="flex justify-content-center align-items-center mt-3">
      <div>
        <label class="py-1" for="date">{{ 'animalWhereabout.date' | transloco }}</label>
        <p-calendar
          class="flex"
          id="date"
          [(ngModel)]="selectedVideo.date"
          dateFormat="dd.mm.yy"
          [showIcon]="true"
          appCalendarMaxDate
        ></p-calendar>
      </div>
    </div>

    <div class="flex justify-content-between align-items-center gap-3 mt-3">
      <div class="col-6">
        <label class="py-1" for="description">
          {{ 'animalImages.description' | transloco }}
        </label>
        <textarea
          area
          pInputText
          class="p-inputtext-sm w-full"
          id="description"
          [(ngModel)]="selectedVideo.description"
          rows="10"
        ></textarea>
      </div>
        <div class="col-6">
          <label class="py-1" for="externalDescription">
            {{ 'animalImages.externalDescription' | transloco }}
          </label>
          <textarea
            area
            pInputText
            class="p-inputtext-sm w-full"
            id="externalDescription"
            [(ngModel)]="selectedVideo.externalDescription"
            rows="10"
          ></textarea>
        </div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="flex">
      <div class="flex-1">
        <button class="p-button p-button-sm" (click)="toggleEditDialog()">
          {{ 'modal.cancel' | transloco }}
        </button>
        <button class="p-button p-button-sm" (click)="saveVideo(selectedVideo)">
          {{ 'modal.save' | transloco }}
        </button>
      </div>
    </div>
  </ng-template>
</p-dialog>
