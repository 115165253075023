import {Component, OnDestroy, OnInit} from '@angular/core';
import {AnimalRepository} from '../animals/states/animal.repository';
import {TranslocoService} from '@jsverse/transloco';
import {AnimalAddService} from '../animals/services/animal-add.service';
import {combineLatest, debounceTime, distinctUntilChanged, filter, Subject, take, takeUntil, takeWhile, tap} from 'rxjs';
import {AnimalModel} from '../animals/models/animal.model';
import {AnimalWhereaboutsRepository} from "../animals/states/animal-whereabouts.repository";
import {HelperService} from "../../shared/services/helper.service";
import { Router } from '@angular/router';
import { SearchService } from 'src/app/shared/services/search.service';
import { AnimalSheltersRepository } from '../animals/states/animal-shelter.repository';
import { ContactRepository } from '../contact/states/contact.repository';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  alive: boolean = true;
  value = 0;
  data: any;
  options: any;
  addDialogVisible: boolean = false;
  newAnimals: AnimalModel[] = [];
  maxLastEditedAnimals = 20;
  whereabouts:any;
  searchTerm: string = '';
  searchTermChanged = new Subject<string>();
  animalSearchResult: AnimalModel[] = [];

  constructor(
    private readonly _animalRepository: AnimalRepository,
    private readonly _animalWhereaboutsRepository: AnimalWhereaboutsRepository,
    private readonly _translocoService: TranslocoService,
    private readonly _animalAddService: AnimalAddService,
    private readonly _helperService: HelperService,
    private readonly _searchService: SearchService,
    private readonly _animalSheltersRepository: AnimalSheltersRepository,
    private readonly _contactRepository: ContactRepository,
    private router: Router
  ) {
    this.searchTermChanged.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      takeWhile(() => this.alive),
      tap(term => {
        if (term.length === 0) {
          this.animalSearchResult = [];
        }
      }),
      filter(term => term.length > 2)
    ).subscribe(term => {
      combineLatest([
        this._animalSheltersRepository.getAnimalShelters(),
      this._animalRepository.searchAnimals(term)
      ]).subscribe(([shelters, results]) => {
        this.animalSearchResult = results.map(animal => ({
          ...animal,
          shelter: shelters.find(shelter => shelter.id === animal.animalShelter?.id) || null
        }));
      });
    });
    
  }

  ngOnInit(): void {
    this._animalAddService.addDialogVisible.pipe(takeWhile(() => this.alive)).subscribe((openModal) => {
      this.addDialogVisible = openModal;
    });
    this._animalRepository.getAnimals$().pipe(takeWhile(() => this.alive)).subscribe((animals: AnimalModel[]) => {
      const categoryCounts: { [key: string]: number } = animals.reduce((counts: { [key: string]: number }, animal) => {
        if (animal.category !== null) {
          if (!counts[this._translocoService.translate('animal.category.' + animal.category.toUpperCase())]) {
            counts[this._translocoService.translate('animal.category.' + animal.category.toUpperCase())] = 0;
          }
          counts[this._translocoService.translate('animal.category.' + animal.category.toUpperCase())]++;
        }
        return counts;
      }, {});

      this.data = {
        labels: Object.keys(categoryCounts),
        datasets: [
          {
            data: Object.values(categoryCounts)
          }
        ]
      };

      if (animals.length > 0) {
        this.getLastEditedAnimals(animals);
      } else {
        this.newAnimals = [];
      }
    });

    this._searchService.searchTerm$.pipe(takeUntil(this.searchTermChanged)).subscribe((term) => {
      this.searchTerm = term;
      this.searchTermChanged.next(this.searchTerm);
    });
  }

  onSearchInput() {
    this.searchTermChanged.next(this.searchTerm);
    this._searchService.searchTermSubject.next(this.searchTerm);
  }

  handleBarcodeScanned($event: { value: string; }) {
    throw new Error('Method not implemented.');
  }

  openAnimal(animal: AnimalModel): void {
    this._animalRepository.addAnimal(animal);

    this.router.navigate(['/tiere', animal.id]);
    // [routerLink]="['/tiere', animal.id]"
  }

  getDateFormat(): string {
    return this._helperService.getDateStringByLang();
  }

  getLastEditedAnimals(animals: AnimalModel[]): void {
    if (!animals || animals.length === 0) {
      return;
    }

    const sortedAnimals = animals.sort((a, b) => {
      const dateA = a.updatedAt ? new Date(a.updatedAt).getTime() : 0;
      const dateB = b.updatedAt ? new Date(b.updatedAt).getTime() : 0;
      return dateB - dateA;
    });
  
    this.newAnimals = sortedAnimals.slice(0, this.maxLastEditedAnimals);
  
    if (!this.whereabouts) {
      this.whereabouts = new Map<number, any>();
    }
    
    this.newAnimals.forEach((animal) => {
      this._animalWhereaboutsRepository.getByAnimalId(animal.id).pipe(take(1)).subscribe((whereabouts: any) => {
        if (Array.isArray(whereabouts) && whereabouts.length > 0) {
          const latestWhereabout = whereabouts.reduce((prev, current) => (prev.id > current.id ? prev : current));
          if (latestWhereabout.contact?.id) {
            this._contactRepository.getById(latestWhereabout.contact.id).pipe(take(1)).subscribe((contact) => {

              if (contact.city) {
               latestWhereabout.location = contact.city;
              }

              this.whereabouts.set(animal.id, latestWhereabout);
            });
          } else {
            this.whereabouts.set(animal.id, latestWhereabout);
          }
        }
      });
    });
  }

  toggleAddDialog() {
    this._animalAddService.toggleAddDialog(true);
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  protected readonly HelperService = HelperService;
}
