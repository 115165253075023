import {NgModule} from '@angular/core';
import {AsyncPipe} from "@angular/common";
import {SharedModule} from "../../shared/shared.module";
import {ChooseContactComponent} from "./components/choose-contact/choose-contact.component";
import { ContactManagementComponent } from './components/contact-management/contact-management.component';

@NgModule({
  imports: [
    SharedModule,
    AsyncPipe,
  ],
  declarations: [
    ChooseContactComponent,
    ContactManagementComponent
  ],
  exports: [
    ContactManagementComponent,
    ChooseContactComponent
  ],
})
export class ContactsModule {
}
