import {Component, OnInit} from '@angular/core';
import {PrimeNGConfig} from 'primeng/api';
import {UserRepository} from './modules/user/states/user.repository';
import {AuthService} from './shared/services/auth.service';
import {take} from 'rxjs';
import {AppDataLoadService} from './shared/services/app-data-load.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private readonly primengConfig: PrimeNGConfig,
    private readonly _userRepository: UserRepository,
    private readonly _authService: AuthService,
    private readonly _appDataLoadService: AppDataLoadService
  ) {
  }

  ngOnInit(): void {
    this._userRepository.getActiveUser().pipe(take(1)).subscribe((user) => {
      if (!user) {
        this._authService.loadUser();
        void this._appDataLoadService.loadAppData();
      }
    });

    this.primengConfig.ripple = true;

    document.addEventListener('gesturestart', function (e) {
      e.preventDefault();
    });
  }
}
