import {Injectable} from '@angular/core';
import {AnimalSheltersRepository} from 'src/app/modules/animals/states/animal-shelter.repository';
import {AnimalRepository} from 'src/app/modules/animals/states/animal.repository';
import {take} from 'rxjs';
import {AuthService} from './auth.service';
import {CompetentAuthoritiesRepository} from '../states/competent-authorities.repository';
import { environment } from 'src/environment/environment';
import { TokenService } from './token.service';
import { ContactRepository } from 'src/app/modules/contact/states/contact.repository';

@Injectable({
  providedIn: 'root'
})
export class AppDataLoadService {

  constructor(
    private readonly _authService: AuthService,
    private readonly _animalRepository: AnimalRepository,
    private readonly _animalShelterRepository: AnimalSheltersRepository,
    private readonly _competentAuthoritiesRepository: CompetentAuthoritiesRepository,
    private readonly _tokenService: TokenService,
    private readonly _contactRepository: ContactRepository
  ) {
  }

  async loadAppData() {
    const user = await this._authService.currentUser$.pipe(take(1)).toPromise();
    this._animalShelterRepository.loadAnimalShelters();
    this._competentAuthoritiesRepository.loadCompetentAuthorities();
    if (user && user.defaultAnimalShelter !== null) {
      this._animalRepository.getByShelter(user.defaultAnimalShelter.id);
    } else {
      this._animalRepository.getAnimals$();
    }
    this.loadContactData();
  }

  loadContactData() {
    setTimeout(() => {
      const token = this._tokenService.getAccessToken();
      fetch(environment.apiUrl + '/contacts', {
        method: 'GET',
        headers: {
          'X-Auth-Token': `${token}`,
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP Fehler! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        this._contactRepository.setAll(data);
      })
      .catch(error => console.error('Fehler beim Laden der großen Daten:', error));
    }, 5000);
  }    
}
