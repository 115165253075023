import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {AppComponent} from "./app.component";
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  provideHttpClient,
  withInterceptors
} from "@angular/common/http";
import {authInterceptor} from "./shared/auth/auth.interceptor";
import {ConfirmationService, MessageService} from "primeng/api";
import {AppRoutingModule} from "./app-routing.module";
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {AuthService} from "./shared/services/auth.service";
import {JwtInterceptor} from "./shared/auth/jwt.interceptor";
import {TranslocoService} from '@jsverse/transloco';
import {firstValueFrom} from 'rxjs';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TranslocoRootModule } from './transloco-root.module';
import { LayoutModule } from './layout/layout.module';
import { ModulesModule } from './modules/modules.module';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    LayoutModule,
    ModulesModule,
    SharedModule,
    TranslocoRootModule,
    ConfirmDialogModule
  ],
  providers: [
    provideHttpClient(withInterceptors([authInterceptor])),
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {
      provide: APP_INITIALIZER,
      useFactory: (auth: AuthService, transloco: TranslocoService) => {
        auth.loadToken();
        return () => firstValueFrom(transloco.load('de'));
      },
      deps: [AuthService, TranslocoService],
      multi: true,
    },
    MessageService,
    ConfirmationService
  ],
  bootstrap: [AppComponent],
  exports: [],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class AppModule {
}
