import {Injectable} from "@angular/core";
import { AnimalMediaCategoryModel } from '../models/animal-media-category.model';
import { HelperService } from 'src/app/shared/services/helper.service';
import { AnimalMediaCategory } from '../models/animal-media-category.enum';

@Injectable({
  providedIn: 'root'
})
export class AnimalImageService {

  constructor(
    private _helperService: HelperService
  ) {
  }

  getAnimalImageCategories(): AnimalMediaCategoryModel[] {
    return this._helperService.getEnumOptions(AnimalMediaCategory, 'animal.imageCategory', true);
  }

}
