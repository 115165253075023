<ng-container *ngIf="videoUrl?.length; else noVideo">
  <span class="video-wrapper">
    <video
      controls
      [muted]="false"
      [autoplay]="false"
      [poster]="thumbnailUrl"
      [style.background-color]="'#a28f6e'"
    >
      <source [src]="videoUrl" type="video/mp4"/>
    </video>
    <div class="icon-website-wrapper" *ngIf="animal?.syncWithWebsite && iconBadge">
      <i class="pi pi-globe"
         pTooltip="{{ 'animalOverview.syncWithWebsite' | transloco }}"
         tooltipPosition="top"
      ></i>
    </div>
  </span>
</ng-container>
<ng-template #noVideo>
    <span class="video-wrapper" [style.background-image]="'url(' + thumbnailUrl + ')'"
          [style.background-color]="'#a28f6e'">
    <div class="icon-website-wrapper" *ngIf="animal?.syncWithWebsite && iconBadge">
      <i
        class="pi pi-globe"
        pTooltip="{{ 'animalOverview.syncWithWebsite' | transloco }}"
        tooltipPosition="top"
      ></i>
    </div>
  </span>
</ng-template>
