import { createStore, select, withProps } from '@ngneat/elf';
import { withRequestsCache, withRequestsStatus } from '@ngneat/elf-requests';
import { Injectable } from "@angular/core";
import { AnimalOriginModel } from "../models/animal-origin.model";
import { RestService } from "../../../shared/services/rest.service";
import { Observable, of } from 'rxjs';
import { tap, switchMap, catchError, take } from "rxjs/operators";
import { AnimalRepository } from './animal.repository';

export interface AnimalOriginState {
  origins: Record<number, AnimalOriginModel[]>;
}

export const originStore = createStore(
  { name: 'animalOrigin' },
  withProps<AnimalOriginState>({ origins: {} }),
  withRequestsCache<'animalOrigin'>(),
  withRequestsStatus<'animalOrigin'>()
);

@Injectable({ providedIn: 'root' })
export class AnimalOriginRepository {
  constructor(
    private _restService: RestService,
    private _animalRepository: AnimalRepository
  ) {
    // silence is golden
  }

  getByAnimalId(animalId: number): Observable<AnimalOriginModel[]> {
    if (!animalId) {
      return of([]);
    }

    return originStore.pipe(
      select(state => state.origins[animalId] ?? null),
      take(1),
      switchMap(cachedOrigins => {
        if (cachedOrigins !== null) {
          return of(cachedOrigins);
        }
        return this._restService.httpGet(`/animal_origins?animal.id=${animalId}`).pipe(
          tap(response => {
            if (!response || !Array.isArray(response)) {
              return;
            }
            originStore.update(state => ({
              ...state,
              origins: {
                ...state.origins,
                [animalId]: response
              }
            }));
          }),
          catchError(() => of([]))
        );
      })
    );
  }

  getAll() {
    return this._restService.httpGet('/animal_origins')
      .pipe(
        tap((origins: AnimalOriginModel[]) => {
          if (origins && origins.length > 0) {
            this.setAll(origins);
          }
        })
      );
  }

  setAll(animalOrigins: AnimalOriginModel[]) {
    const updatedOrigins = animalOrigins.reduce((acc, entry) => {
      const animalId = entry.animal?.id;
      if (animalId) {
        acc[animalId] = [...(acc[animalId] || []), entry];
      }
      return acc;
    }, {} as Record<number, AnimalOriginModel[]>);

    originStore.update(state => ({
      ...state,
      origins: {
        ...state.origins,
        ...updatedOrigins
      }
    }));
  }

  create(payload: any, animalId: number) {
    return this._restService.httpPost('/animal_origins', payload)
      .pipe(
        tap((response: AnimalOriginModel) => {
          originStore.update(state => ({
            ...state,
            origins: {
              ...state.origins,
              [animalId]: [...(state.origins[animalId] || []), response]
            }
          }));
        })
      );
  }

  update(id: number, payload: any, animalId: number) {
    return this._restService.httpPut(`/animal_origins/${id}`, payload)
      .pipe(
        tap((response: AnimalOriginModel) => {
          originStore.update(state => ({
            ...state,
            origins: {
              ...state.origins,
              [animalId]: state.origins[animalId]?.map(entry =>
                entry.id === id ? response : entry
              ) || []
            }
          }));
        })
      );
  }

  delete(id: number, animalId: number) {
    return this._restService.httpDelete(`/animal_origins/${id}`)
      .pipe(
        tap(() => {
          originStore.update(state => ({
            ...state,
            origins: {
              ...state.origins,
              [animalId]: state.origins[animalId]?.filter(entry => entry.id !== id) || []
            }
          }));
        })
      );
  }
}
