<div class="card mb-3">
  <div class="flex justify-content-between">
    <h2>{{ 'animalDetails.media.images.headline' | transloco }}</h2>
    <button 
        *ngIf="selectedImages.length > 0"
        pButton 
        type="button" 
        icon="pi pi-download"
        class="p-button-sm flex justify-content-end"
        label="{{ 'download.dowloaden' | transloco }} - ({{ selectedImages.length }})"
        (click)="downloadSelectedImages()"
    ></button>
  </div>
  <div class="grid gap-8 justify-content-center align-items-center p-3">
    <div class="col-3 max-w-20rem" *ngIf="!isEditingMode">
      <app-image-upload [animal]="animal"></app-image-upload>
    </div>
    <div class="col-3 max-w-20rem" *ngFor="let image of images">
      <div class="card flex flex-column min-h-full max-h-30rem">
        <span>{{image.date | date: 'dd.MM.yyyy'}}</span>
        <div class="text-center p-3 flex-grow-1">
          <div class="blurred-image-container" [class.cursor-pointer]="!isEditingMode" (click)="openDialog(image)">
            <div class="blurred flex align-items-center justify-content-center">
              <app-animal-image-container [animal]="animal" [id]="image.id" [variant]="'app-preview'"></app-animal-image-container>
            </div>
          </div>
        </div>
        <div class="flex flex-row flex-wrap gap-3 align-items-center justify-content-center icon-container">
          <div class="icon-wrapper" [ngClass]="image.preview ? 'active' : ''"
            (click)="clickTogglePreview(image)"
            pTooltip="{{ 'tooltip.togglePreview' | transloco }}"
            tooltipPosition="top"
          >
            <i class="pi pi-image cursor-pointer"></i>
          </div>
          <div class="icon-wrapper" [ngClass]="image.syncWithSharedFolder ? 'active' : ''"
            (click)="clickFileExport(image)"
            pTooltip="{{ 'tooltip.syncWithSharedFolder' | transloco }}"
            tooltipPosition="top"
          >
            <i class="pi pi-file-export cursor-pointer"></i>
          </div>
          <div class="icon-wrapper" [ngClass]="image.syncWithWebsite ? 'active' : ''"
               (click)="clickFileSync(image)"
               pTooltip="{{ 'tooltip.syncWithWebsite' | transloco }}"
               tooltipPosition="top"
          >
            <i class="pi pi-desktop cursor-pointer"></i>
          </div>
          <div
            class="icon-wrapper delete"
            [ngClass]="selectedImgToDelete.includes(image.id) ? 'delete' : ''"
            (click)="deleteImage(image)"
            pTooltip="{{ 'tooltip.delete' | transloco }}"
            tooltipPosition="top"
          >
            <i class="pi pi-trash cursor-pointer"></i>
          </div>
        </div>
        <div class="flex flex-row flex-wrap gap-2 align-items-center justify-content-center labels-container">
          <div
            class="label-wrapper"
          >
            <i class="pi pi-file-edit mr-2"></i> {{image.description}}
          </div>
          <div
            class="label-wrapper"
          >
            <i class="pi pi-tags mr-2"></i> {{image.translatedCategories}}
          </div>
          <div
            class="label-wrapper"
          >
            <i class="pi pi-file-export mr-2"></i> {{image.externalDescription}}
          </div>
          <div class="p-2">
            <p-checkbox 
              [binary]="true"
              [(ngModel)]="selectedImagesMap[image.id]"
              (onChange)="onImageSelectChange(image)"
              inputId="img-{{ image.id }}"
            ></p-checkbox>
            <label for="img-{{ image.id }}" class="ml-2">{{ 'download.downloadSelect' | transloco }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<p-dialog
  [(visible)]="displayDialog"
  [modal]="true"
  [style]="{ width: '50vw', height: '75vh' }"
  [closable]="true"
  [draggable]="false"
  [resizable]="false"
  *ngIf="selectedImage"
  #dialog
>
  <div class="flex flex-wrap justify-content-center align-items-center relative">
    <div class="image-container">
      <app-animal-image-container [animal]="animal" [id]="selectedImage.id" [variant]="'app-preview'"></app-animal-image-container>
    </div>
    </div>
  <div>
    <div class="flex flex-row flex-wrap gap-3 align-items-center justify-content-center icon-container" >
      <div class="icon-wrapper" [ngClass]="selectedImage.preview ? 'active' : ''"
        (click)="selectedImage.preview = !selectedImage.preview"
        pTooltip="{{ 'tooltip.togglePreview' | transloco }}"
        tooltipPosition="top"
      >
        <i class="pi pi-image cursor-pointer"></i>
      </div>
      <div class="icon-wrapper" [ngClass]="selectedImage.syncWithSharedFolder ? 'active' : ''"
        (click)="selectedImage.syncWithSharedFolder = !selectedImage.syncWithSharedFolder"
        pTooltip="{{ 'tooltip.syncWithSharedFolder' | transloco }}"
        tooltipPosition="top"
      >
        <i class="pi pi-file-export cursor-pointer"></i>
      </div>
      <div class="icon-wrapper" [ngClass]="selectedImage.syncWithWebsite ? 'active' : ''"
           (click)="selectedImage.syncWithWebsite = !selectedImage.syncWithWebsite"
           pTooltip="{{ 'tooltip.syncWithWebsite' | transloco }}"
           tooltipPosition="top"
      >
        <i class="pi pi-desktop cursor-pointer"></i>
      </div>
    </div>
    <div class="flex justify-content-center align-items-center mt-3">
      <p-multiSelect
        class="p-mutliselect-sm flex"
        [options]="imageCategoryOptions"
        [(ngModel)]="selectedImage.categories"
        optionLabel="name"
        optionValue="value"
        placeholder="{{ 'placeholder.selectCategory' | transloco }}"
        [appendTo]="dialog"
      ></p-multiSelect>
    </div>
    <div class="flex justify-content-center align-items-center mt-3">
      <div>
        <label class="py-1" for="date">{{ 'animalWhereabout.date' | transloco }}</label>
        <p-calendar
          class="flex"
          id="date"
          [(ngModel)]="selectedImage.date"
          dateFormat="dd.mm.yy"
          [showIcon]="true"
          appCalendarMaxDate
        ></p-calendar>
      </div>
    </div>

    <div class="flex justify-content-between align-items-center gap-3 mt-3">
      <div class="col-6">
        <label class="py-1" for="description">
          {{ 'animalImages.description' | transloco }}
        </label>
        <textarea
          area
          pInputText
          class="p-inputtext-sm w-full"
          id="description"
          [(ngModel)]="selectedImage.description"
          rows="10"
        ></textarea>
      </div>
        <div class="col-6">
          <label class="py-1" for="externalDescription">
            {{ 'animalImages.externalDescription' | transloco }}
          </label>
          <textarea
            area
            pInputText
            class="p-inputtext-sm w-full"
            id="externalDescription"
            [(ngModel)]="selectedImage.externalDescription"
            rows="10"
          ></textarea>
        </div>
    </div>

  </div>
  <ng-template pTemplate="footer">
    <div class="flex">
      <div class="flex-1">
        <button class="p-button p-button-sm" (click)="toggleEditDialog()">
          {{ 'modal.cancel' | transloco }}
        </button>
        <button class="p-button p-button-sm" (click)="saveImage(selectedImage)">
          {{ 'modal.save' | transloco }}
        </button>
      </div>
    </div>
  </ng-template>
</p-dialog>
