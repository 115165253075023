import { AnimalImagesRepository } from './../../../modules/animals/states/animal-images.repository';
import { Component, Input, ViewChild } from '@angular/core';
import { ToastService } from '../../services/toast.service';
import { FileUpload } from "primeng/fileupload";
import { RestService } from "../../services/rest.service";
import { AnimalModel } from "../../../modules/animals/models/animal.model";
import { WaitingDialogService } from '../../services/waiting-dialog.service';
import { AnimalRepository } from 'src/app/modules/animals/states/animal.repository';
import {AnimalVideoRepository} from "../../../modules/animals/states/animal-video.repository";

@Component({
  selector: 'app-video-upload',
  templateUrl: './video-upload.component.html',
  styleUrls: ['./video-upload.component.scss'],
})
export class VideoUploadComponent {
  @ViewChild(FileUpload) fileUpload!: FileUpload;
  @Input() animal!: AnimalModel | undefined;
  uploadedFiles: any[] = [];
  uploadUrl: string = this._restService._baseUrl + '/animal_videos';
  maxFilesToShow = 4;
  filesToShow = [];


  constructor(
    private readonly _toastService: ToastService,
    private readonly _restService: RestService,
    private readonly _animalVideoRepository: AnimalVideoRepository,
    private readonly _waitingDialogService: WaitingDialogService,
    private readonly _animalRepository: AnimalRepository
  ) {
  }

  get isFileSelected(): boolean {
    return !!(this.fileUpload && this.fileUpload.files && this.fileUpload.files.length > 0);
  }

  onUpload(event: { files: any; }) {
    if (!this.animal) {
      return;
    }
    this._waitingDialogService.showWaitingDialog(true);
    let uploadCount = 0;

    for (const file of event.files) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('animal_id', this.animal.id.toString());

      this._animalVideoRepository.addVideo(formData, this.animal.id).subscribe({
        next: () => {
          uploadCount++;
          if (uploadCount === event.files.length) {
            this._toastService.showSuccess('Video(s) erfolgreich hochgeladen.');
            this.fileUpload.clear();
            if (this.animal) {
              this._animalRepository.getById(this.animal.id);
            }
            this._waitingDialogService.showWaitingDialog(false);
          }
        },
        error: () => {
          this._toastService.showError('Fehler beim Hochladen des Videos.');
          this._waitingDialogService.showWaitingDialog(false);
        }
      });
    }

  }

  getObjectURL(file: File): string {
    return URL.createObjectURL(file);
  }

}
