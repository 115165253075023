import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {AnimalWhereaboutModel} from '../models/animal-whereabout.model';
import {AnimalModel} from '../models/animal.model';
import {AnimalWhereaboutsRepository} from '../states/animal-whereabouts.repository';
import {AnimaleWhereAboutService} from '../services/animal-whereabout.service';
import {catchError, finalize, map, Observable, switchMap, take, takeWhile, throwError} from 'rxjs';
import {TranslocoService} from '@jsverse/transloco';
import {PersonTitleModel} from "../../../shared/models/person-title.model";
import {ContactRepository} from "../../contact/states/contact.repository";
import {ContactModel} from "../../contact/models/contact.model";
import {HelperService} from "../../../shared/services/helper.service";
import { ConfirmationService } from 'primeng/api';
import { ToastService } from 'src/app/shared/services/toast.service';
import { CanComponentDeactivate } from 'src/app/shared/guard/can-deactivate.guard';
import { AnimalRepository } from '../states/animal.repository';

export interface NewAnimalWhereaboutItem {
  id: string;
  animal: { id: number };
  type: string | null;
  date: Date | null;
  contact: { id: number | null };
  dateCare: Date | null;
  information: string | null
}

@Component({
  selector: 'app-animal-details-whereabouts',
  templateUrl: './animal-details-whereabouts.component.html',
  styleUrls: ['./animal-details-whereabouts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnimalDetailsWhereaboutsComponent implements OnInit, OnChanges {
  @Input() animal: AnimalModel | undefined;
  @Output() formsValid: EventEmitter<boolean> = new EventEmitter();
  alive: boolean = true;
  objectKeys = Object.keys;
  isEditingMode: boolean = false;
  isAddMode: boolean = false;
  animalWhereaboutsItems: { [id: string]: AnimalWhereaboutModel | any} = {};
  whereAboutTypeOptions: { value: string, name: string }[] = [];
  selectedTypeOption: { [id: string]: { value: string, name: string } } = {};
  personSalutationOptions: PersonTitleModel[] = [];

  contacts: { [id: string]: ContactModel } = {};
  editingItem: any;
  openEdit: boolean = false;
  editType: any;
  editDialogContactKey: any;
  editDialogWhereAboutKey: number | null = null;

  contact: ContactModel = {} as ContactModel;
  existingContact: boolean = false;
  existingContactUpdated: boolean = false;
  validationStatusChanged: boolean = true;

  hasUnsavedData: boolean = false;

  constructor(
    private _animalWhereaboutsRepository: AnimalWhereaboutsRepository,
    private _contactRepository: ContactRepository,
    private _translocoService: TranslocoService,
    private _animalWhereaboutService: AnimaleWhereAboutService,
    private _helperService: HelperService,
    private cdr: ChangeDetectorRef,
    private _confirmationService: ConfirmationService,
    private _animalRepository: AnimalRepository,
    private _toast: ToastService
  ) {
  }

  ngOnInit(): void {
    this.whereAboutTypeOptions = this._animalWhereaboutService.getAnimalWhereaboutTypeOptions().sort((a, b) =>
      a.name.localeCompare(b.name)
    );

    this.personSalutationOptions = this._helperService.getSalutationOptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['animal'] && this.animal?.id) {
      this.loadAnimalWhereabouts();
    }
  }
  
  private loadAnimalWhereabouts(): void {
    if (!this.animal?.id) {
      return;
    }
    this._animalWhereaboutsRepository.getByAnimalId(this.animal.id)
      .pipe(takeWhile(() => this.alive))
      .subscribe((animalWhereabouts) => {
        if (Object.keys(animalWhereabouts).length) {
          this.setAnimalWhereabouts(animalWhereabouts);
        }
      });
  }

  setAnimalWhereabouts(animalWhereabouts: AnimalWhereaboutModel[] ) {
    Object.values(animalWhereabouts).forEach((animalWhereabout: AnimalWhereaboutModel) => {
      if (!animalWhereabout.id) {
        return;
      }
      this._contactRepository.getById(animalWhereabout.contact?.id).pipe(take(1)).subscribe((contact: ContactModel) => {
        this.animalWhereaboutsItems[animalWhereabout.id] = JSON.parse(JSON.stringify(animalWhereabout));
        this.contacts[animalWhereabout.contact?.id] = contact;
        if (this.animalWhereaboutsItems[animalWhereabout.id].date) {
          const dateValue = this.animalWhereaboutsItems[animalWhereabout.id].date;
          const formattedDate = dateValue ? new Date(dateValue) : null;
          this.animalWhereaboutsItems[animalWhereabout.id].date = formattedDate;
        }
        if (this.animalWhereaboutsItems[animalWhereabout.id].dateCare) {
          const dateCareValue = this.animalWhereaboutsItems[animalWhereabout.id].dateCare;
          const formattedDateCare = dateCareValue ? new Date(dateCareValue) : null;
          this.animalWhereaboutsItems[animalWhereabout.id].dateCare = formattedDateCare;
        }

        Promise.resolve().then(() => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
          const foundOption = this.whereAboutTypeOptions.find(option => option.value === animalWhereabout.type);
          if (foundOption) {
            this.selectedTypeOption[animalWhereabout.id] = foundOption;
          }

          this.cdr.detectChanges();
        });
      });
    });
    this.cdr.detectChanges();
  }

  toggleEditDialog(open: boolean, editType: string, whereAboutKey: number | null, contactKey?: number | null) {
    if (open) {
      if (editType === 'general' && whereAboutKey) {
        this.editingItem = JSON.parse(JSON.stringify(this.animalWhereaboutsItems[whereAboutKey]));
        this.editingItem.date = this.editingItem.date
        ? new Date(this.editingItem.date)
        : null;
        this.editingItem.dateCare = this.editingItem.dateCare
        ? new Date(this.editingItem.dateCare)
        : null;
      } else if (editType === 'givingContact') {
        if (contactKey && this.contacts[contactKey]) {
          this.editingItem = JSON.parse(JSON.stringify(this.contacts[contactKey]));
        } else {
          this.editingItem = {} as ContactModel;
        }
        this.existingContact = Object.values(this.editingItem).some(value => value !== null && value !== undefined);
      }
      this.editType = editType;
      this.editDialogContactKey = contactKey;
      this.editDialogWhereAboutKey = whereAboutKey;
      this.isEditingMode = true;

      this.openEdit = open

    } else {
      this.openEdit = open;
      this.editDialogContactKey = '';
      this.editDialogWhereAboutKey = -1;
      this.editingItem = null;
      this.isEditingMode = false;
    }
  }

  onContactUpdate($event: any) {
    this.contact = $event;
  }
  onValidationStatusChanged($event: boolean) {
    this.validationStatusChanged = $event;
  }
  onExistingContactUpdated($event: boolean) {
    this.existingContactUpdated = $event;
  }
  onExistingContactSelected($event: ContactModel) {
    this.contact = $event;
    this.existingContact = Object.keys($event).length ? true : false;
  }


  setUnsavedChanges(value: boolean) {
    this.hasUnsavedData = value;
  }

  addNewWhereabout(): void {
    if (!this.animal) {
      return;
    }
    this.setUnsavedChanges(true);
    const emptyWhereabout: NewAnimalWhereaboutItem = {
      id: 'dummy',
      animal: {id: this.animal?.id} as any,
      type: null,
      date: null,
      contact: {id: null},
      dateCare: null,
      information: null
    };

    this.editingItem = {} as ContactModel;
    (this.animalWhereaboutsItems['dummy'] as NewAnimalWhereaboutItem) = emptyWhereabout;
    this.isAddMode = true;
    this.cdr.detectChanges();
  }

  cancelNewWhereabout() {
    delete this.animalWhereaboutsItems['dummy'];
    this.isAddMode = false;
    this.editingItem = null;
    this.contact = {} as ContactModel;
    this.setUnsavedChanges(false);
    this.cdr.detectChanges();
  }

  saveNewWhereabout() {
    let date = this.animalWhereaboutsItems['dummy'].date
      ? this._helperService.normalizeDate(this.animalWhereaboutsItems['dummy'].date)
      : null;
  
    let dateCare = this.animalWhereaboutsItems['dummy'].dateCare
      ? this._helperService.normalizeDate(this.animalWhereaboutsItems['dummy'].dateCare)
      : null;
  
    const payload: any = {
      type: this.selectedTypeOption['dummy']?.value,
      date: date,
      dateCare: dateCare,
      information: this.animalWhereaboutsItems['dummy'].information,
      animal: { id: this.animal?.id } as any,
    };
  
    let request$;
    if (this.contact && this.contact.id && !this.existingContactUpdated) {
      payload.contact = { id: this.contact.id };
      request$ = this._animalWhereaboutsRepository.create(payload);
    } else if (this.contact && this.contact.id && this.existingContactUpdated) {
      request$ = this._contactRepository.update(this.contact, this.contact.id).pipe(
        switchMap((response) => {
          payload.contact = { id: this.contact.id };
          this.contacts[response.id] = response;
          return this._animalWhereaboutsRepository.create(payload);
        })
      );
    } else if (this.contact && Object.keys(this.contact).length && !this.contact.id) {
      request$ = this._contactRepository.create(this.contact).pipe(
        switchMap((response) => {
          payload.contact = { id: response.id };
          this.contacts[response.id] = response;
          return this._animalWhereaboutsRepository.create(payload);
        })
      );
    } else {
      request$ = this._animalWhereaboutsRepository.create(payload);
    }
  
    request$
      .pipe(
        take(1),
        catchError((error) => {
          this._toast.showError(this._translocoService.translate('toast.saveError'));
          console.error('Fehler beim Speichern des Aufenthaltsortes:', error);
          return throwError(() => error);
        }),
        finalize(() => {
          this.cdr.detectChanges();
        })
      )
      .subscribe((response) => {
        this.cancelNewWhereabout();
        this.handleResponse(response);
        this._toast.showSuccess(this._translocoService.translate('toast.saveSuccess'));
  
        if (this.animal?.id) {
          this._animalWhereaboutsRepository.getByAnimalId(this.animal?.id)
            .pipe(take(1))
            .subscribe((animalWhereabouts: AnimalWhereaboutModel[]) => {
              if (Object.keys(animalWhereabouts).length) {
                this.setAnimalWhereabouts(animalWhereabouts);
              }
            });
        }
      });
  }
  

  handleResponse(response: any) {
    response.date = response.date
    ? new Date(response.date)
    : null;
    response.dateCare = response.dateCare
    ? new Date(response.dateCare)
    : null;
    this.animalWhereaboutsItems = {
      ...this.animalWhereaboutsItems,
      [response.id]: response
    };
    const foundOption = this.whereAboutTypeOptions.find(option => option.value === response.type);
    if (foundOption) {
    this.selectedTypeOption[response.id] = foundOption;
    }
    if (this.animal?.id) {
      this._animalRepository.updateAnimalStore(this.animal.id, { updatedAt: new Date() });
    }
    this.cdr.detectChanges();
  }


  dummyFirst = (a: any, b: any) => {
    if (a.key === 'dummy') return -1;
    if (b.key === 'dummy') return 1;

    return b.value.id - a.value.id;
  };
  


  private updateContactAndWhereAbout(
    contactPayload: ContactModel,
    whereAboutPayload: AnimalWhereaboutModel
  ): Observable<void> {
    return this._contactRepository.update(contactPayload, contactPayload.id).pipe(
      switchMap((response) => {
        whereAboutPayload.contact = {id: contactPayload.id};
        this.contacts[response.id] = response;
        return this._animalWhereaboutsRepository.update(
          whereAboutPayload.id,
          whereAboutPayload
        ).pipe(
          map((response) => {
            response.date = response.date
              ? new Date(response.date)
              : null;
              response.dateCare = response.dateCare
              ? new Date(response.dateCare)
              : null;
            this.animalWhereaboutsItems = {
              ...this.animalWhereaboutsItems,
              [whereAboutPayload.id]: response
            };
        })
      );
      }),
      take(1)
    );
  }


  private createContactAndUpdateWhereAbout(
    contactPayload: ContactModel,
    whereAboutPayload: AnimalWhereaboutModel,
    key: number,
    editingType: string
  ): Observable<void> {
    return this._contactRepository.create(contactPayload).pipe(
      map(response => {
        const updatedPayload = { ...whereAboutPayload };

      if (editingType === 'givingContact') {
          updatedPayload.contact = { id: response.id };
        }
        this.contacts[response.id] = response;
        return updatedPayload;
      }),
      switchMap(updatedPayload =>
        this._animalWhereaboutsRepository.update(key, updatedPayload).pipe(
          map((response) => {
            response.date = response.date
              ? new Date(response.date)
              : null;
              response.dateCare = response.dateCare
              ? new Date(response.dateCare)
              : null;
            this.animalWhereaboutsItems = {
              ...this.animalWhereaboutsItems,
              [key]: response
            };
          })
        )
      ),
      take(1)
    );
  }


  save(animalWhereAboutItem: AnimalWhereaboutModel) {
    if (this.editType === 'general') {
      this.updateAnimalWhereAbout(animalWhereAboutItem);
    } else if (this.editType === 'givingContact' || this.editType === 'owningContact') {
      if (this.existingContact && this.editDialogWhereAboutKey) {
        this.updateContactAndWhereAbout(
          this.contact,
          this.animalWhereaboutsItems[this.editDialogWhereAboutKey]
        ).pipe(
          take(1),
          catchError((error) => {
            this._toast.showError(this._translocoService.translate('toast.saveError'));
            console.error('Fehler beim Speichern des Kontakts:', error);
            return throwError(() => error);
          }),
          finalize(() => {
            this.cdr.detectChanges();
          })
        ).subscribe(() => {
          if (this.editDialogWhereAboutKey) {
            this.animalWhereaboutsItems[this.editDialogWhereAboutKey].contact = { id: this.contact.id };
            this.toggleEditDialog(false, '', null);
            this._toast.showSuccess(this._translocoService.translate('toast.saveSuccess'));
          }
        });
  
      } else if (this.contact && this.editDialogWhereAboutKey) {
        this.createContactAndUpdateWhereAbout(
          this.contact,
          animalWhereAboutItem,
          this.editDialogWhereAboutKey,
          this.editType
        ).pipe(
          take(1),
          catchError((error) => {
            this._toast.showError(this._translocoService.translate('toast.saveError'));
            console.error('Fehler beim Erstellen des Kontakts:', error);
            return throwError(() => error);
          }),
          finalize(() => {
            this.cdr.detectChanges();
          })
        ).subscribe(() => {
          this.toggleEditDialog(false, '', null);
          this._toast.showSuccess(this._translocoService.translate('toast.saveSuccess'));
        });
      }
    }
  }
  

  updateAnimalWhereAbout(animalWhereAboutItem: AnimalWhereaboutModel | any): void {
    if (!this.animal || !this.animal.id || !this.editDialogWhereAboutKey) {
      return;
    }
    let date = null;
    if (animalWhereAboutItem.date) {
      date = this._helperService.determineDateOfReceipt(
        this.animalWhereaboutsItems[this.editDialogWhereAboutKey].date,
        animalWhereAboutItem.date
      );
    }
    let dateCare = null;
    if (animalWhereAboutItem.dateCare) {
      dateCare = this._helperService.determineDateOfReceipt(
        this.animalWhereaboutsItems[this.editDialogWhereAboutKey].dateCare,
        animalWhereAboutItem.dateCare
      );
    }
  
    const whereAboutPayload: AnimalWhereaboutModel = {
      ...animalWhereAboutItem,
      date,
      dateCare,
      animal: { id: this.animal.id },
      contact: animalWhereAboutItem.contact && animalWhereAboutItem.contact.id ? { id: animalWhereAboutItem.contact.id } : null,
      type: this.selectedTypeOption[animalWhereAboutItem.id].value,
      information: animalWhereAboutItem.information
    };

    const key = String(this.editDialogWhereAboutKey);
  
    this._animalWhereaboutsRepository.update(this.editDialogWhereAboutKey, whereAboutPayload)
    .pipe(
      take(1),
      catchError((error) => {
        this._toast.showError(this._translocoService.translate('toast.saveError'));
        console.error('Fehler beim Speichern des Verbleib:', error);
        return throwError(() => error);
      }),
      finalize(() => {
        this.cdr.detectChanges();
      })
    )
    .subscribe(() => {
      this.animalWhereaboutsItems = {
        ...this.animalWhereaboutsItems,
        [key]: whereAboutPayload
      };

      this.toggleEditDialog(false, '', null);
      this._toast.showSuccess(this._translocoService.translate('toast.saveSuccess'));
    });

  }


  dummyTranslateFunction() {
    this._translocoService.translate('animalWhereabout.died');
    this._translocoService.translate('animalWhereabout.adopted');
    this._translocoService.translate('animalWhereabout.back-with-owner');
    this._translocoService.translate('animalWhereabout.passed-on');
    this._translocoService.translate('animalWhereabout.ran-away');
    this._translocoService.translate('animalWhereabout.released-into-wild');
    this._translocoService.translate('animalWhereabout.adopted-on-trial');
    this._translocoService.translate('animalWhereabout.outdoor-animal');
    this._translocoService.translate('animalWhereabout.foster-home');
    this._translocoService.translate('animalWhereabout.euthanasia');
    this._translocoService.translate('animalWhereabout.conveyed');
  }

  deleteWhereabout(id: number) {
    if (!this.animal?.id) {
      return;
    }
  
    this._confirmationService.confirm({
      message: this._translocoService.translate('delete_confirmation'),
      acceptLabel: this._translocoService.translate('yes'),
      rejectLabel: this._translocoService.translate('no'),
      header: '',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass: "p-button-danger p-button-text",
      rejectButtonStyleClass: "p-button-text p-button-text",
      acceptIcon: "none",
      rejectIcon: "none",
      accept: () => {
        this._animalWhereaboutsRepository.delete(id, this.animal!.id)
          .pipe(take(1))
          .subscribe(() => {
            delete this.animalWhereaboutsItems[id];
            this._toast.showSuccess(this._translocoService.translate('toast.deleteSuccess'));
            this.cdr.detectChanges();
          });
      }
    });
  }
  

  closeEditModal() {
    throw new Error('Method not implemented.');
  }

}
