import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../../shared/services/auth.service";
import {MenuItem} from "primeng/api";
import { LayoutService } from '../../layout.service';

@Component({
  selector: 'app-profile-icon',
  templateUrl: './profile-icon.component.html',
  styleUrls: ['./profile-icon.component.scss']
})
export class ProfileIconComponent implements OnInit {
  items: MenuItem[] = [];

  constructor(
    private authService: AuthService,
    private layoutService: LayoutService
  ) {
  }

  ngOnInit() {
    this.items = [
      {
        label: 'Profile',
        icon: 'pi pi-fw pi-user',
        routerLink: ['/profile']
      },
      {
        label: 'Changelog',
        icon: 'pi pi-fw pi-list',
        command: () => this.openChangelog()
      },
      {
        label: 'Logout',
        icon: 'pi pi-fw pi-sign-out',
        command: () => this.logout()
      }
    ];
  }

  logout() {
    this.authService.logout();
  }

  openChangelog() {
    this.layoutService.openChangelogBehaviorSubject.next(true);
  }
}
