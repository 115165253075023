import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../shared/services/auth.service";
import {MessageService} from "primeng/api";
import {ActivatedRoute, Router} from "@angular/router";
import { WaitingDialogService } from 'src/app/shared/services/waiting-dialog.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [MessageService]
})
export class LoginComponent implements OnInit {
  pin = '';
  showPassword = false;
  returnUrl: string = '/home';

  constructor(
    private router: Router,
    private authService: AuthService,
    private _waitingDialogService: WaitingDialogService,
    private route: ActivatedRoute
  ) {
    if (localStorage.getItem('token')) {
      this.authService.isLoggedInSubject.next(true);
    }
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['returnUrl']) {
        this.returnUrl = params['returnUrl'];
      }
    });

    this.authService.isLoggedIn$.subscribe(isLoggedIn => {
      if (isLoggedIn) {
        this.router.navigateByUrl(this.returnUrl);
      }
    });
  }

  fieldPress(item: string | number) {
    if (item === 'clear') {
      this.pin = '';

      return;
    }

    this.pin += item;

    this.pinChanged();
  }

  onShowPswClear() {
    this.showPassword = !this.showPassword;
  }

  pinChanged() {
    if (this.pin.length === 6) {
      this.authService.login(this.pin);
      this._waitingDialogService.showWaitingDialog(true);
      return;
    }
  }
}
