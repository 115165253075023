<div class="changelog">
  <p-dialog [(visible)]="isChangelogVisible"
            [modal]="true"
            [style]="{ width: '80vw', height: '80vh' }"
            [draggable]="false"
            (onHide)="resetProperties()">
    <ng-template pTemplate="header">
      <div class="flex flex-row align-items-center w-full">
        <img class="logo" src="./../../../assets/layout/images/logo-header.svg" alt="Login">
        <span class="title">{{ 'changelog.changelog' | transloco }}</span>
      </div>
    </ng-template>
    <ng-template pTemplate="content" class="p-0">
      <div class="changelog-container">
        <div class="sidebar">
          <ul>
            <li *ngFor="let log of changelogData" [class.active]="isActive(log.id)">
              <a (click)="scrollTo(log.id)">{{ log.version }}</a>
            </li>
          </ul>
        </div>
        <div #scrollContainer class="content">
          <div *ngFor="let log of changelogData" [id]="'log-' + log.id" class="log-entry">
            <h2>{{ log.version }} <span>{{ log.date | date: 'MMMM dd, yyyy' }}</span></h2>
            <p>{{ log.description }}</p>

            <div *ngIf="log.newFeatures.length">
              <h3>{{ 'changelog.newFeatures' | transloco }}:</h3>
              <ul>
                <li *ngFor="let feature of log.newFeatures">{{ feature }}</li>
              </ul>
            </div>

            <div *ngIf="log.bugfixes.length">
              <h3>{{ 'changelog.bugFixes' | transloco }}:</h3>
              <ul>
                <li *ngFor="let bug of log.bugfixes">{{ bug }}</li>
              </ul>
            </div>

            <div *ngIf="log.technicalChanges.length">
              <h3>{{ 'changelog.technicalChanges' | transloco }}:</h3>
              <ul>
                <li *ngFor="let change of log.technicalChanges">{{ change }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </p-dialog>
</div>
