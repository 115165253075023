import {Injectable} from '@angular/core';
import {environment} from 'src/environment/environment';
import {finalize, Observable, of, switchMap} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {WaitingDialogService} from './waiting-dialog.service';
import {ToastService} from './toast.service';
import {TranslocoService} from '@jsverse/transloco';
import {TokenService} from './token.service';

@Injectable({
  providedIn: 'root'
})
export class RestService {
  public _baseUrl: string;

  constructor(
    private readonly http: HttpClient,
    private readonly _waitingDialogService: WaitingDialogService,
    private readonly _toast: ToastService,
    private readonly _translateService: TranslocoService,
    private readonly tokenService: TokenService
  ) {
    this._baseUrl = environment.apiUrl;
  }

  private executeRequest<T>(requestFn: () => Observable<T>): Observable<T | null> {
    const token = this.tokenService.getAccessToken();

    if (token && this.tokenService.isTokenExpired(token)) {
      return this.tokenService.renewToken().pipe(
        switchMap(newToken => {
          if (newToken) {
            return requestFn();
          } else {
            this._toast.showError(this._translateService.translate('general.toast.serverError'));
            return of(null);
          }
        }),
        finalize(() => this._waitingDialogService.showWaitingDialog(false))
      );
    } else if (token) {
      return requestFn().pipe(
        finalize(() => this._waitingDialogService.showWaitingDialog(false))
      );
      return requestFn();
    } else {
      this._toast.showError(this._translateService.translate('general.toast.serverError'));
      this._waitingDialogService.showWaitingDialog(false);
      return of(null);
    }
  }

  private sendRequest<T>(method: 'GET' | 'POST' | 'PUT' | 'DELETE', path: string, body?: any, options?: any): Observable<T | null> {
    return this.executeRequest(() => {
      let request: Observable<any>;

      switch (method) {
        case 'GET':
          request = this.http.get(this._baseUrl + path, options);
          break;
        case 'POST':
          request = this.http.post(this._baseUrl + path, body, options);
          break;
        case 'PUT':
          request = this.http.put(this._baseUrl + path, body, options);
          break;
        case 'DELETE':
          request = this.http.delete(this._baseUrl + path, options);
          break;
        default:
          throw new Error('Unsupported request method');
      }

      return request;
    });
  }

  public httpGet = (path: string, options?: any): Observable<any> => this.sendRequest('GET', path, null, options);
  public httpPost = (path: string, body: any, options?: any): Observable<any> => this.sendRequest('POST', path, body, options);
  public httpPut = (path: string, body: any, options?: any): Observable<any> => this.sendRequest('PUT', path, body, options);
  public httpDelete = (path: string, options?: any): Observable<any> => this.sendRequest('DELETE', path, null, options);
}
