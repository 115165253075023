import {HttpInterceptorFn} from "@angular/common/http";
import {inject} from "@angular/core";
import { TokenService } from '../services/token.service';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const tokenService = inject(TokenService);
  const token = tokenService.getAccessToken();

  if (token) {
    const headers1 = {
      "X-Auth-Token": `${token}`,
      "Content-Type": "application/json",
    };

    let headers2 = undefined;
    if (req.url.includes('/animal_images') || req.url.includes('/animal_videos')) {
      headers2 =
        {
          "X-Auth-Token": `${token}`,
        };
    }

    const header = headers2 || headers1;

    req = req.clone({
      url: req.url,
      setHeaders: header,
    });
  }

  return next(req);
};
