import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { ContactRepository } from '../../states/contact.repository';
import { ContactModel } from '../../models/contact.model';
import { debounceTime, distinctUntilChanged, filter, Subject, take } from 'rxjs';
import { Scroller } from 'primeng/scroller';
import { HelperService } from 'src/app/shared/services/helper.service';

@Component({
  selector: 'app-contact-management',
  templateUrl: './contact-management.component.html',
  styleUrls: ['./contact-management.component.scss']
})
export class ContactManagementComponent {

  @ViewChild(Scroller) scroller!: Scroller;
  groupedContacts: Record<string, ContactModel[]> = {};
  alphabet: string[] = [];

  selectedLetter: string = '';
  filteredContacts: ContactModel[] = [];
  searchText: string = '';
  private searchSubject = new Subject<string>();
  searchedContacts: ContactModel[] = [];
  noSearchResults: boolean = false;

  openCreateContactModal: boolean = false;
  newContact: ContactModel = {} as ContactModel;
  validationStatusChanged: any;

  highlightedContactId: number | null = null;
  openEditContactModal: boolean = false;

  constructor(
    private readonly _contactRepository: ContactRepository,
    private _helperService: HelperService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.alphabet = [...Array(26)].map((_, i) => String.fromCharCode(65 + i));
    this.alphabet.push("#", "?");

    this._contactRepository.getAll().pipe(take(1)).subscribe((contacts: ContactModel[]) => {
      this.groupedContacts = this.groupContactsByLastName(contacts);
      this.filterByLetter(this.alphabet[0]);
    });

    this.searchSubject.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(searchTerm => {
      if (searchTerm.length >= 2) {
        this._contactRepository.searchContacts(searchTerm).pipe(take(1)).subscribe((contacts: ContactModel[]) => {
          this.searchedContacts = contacts;
        });
      } else {
        this.searchedContacts = [];
      }
    });
    
  }
  

  groupContactsByLastName(contacts: ContactModel[]): Record<string, ContactModel[]> {
    const groupedContacts: Record<string, ContactModel[]> = {};
  
    contacts.forEach((contact) => {
      let firstLetter = contact.lastName?.trim().charAt(0).toUpperCase() || "#";
  
      if (!/^[A-Z]$/.test(firstLetter) && firstLetter !== "#") {
        firstLetter = "?";
      }
  
      if (!groupedContacts[firstLetter]) {
        groupedContacts[firstLetter] = [];
      }
  
      groupedContacts[firstLetter].push(contact);
    });
  
    return groupedContacts;
  }


  filterByLetter(letter: string) {
    this.selectedLetter = letter;
    this.filteredContacts = this.groupedContacts[letter] || [];
  
    setTimeout(() => {
      if (this.scroller) {
        this.scroller.scrollToIndex(0);
      }
    }, 0);
  }

  onSearchChange(event: Event) {
    const inputValue = (event.target as HTMLInputElement).value;
    this.searchText = inputValue;
    this.searchSubject.next(inputValue);
  }

  clearSearch() {
    this.searchText = '';
    this.searchedContacts = [];
    this.searchSubject.next('');
  }

  saveNewContact() {
    this._contactRepository.create(this.newContact).pipe(take(1)).subscribe((contact: ContactModel) => {
      const firstLetter = contact.lastName?.trim().charAt(0).toUpperCase() || "#";
      const groupKey = /^[A-Z]$/.test(firstLetter) ? firstLetter : "?";

      if (!this.groupedContacts[groupKey]) {
        this.groupedContacts[groupKey] = [];
      }

      this.groupedContacts[groupKey] = [...this.groupedContacts[groupKey], contact].sort((a, b) =>
        (a.lastName?.toLowerCase() || "").localeCompare(b.lastName?.toLowerCase() || "")
      );

      this.newContact = {} as ContactModel;
      this.openCreateContactModal = false;

      this.filterByLetter(firstLetter);

      this.highlightedContactId = contact.id;
      this.cdr.detectChanges();

      const index = this.filteredContacts.findIndex(c => c.id === contact.id);
      if (index !== -1 && this.scroller) {
        this.scroller.scrollToIndex(index);
      }

      setTimeout(() => {
        this.scrollToNewContact(contact.id);
      }, 300);
    });
  }

  scrollToNewContact(contactId: number) {
    setTimeout(() => {
      this.cdr.detectChanges();
  
      const contactElement = document.getElementById(`contact-${contactId}`);
      if (contactElement) {  
        contactElement.scrollIntoView({ behavior: 'smooth', block: 'center' });  
        setTimeout(() => {
          this.highlightedContactId = null;
          this.cdr.detectChanges();
        }, 2000);
      }
    }, 100);
  }

  toggleCreateContact(show: boolean) {
    this.openCreateContactModal = show;
  }

  onContactUpdate($event: any) {
    this.newContact = $event;
  }

  toggleEditContact(show: boolean, contact?: ContactModel) {
    if (contact) {
      this.newContact = contact;
    }
    this.openEditContactModal = show;
  }

  saveUpdateContact() {

    if (this.newContact.birthday) {
      this.newContact.birthday = this._helperService.normalizeDate(this.newContact.birthday);
    }

    this._contactRepository.update(this.newContact, this.newContact.id).pipe(take(1)).subscribe((updatedContact: ContactModel) => {
      const newFirstLetter = updatedContact.lastName?.trim().charAt(0).toUpperCase() || "#";
      const newGroupKey = /^[A-Z]$/.test(newFirstLetter) ? newFirstLetter : "?";
  
      let oldGroupKey: string | null = null;
      for (const key in this.groupedContacts) {
        if (this.groupedContacts[key].some(c => c.id === updatedContact.id)) {
          oldGroupKey = key;
          break;
        }
      }
  
      if (oldGroupKey && oldGroupKey !== newGroupKey) {
        this.groupedContacts[oldGroupKey] = this.groupedContacts[oldGroupKey].filter(c => c.id !== updatedContact.id);
      }
  
      if (!this.groupedContacts[newGroupKey]) {
        this.groupedContacts[newGroupKey] = [];
      }
  
      this.groupedContacts[newGroupKey] = [
        ...this.groupedContacts[newGroupKey].filter(c => c.id !== updatedContact.id),
        updatedContact
      ].sort((a, b) => (a.lastName?.toLowerCase() || "").localeCompare(b.lastName?.toLowerCase() || ""));
  
      this.newContact = {} as ContactModel;
      this.openEditContactModal = false;
      this.filterByLetter(newFirstLetter);
      this.cdr.detectChanges();
    });
  }

}
