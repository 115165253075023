import {Route} from '@angular/router';
import {AuthGuard} from "../../shared/auth/auth.guard";
import {AnimalOverviewComponent} from "./animal-overview.component";
import {AnimalDetailsComponent} from "./animal-details/animal-details.component";
import { canDeactivateGuard } from 'src/app/shared/guard/can-deactivate.guard';

export const AnimalRoutes: Route[] = [
  {
    path: 'tiere',
    component: AnimalOverviewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'tiere/:id',
    component: AnimalDetailsComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    canDeactivate: [canDeactivateGuard]
  }
];
