<p-dialog [(visible)]="visible" [style]="{width: '90vw', height: '90vh'}" [modal]="true" [draggable]="false"
          [resizable]="false" (onHide)="closeModal()">
  <ng-template pTemplate="closeicon">
    <span class="closeicon pi pi-times"></span>
  </ng-template>
  <ng-template pTemplate="header">
    <div class="w-full flex justify-content-center">
      <p-steps [model]="items" [readonly]="true" [activeIndex]="activeTabIndex"></p-steps>
    </div>
  </ng-template>

  <ng-template pTemplate="content">
    <form id="addForm" class="h-full" [formGroup]="formGroup" novalidate>
      <div class="card m-1 p-5 h-full" *ngIf="getTabContent() === 'search'">
        <div class="table-container">
          <div class="flex flex-row w-full justify-content-center mb-2">
              <input type="text" class="w-full" pInputText [(ngModel)]="searchTerm"
                [ngModelOptions]="{standalone: true}" placeholder="Suche nach Tier"
                spellcheck="false" autocomplete="off"
                (input)="onSearch()"
              />
          </div>
          <div class="flex-grow-scroll">
              <p-table *ngIf="animalSearchResult.length > 0" [value]="animalSearchResult" class="p-mt-4">
                  <ng-template pTemplate="header">
                      <tr>
                          <th></th>
                          <th>{{ 'animalAdd.general.basicData.name' | transloco }}</th>
                          <th>{{ 'animalAdd.general.basicData.type' | transloco }}</th>
                          <th>{{ 'animalAdd.general.basicData.oldBookNumber' | transloco }}</th>
                          <th></th>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-animal>
                      <tr>
                          <td class="relative">
                            <app-animal-image-container [animal]="animal" [preview]="true"></app-animal-image-container>
                          </td>
                          <td>{{ animal.name }}</td>
                          <td>{{ animal.type }}</td>
                          <td>{{ animal.bookNumber }}</td>
                          <td class="flex flex-row-reverse">
                            <span [pTooltip]="animal.inStock ? ('animalAdd.general.basicData.stillInStock' | transloco) : ''"
                              tooltipPosition="top"
                            >
                              <p-button (click)="resumeAnimalStep(animal)" [disabled]="animal.inStock">
                                {{ 'animalAdd.general.basicData.resume' | transloco }}
                              </p-button>
                            </span>
                          </td>
                      </tr>
                  </ng-template>
              </p-table>
          </div>
          <div class="flex flex-row justify-content-center">
            <p-button (click)="addNewAnimalStep()">
              {{ 'animalAdd.general.basicData.addNewAnimal' | transloco }}
            </p-button>
          </div>
        </div>
      </div>
      <div class="card m-1 p-5 h-full" *ngIf="getTabContent() === 'category'">
        <div class="flex h-full align-items-center justify-content-center">
          <div class="flex flex-column align-items-center justify-content-center flex-wrap card-container">
            <h1 class="m-0 text-primary text-4xl font-semibold">{{ 'animalAdd.general.basicData.addNewAnimal' | transloco }}</h1>
            <h2 class="m-0 text-color-secondary text-2xl font-semibold">{{ 'animalAdd.general.basicData.chooseCategory' | transloco }}</h2>
            <div class="flex flex-wrap mt-5">
              <ng-container *ngFor="let filter of categoryFilters">
                <div class="flex justify-content-center align-items-stretch ml-1 animal-cat-button">
                  <p-button [ngClass]="{'selected': isSelectedCategory(filter)}"
                      (click)="selectCategory(filter)"
                      [text]="true">
                    <div class="flex flex-column">
                      <div class="flex align-items-center justify-content-center"
                           style="min-height:65px">
                        <p-image class="block mx-1" src="{{ filter.imgSrc }}" width="37"
                                 [imageStyle]="{'max-height': '45px'}"></p-image>
                      </div>
                      <div class="flex-1">
                        <p class="m-0 block font-semibold text-color-secondary font-semibold">{{ filter.title }}</p>
                      </div>
                    </div>
                  </p-button>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="card m-1" *ngIf="getTabContent() === 'general'">
        <div class="flex flex-row details-view">
          <p-accordion>
            <p-accordionTab>
              <ng-template pTemplate="header">
                <span class="p-accordion-custom-header-text align-items-center">{{ 'animalAdd.general.basicData.headline' | transloco }}</span>
                <span class="pi pi-exclamation-triangle"
                  [pTooltip]="'animalAdd.general.invalidTooltip' | transloco"
                  tooltipPosition="right"
                  *ngIf="formGroup.get('type')?.invalid ||
                  formGroup.get('category')?.invalid ||
                  formGroup.get('sex')?.invalid"
                ></span>
              </ng-template>
              <div class="grid">
                <div class="col-6">
                  <label class="py-1" for="name">{{ 'animalAdd.general.basicData.name' | transloco }}</label>
                  <input type="text" pInputText class="p-inputtext-sm w-full" id="name" formControlName="name"/>
                </div>
                <div class="col-6">
                  <label class="py-1" for="nameOld">{{ 'animalAdd.general.basicData.nameOld' | transloco }}</label>
                  <input type="text" pInputText class="p-inputtext-sm w-full" id="nameOld" formControlName="nameOld"/>
                </div>
                <div class="col-6">
                  <label class="py-1"
                          for="animalCategory">{{ 'animalAdd.general.basicData.category' | transloco }}
                    <span class="required">*</span></label>
                  <p-dropdown class="p-inputdropdown-sm w-full" id="animalCategory"
                              [options]="animalCategoryOptions"
                              optionLabel="name" optionValue="value"
                              formControlName="category"
                              (onChange)="onCategoryChange($event)"
                              appendTo="body"></p-dropdown>
                </div>
                <div class="col-6">
                  <label class="py-1"
                          for="animalType">{{ 'animalAdd.general.basicData.type' | transloco }}
                    <span class="required">*</span></label>
                  <p-dropdown class="p-inputdropdown-sm w-full" id="animalType"
                              [ngClass]="{'p-disabled': animalTypeOptions.length === 1}"
                              [options]="animalTypeOptions"
                              optionLabel="name" optionValue="value"
                              formControlName="type"
                              appendTo="body"></p-dropdown>
                </div>
                <div class="col-6">
                  <label class="py-1" for="gender">
                    {{ 'animalAdd.general.basicData.sex' | transloco }}
                    <span class="required">*</span>
                  </label>
                  <div class="flex flex-wrap flex-row">
                    <div *ngFor="let genderOption of genderOptions" class="field-checkbox m-2">
                      <p-radioButton [inputId]="genderOption.key" [value]="genderOption.key"
                                      formControlName="sex" id="gender"
                                      [required]=true></p-radioButton>
                      <label [for]="genderOption.key"
                              class="ml-2">{{ genderOption.name }}</label>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <label class="py-1" for="oldBookNumber">{{ 'animalAdd.general.basicData.oldBookNumber' | transloco }}</label>
                  <input type="text" pInputText class="p-inputtext-sm w-full" id="oldBookNumber" formControlName="oldBookNumber"/>
                </div>
              </div>
            </p-accordionTab>
            <p-accordionTab>
              <ng-template pTemplate="header">
                <span class="p-accordion-custom-header-text align-items-center">{{ 'animalAdd.general.specifications.headline' | transloco }}</span>
                <span class="pi pi-exclamation-triangle"
                  [pTooltip]="'animalAdd.general.invalidTooltip' | transloco"
                  tooltipPosition="right"
                  *ngIf="formGroup.get('breed')?.invalid"
                ></span>
              </ng-template>
              <div class="grid">
                <div class="col-12">
                  <label class="py-1" for="birthDate">{{ 'animalAdd.general.specifications.birthday' | transloco }}</label>
                  <div class="flex">
                    <div class="flex align-items-center p-2">
                      {{ 'animalAdd.general.specifications.approx' | transloco }}
                      <p-inputSwitch formControlName="birthApprox"></p-inputSwitch>
                    </div>
                    <div class="flex align-items-center p-2">
                      {{ 'animalAdd.general.specifications.date' | transloco }}
                    </div>
                    <div class="flex align-items-center p-2">
                      <p-inputSwitch formControlName="birthdayType"></p-inputSwitch>
                    </div>
                    <div class="flex align-items-center p-2">
                      {{ 'animalAdd.general.specifications.year' | transloco }}
                    </div>
                    <div class="flex align-items-center">
                      <p-calendar class="w-full p-inputcalendar-sm" id="birthYear"
                                  formControlName="birthYear"
                                  view="year"
                                  dateFormat="yy" [showIcon]="true"
                                  appCalendarMaxDate
                                  *ngIf="formGroup.get('birthdayType')?.value === true"></p-calendar>
                      <p-calendar class="w-full p-inputcalendar-sm" id="birthDate"
                                  formControlName="birthday"
                                  dateFormat="dd.mm.yy" [showIcon]="true"
                                  appCalendarMaxDate
                                  *ngIf="formGroup.get('birthdayType')?.value === false"></p-calendar>
                    </div>
                  </div>
                  <div class="flex">
                    <div class="flex-1 flex-column p-2">
                      {{ 'animalEdit.general.specifications.birthPlace' | transloco }}
                      <input
                        type="text"
                        pInputText
                        class="p-inputtext-sm w-full"
                        id="birthPlace"
                        formControlName="birthPlace"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <label class="py-1" for="breed">
                    {{ 'animalAdd.general.specifications.breed' | transloco }}
                    <span class="required">*</span>
                  </label>
                  <input class="py-1" type="text" pInputText class="p-inputtext-sm w-full" id="breed"
                    formControlName="breed" required/>
                </div>
              </div>
            </p-accordionTab>
            <p-accordionTab>
              <ng-template pTemplate="header">
                <span class="p-accordion-custom-header-text align-items-center">{{ 'animalAdd.general.numbers.headline' | transloco }}</span>
              </ng-template>
              <div class="grid">
                <div class="col-6">
                  <label class="py-1" for="transponderNumber">
                    {{ 'animalAdd.general.numbers.transponderNumber' | transloco }}
                  </label>
                  <div class="p-inputgroup">
                    <input type="text" pInputText id="transponderNumber" formControlName="transponderNumber"
                      class="p-inputtext-sm w-full" />
                    <app-barcode-scanner [bindTo]="'transponderNumber'" (barcodeScanned)="handleBarcodeScanned($event)">
                    </app-barcode-scanner>
                  </div>
                </div>
                <div class="col-6">
                  <label class="py-1" for="lifeNumber">
                    {{ 'animalAdd.general.numbers.lifeNumber' | transloco }}
                  </label>
                  <div class="p-inputgroup">
                    <input type="text" pInputText id="lifeNumber" formControlName="lifeNumber" class="p-inputtext-sm w-full" />
                    <app-barcode-scanner [bindTo]="'lifeNumber'" (barcodeScanned)="handleBarcodeScanned($event)">
                    </app-barcode-scanner>
                  </div>
                </div>
                <div class="col-6">
                  <label class="py-1" for="petPassportNumber">
                    {{ 'animalAdd.general.numbers.petPassportNumber' | transloco }}
                  </label>
                  <div class="p-inputgroup">
                    <input type="text" pInputText id="petPassportNumber" formControlName="petPassportNumber"
                      class="p-inputtext-sm w-full" />
                    <app-barcode-scanner [bindTo]="'petPassportNumber'" (barcodeScanned)="handleBarcodeScanned($event)">
                    </app-barcode-scanner>
                  </div>
                </div>
                <div class="col-6">
                  <label class="py-1" for="equinePassportNumber">
                    {{ 'animalAdd.general.numbers.equinePassportNumber' | transloco }}
                  </label>
                  <div class="p-inputgroup">
                    <input type="text" pInputText id="equinePassportNumber" formControlName="equinePassportNumber"
                      class="p-inputtext-sm w-full" />
                    <app-barcode-scanner [bindTo]="'equinePassportNumber'" (barcodeScanned)="handleBarcodeScanned($event)">
                    </app-barcode-scanner>
                  </div>
                </div>
                <div class="col-6">
                  <label class="py-1" for="ringNumber">
                    {{ 'animalAdd.general.numbers.ringNumber' | transloco }}
                  </label>
                  <div class="p-inputgroup">
                    <input type="text" pInputText id="ringNumber" formControlName="ringNumber" class="p-inputtext-sm w-full" />
                    <app-barcode-scanner [bindTo]="'ringNumber'" (barcodeScanned)="handleBarcodeScanned($event)">
                    </app-barcode-scanner>
                  </div>
                </div>
                <div class="col-6">
                  <label class="py-1" for="earTag">
                    {{ 'animalAdd.general.numbers.earTagNumber' | transloco }}
                  </label>
                  <div class="p-inputgroup">
                    <input type="text" pInputText id="earTag" formControlName="earTag" class="p-inputtext-sm w-full" />
                    <app-barcode-scanner [bindTo]="'earTag'" (barcodeScanned)="handleBarcodeScanned($event)">
                    </app-barcode-scanner>
                  </div>
                </div>
                <div class="col-6">
                  <label class="py-1" for="tattoo">
                    {{ 'animalAdd.general.numbers.tattoo' | transloco }}
                  </label>
                  <div class="p-inputgroup">
                    <input type="text" pInputText id="tattoo" formControlName="tattoo" class="p-inputtext-sm w-full" />
                    <app-barcode-scanner [bindTo]="'tattoo'" (barcodeScanned)="handleBarcodeScanned($event)">
                    </app-barcode-scanner>
                  </div>
                </div>
                <div class="col-6">
                  <label class="py-1" for="tattooLocation">
                    {{ 'animalAdd.general.numbers.tattooLocation' | transloco }}
                  </label>
                  <input type="text" pInputText id="tattooLocation" formControlName="tattooLocation" class="p-inputtext-sm w-full" />
                </div>
              </div>
            </p-accordionTab>
          </p-accordion>
        </div>
      </div>
      <div class="card h-full m-1" *ngIf="getTabContent() === 'reasonForDelivery'">
        <div class="flex w-full h-full flex-column align-items-center justify-content-center">
          <p>{{ 'animalAdd.origin.reasonForTransfer' | transloco }}</p>
          <button pButton
            class="p-button w-20rem h-5rem text-center my-3 flex align-items-center justify-content-center"
            [ngClass]="{'p-button-outlined': reasonGivenSet === reasonsForTransfer.DELIVERY}"
            (click)="reasonGiven(reasonsForTransfer.DELIVERY)"
          >
            {{ 'animalAdd.origin.reasonForTransferSelect.DELIVERY' | transloco }}
          </button>
          <button pButton
            class="p-button w-20rem h-5rem text-center my-3 flex align-items-center justify-content-center"
            [ngClass]="{'p-button-outlined': reasonGivenSet === reasonsForTransfer.FOUND_ANIMAL}"
            (click)="reasonGiven(reasonsForTransfer.FOUND_ANIMAL)"
          >
            {{ 'animalAdd.origin.reasonForTransferSelect.FOUND_ANIMAL' | transloco }}
          </button>
          <button pButton
            class="p-button w-20rem h-5rem text-center my-3 flex align-items-center justify-content-center"
            [ngClass]="{'p-button-outlined': reasonGivenSet === reasonsForTransfer.TAKEOVER_FROM_ANOTHER_SHELTER}"
            (click)="reasonGiven(reasonsForTransfer.TAKEOVER_FROM_ANOTHER_SHELTER)"
          >
            {{ 'animalAdd.origin.reasonForTransferSelect.TAKEOVER_FROM_ANOTHER_SHELTER' | transloco }}
          </button>
          <button pButton
            class="p-button w-20rem h-5rem text-center my-3 flex align-items-center justify-content-center"
            [ngClass]="{'p-button-outlined': reasonGivenSet === reasonsForTransfer.TAKE_AWAY}"
            (click)="reasonGiven(reasonsForTransfer.TAKE_AWAY)"
          >
            {{ 'animalAdd.origin.reasonForTransferSelect.TAKE_AWAY' | transloco }}
          </button>
          <button pButton
            *ngIf="!resumeAnimalSteps"
            class="p-button w-20rem h-5rem text-center my-3 flex align-items-center justify-content-center"
            [ngClass]="{'p-button-outlined': reasonGivenSet === reasonsForTransfer.BORN_IN_SHELTER}"
            (click)="reasonGiven(reasonsForTransfer.BORN_IN_SHELTER)"
          >
            {{ 'animalAdd.origin.reasonForTransferSelect.BORN_IN_SHELTER' | transloco }}
          </button>
          <button pButton
                  class="p-button w-20rem h-5rem text-center my-3 flex align-items-center justify-content-center"
                  [ngClass]="{'p-button-outlined': reasonGivenSet === reasonsForTransfer.RESUMPTION_AFTER_TRIAL}"
                  (click)="reasonGiven(reasonsForTransfer.RESUMPTION_AFTER_TRIAL)"
          >
            {{ 'animalAdd.origin.reasonForTransferSelect.RESUMPTION_AFTER_TRIAL' | transloco }}
          </button>
        </div>
      </div>
      <div class="card m-1" *ngIf="getTabContent() === 'origin'">
        <div class="flex flex-row details-view">
          <div class="flex-1">
            <p-accordion>
              <p-accordionTab>
                <ng-template pTemplate="header">
                  <span class="p-accordion-custom-header-text align-items-center">{{ 'animalAdd.general.basicData.headline' | transloco }}</span>
                  <span class="pi pi-exclamation-triangle"
                    [pTooltip]="'animalAdd.general.invalidTooltip' | transloco"
                    tooltipPosition="right"
                    *ngIf="
                      formGroup.get('originDateOfReceipt')?.invalid
                    "
                  ></span>
                </ng-template>
                <div class="grid">
                  <div class="col-6">
                    <label class="py-1">
                      {{ 'animalAdd.origin.dateOfReceipt' | transloco }}
                      <span class="required">*</span>
                    </label>
                    <p-calendar class="w-full p-inputcalendar-sm" id="originDateOfReceipt"
                      formControlName="originDateOfReceipt"
                      dateFormat="dd.mm.yy" [showIcon]="true"
                      appCalendarMaxDate
                      appendTo="body">
                    </p-calendar>
                  </div>
                  <div class="col-3">
                    <label class="py-1">{{ 'animalAdd.origin.conditionOfCare.headline' | transloco }}</label>
                    <div class="flex flex-wrap gap-3 p-2">
                      <div class="flex align-items-center">
                        <p-radioButton inputId="conditionBad" value="bad"
                                        formControlName="originConditionOfCare"></p-radioButton>
                        <label for="conditionBad"
                                class="ml-2">{{ 'animalAdd.origin.conditionOfCare.bad' | transloco }}</label>
                      </div>
                      <div class="flex align-items-center">
                        <p-radioButton inputId="conditionMedium" value="medium"
                                        formControlName="originConditionOfCare"></p-radioButton>
                        <label for="conditionMedium"
                                class="ml-2">{{ 'animalAdd.origin.conditionOfCare.medium' | transloco }}</label>
                      </div>
                      <div class="flex align-items-center">
                        <p-radioButton inputId="conditionGood" value="good"
                                        formControlName="originConditionOfCare"></p-radioButton>
                        <label for="conditionGood"
                                class="ml-2">{{ 'animalAdd.origin.conditionOfCare.good' | transloco }}</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-3">
                    <label class="py-1">{{ 'animalAdd.origin.nutritionalStatus.headline' | transloco }}</label>
                    <div class="flex flex-wrap gap-3 p-2">
                      <div class="flex align-items-center">
                        <p-radioButton inputId="nutritionBad" value="bad"
                                        formControlName="originNutritionalStatus"></p-radioButton>
                        <label for="nutritionBad"
                                class="ml-2">{{ 'animalAdd.origin.nutritionalStatus.bad' | transloco }}</label>
                      </div>
                      <div class="flex align-items-center">
                        <p-radioButton inputId="nutritionMedium" value="medium"
                                        formControlName="originNutritionalStatus"></p-radioButton>
                        <label for="nutritionMedium"
                                class="ml-2">{{ 'animalAdd.origin.nutritionalStatus.medium' | transloco }}</label>
                      </div>
                      <div class="flex align-items-center">
                        <p-radioButton inputId="nutritionGood" value="good"
                                        formControlName="originNutritionalStatus"></p-radioButton>
                        <label for="nutritionGood"
                                class="ml-2">{{ 'animalAdd.origin.nutritionalStatus.good' | transloco }}</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="py-1" for="originSpecialInformation">
                      {{ 'animalAdd.origin.specialInformation' | transloco }}
                    </label>
                    <textarea
                      area
                      pInputText
                      class="p-inputtext-sm w-full"
                      id="originSpecialInformation"
                      formControlName="originSpecialInformation"
                      rows="10"
                    ></textarea>
                  </div>
                  <div class="col-6">
                    <label class="py-1"
                            for="originOtherComments">{{ 'animalAdd.origin.otherComments' | transloco }}</label>
                    <textarea pInputText class="p-inputtext-sm w-full" area id="originOtherComments"
                              formControlName="originOtherComments" rows="10"></textarea>
                  </div>
                </div>
              </p-accordionTab>
              <p-accordionTab *ngIf="reasonGivenSet === reasonsForTransfer.DELIVERY || reasonGivenSet === reasonsForTransfer.RESUMPTION_AFTER_TRIAL">
                <ng-template pTemplate="header">
                  <span class="p-accordion-custom-header-text align-items-center">
                    <ng-container *ngIf="reasonGivenSet === reasonsForTransfer.DELIVERY">
                      {{ 'animalAdd.general.delivery.headline' | transloco }}
                    </ng-container>
                    <ng-container *ngIf="reasonGivenSet === reasonsForTransfer.RESUMPTION_AFTER_TRIAL">
                      {{ 'animalAdd.general.resumption.headline' | transloco }}
                    </ng-container>
                  </span>
                  <span class="pi pi-exclamation-triangle"
                    [pTooltip]="'animalAdd.general.invalidTooltip' | transloco"
                    tooltipPosition="right"
                    *ngIf="
                      !validationStatusChangedGiver ||
                      !validationStatusChangedOwner
                    "
                  ></span>
                </ng-template>
                <p-tabView>
                  <p-tabPanel [header]="'animalAdd.general.delivery.giver' | transloco">
                    <div class="details-view">
                      <app-choose-contact
                        [newContact]="contactGiver"
                        [isExistingContact]="existingGiverContact"
                        (newContactUpdated)="onContactUpdateGiver($event)"
                        (validationStatusChanged)="onValidationStatusChangedGiver($event)"
                        (existingContactSelected)="onExistingContactSelectedGiver($event)"
                        (existingContactUpdated)="onExistingContactUpdatedGiver($event)"
                      >
                      </app-choose-contact>
                    </div>
                  </p-tabPanel>
                  <p-tabPanel [header]="'animalAdd.general.delivery.owner' | transloco">
                    <div class="details-view">
                      <app-choose-contact
                        [newContact]="contactOwner"
                        [isExistingContact]="existingOwnerContact"
                        (newContactUpdated)="onContactUpdateOwner($event)"
                        (validationStatusChanged)="onValidationStatusChangedOwner($event)"
                        (existingContactSelected)="onExistingContactSelectedOwner($event)"
                        (existingContactUpdated)="onExistingContactUpdatedOwner($event)"
                      >
                      </app-choose-contact>
                    </div>
                  </p-tabPanel>
                </p-tabView>
              </p-accordionTab>
              <p-accordionTab *ngIf="reasonGivenSet === reasonsForTransfer.TAKEOVER_FROM_ANOTHER_SHELTER">
                <ng-template pTemplate="header">
                  <span class="p-accordion-custom-header-text align-items-center">{{ 'animalAdd.general.anotherShelter.headline' | transloco }}</span>
                  <span class="pi pi-exclamation-triangle"
                    [pTooltip]="'animalAdd.general.invalidTooltip' | transloco"
                    tooltipPosition="right"
                    *ngIf="
                      !validationStatusChangedGiver
                    "
                  ></span>
                </ng-template>
                <div class="grid details-view">
                  <app-choose-contact
                    [newContact]="contactGiver"
                    [isExistingContact]="existingGiverContact"
                    (newContactUpdated)="onContactUpdateGiver($event)"
                    (validationStatusChanged)="onValidationStatusChangedGiver($event)"
                    (existingContactSelected)="onExistingContactSelectedGiver($event)"
                    (existingContactUpdated)="onExistingContactUpdatedGiver($event)"
                  >
                  </app-choose-contact>
                </div>
              </p-accordionTab>
              <p-accordionTab *ngIf="reasonGivenSet === reasonsForTransfer.TAKE_AWAY || reasonGivenSet === reasonsForTransfer.FOUND_ANIMAL">
                <ng-template pTemplate="header">
                  <span class="p-accordion-custom-header-text align-items-center">
                    <ng-container *ngIf="reasonGivenSet === reasonsForTransfer.TAKE_AWAY">{{ 'animalAdd.general.takeAway.headline' | transloco }}</ng-container>
                    <ng-container *ngIf="reasonGivenSet === reasonsForTransfer.FOUND_ANIMAL">{{ 'animalAdd.general.foundAnimal.headline' | transloco }}</ng-container>
                  </span>
                  <span class="pi pi-exclamation-triangle"
                    [pTooltip]="'animalAdd.general.invalidTooltip' | transloco"
                    tooltipPosition="right"
                    *ngIf="
                      formGroup.get('originCompetentAuthority')?.invalid ||
                      !validationStatusChangedOwner
                    "
                  ></span>
                </ng-template>
                <div class="grid details-view">
                  <div class="col-6">
                    <label class="py-1">
                      {{ 'animalAdd.origin.location' | transloco }}
                    </label>
                    <input type="text" pInputText class="p-inputtext-sm w-full" id="location" formControlName="originLocation"/>
                  </div>
                  <div class="col-6">
                    <label class="py-1" for="originCompetentAuthority">
                      {{ 'animalAdd.origin.competentAuthority' | transloco }}
                      <span class="required">*</span>
                    </label>
                    <p-dropdown
                        class="p-inputdropdown-sm w-full" id="originCompetentAuthority"
                        [options]="originCompetentAuthorityOptions"
                        optionLabel="name" optionValue="id"
                        [autoDisplayFirst]="false"
                        formControlName="originCompetentAuthority" [required]="true"
                        appendTo="body"
                      >
                    </p-dropdown>
                  </div>
                  <div class="col-12">
                    <label class="py-1">
                      {{ 'animalAdd.general.delivery.owner' | transloco }}
                    </label>
                    <app-choose-contact
                      [newContact]="contactOwner"
                      [isExistingContact]="existingOwnerContact"
                      (newContactUpdated)="onContactUpdateOwner($event)"
                      (validationStatusChanged)="onValidationStatusChangedOwner($event)"
                      (existingContactSelected)="onExistingContactSelectedOwner($event)"
                      (existingContactUpdated)="onExistingContactUpdatedOwner($event)"
                    >
                    </app-choose-contact>
                  </div>
                </div>
              </p-accordionTab>
              <p-accordionTab *ngIf="reasonGivenSet === reasonsForTransfer.BORN_IN_SHELTER">
                <ng-template pTemplate="header">
                  <span class="p-accordion-custom-header-text align-items-center">{{ 'animalAdd.general.bornInShelter.headline' | transloco }}</span>
                  <span class="pi pi-exclamation-triangle"
                    [pTooltip]="'animalAdd.general.invalidTooltip' | transloco"
                    tooltipPosition="right"
                    *ngIf="
                      formGroup.get('motherId')?.invalid
                    "
                  ></span>
                </ng-template>
                <div class="grid details-view">
                  <div class="col-12 table-container">
                    <div class="flex flex-row w-full justify-content-center mb-2">
                      <input type="text" class="w-full" pInputText [(ngModel)]="searchTerm" [ngModelOptions]="{standalone: true}" placeholder="Suche nach Mutter">
                      <button class="ml-1" pButton type="button" label="Suchen" (click)="onSearch()"></button>
                    </div>
                    <div class="flex-grow-scroll">
                      <p-table *ngIf="animalSearchResult.length > 0" [value]="animalSearchResult" class="p-mt-4">
                        <ng-template pTemplate="header">
                          <tr>
                            <th></th>
                            <th>{{ 'animalAdd.general.basicData.name' | transloco }}</th>
                            <th>{{ 'animalAdd.general.basicData.type' | transloco }}</th>
                            <th>{{ 'animalAdd.general.basicData.bookNumber' | transloco }}</th>
                            <th></th>
                          </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-animal>
                          <tr>
                            <td class="relative">
                              <app-animal-image-container [animal]="animal" [preview]="true"></app-animal-image-container>
                            </td>
                            <td>{{ animal.name }}</td>
                            <td>{{ animal.type }}</td>
                            <td>{{ animal.bookNumber }}</td>
                            <td class="flex flex-row-reverse">
                              <p-button (click)="addMotherAnimal(animal)">
                                {{ 'animalAdd.general.basicData.addMother' | transloco }}
                              </p-button>
                            </td>
                          </tr>
                        </ng-template>
                      </p-table>
                    </div>
                  </div>
                </div>
              </p-accordionTab>
            </p-accordion>
          </div>
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="flex">
      <div class="flex-1">
        <button class="p-button" (click)="lastStep()"
            [disabled]="(!resumeAnimalSteps && activeTabIndex === 0)"
            data-cy="last-step-btn">{{ 'animalAdd.lastStep' | transloco }}
        </button>
        <button *ngIf="activeTabIndex !== activeTabIndexLength" class="p-button" (click)="nextStep()"
            [disabled]="isNextStepDisabled()"
            data-cy="next-step-btn">{{ 'animalAdd.nextStep' | transloco }}
        </button>
        <button *ngIf="activeTabIndex === activeTabIndexLength" class="p-button" form="addForm" type="button" (click)="saveChanges()"
            [disabled]="!getInvalidFormControls()"
            data-cy="submit-btn">{{ 'modal.save' | transloco }}
        </button>
      </div>
    </div>
    <div class="flex-end">
      <span class="required">* {{ 'animalAdd.general.required' | transloco }}</span>
    </div>
  </ng-template>
</p-dialog>
