import { Component, Input } from '@angular/core';
import { AnimalModel } from 'src/app/modules/animals/models/animal.model';

@Component({
  selector: 'app-animal-in-stock-icon',
  template: `
    <span *ngIf="!animal.inStock" 
          class="pi pi-info-circle ml-1 not-in-stock" 
          [pTooltip]="'animalAdd.general.basicData.notInStock' | transloco" 
          tooltipPosition="right"
          style="color: #7e9c30;"
    >
    </span>
  `
})
export class AnimalInStockIconComponent {
  @Input() animal!: AnimalModel;
}
