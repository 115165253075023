import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {AnimalRepository} from "../states/animal.repository";
import {ActivatedRoute} from "@angular/router";
import {Observable, take, takeWhile} from "rxjs";
import {AnimalEditService} from "../services/animal-edit.service";
import {AnimalModel} from "../models/animal.model";
import {GalleriaResponsiveOptions} from 'primeng/galleria';
import {getAnimalHintKey} from "../models/animal-hint.enum";
import {getCharacteristicsEnumKey} from "../models/animal-characteristics.enum";
import {AnimalDetailsTimelineComponent} from '../animal-details-timeline/animal-details-timeline.component';
import {InputSwitchOnChangeEvent} from "primeng/inputswitch";
import {ConfirmationService, MenuItem} from "primeng/api";
import {AnimalSheltersRepository} from '../states/animal-shelter.repository';
import {CanComponentDeactivate} from 'src/app/shared/guard/can-deactivate.guard';
import {AnimalDetailsWhereaboutsComponent} from '../animal-details-whereabouts/animal-details-whereabouts.component';
import {TranslocoService} from '@jsverse/transloco';

@Component({
  selector: 'app-animal-details',
  templateUrl: './animal-details.component.html',
  styleUrls: ['./animal-details.component.scss']
})
export class AnimalDetailsComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  @ViewChild('animalDetailsTimelineComponent') animalDetailsTimelineComponent!: AnimalDetailsTimelineComponent;
  @ViewChild(AnimalDetailsWhereaboutsComponent) whereaboutsComponent!: AnimalDetailsWhereaboutsComponent;
  alive: boolean;
  animal: AnimalModel | undefined;
  responsiveOptions: GalleriaResponsiveOptions[] = [];
  editTabsDialogVisible: { [tab: string]: boolean | string } = {};
  showEditDialog: boolean = false;
  showMoveDialog: boolean = false;
  filteredCoatTypes: string[] | undefined = [];
  animalEditMenuItems: MenuItem[] | undefined;
  motherAnimal: AnimalModel | undefined;
  previousBookNumbersWithShelter: { bookNumber: string; shelterName: string | null }[] = [];
  selectedTabIndex: number = 0;
  objectKeys = Object.keys;
  fromOtherShelter: boolean = false;

  constructor(
    private _animalRepository: AnimalRepository,
    private _route: ActivatedRoute,
    private _animalEditService: AnimalEditService,
    private _animalSheltersRepository: AnimalSheltersRepository,
    private _confirmationService: ConfirmationService,
    private _translocoService: TranslocoService
  ) {
    this.alive = true;
  }

  ngOnInit(): void {
    this._animalEditService.editDialogVisible.pipe(takeWhile(() => this.alive)).subscribe((openModal) => {
      this.editTabsDialogVisible = openModal;
      this.showEditDialog = openModal && Object.keys(openModal).length ? true : false;
    });

    this._animalEditService.moveDialogVisible.pipe(takeWhile(() => this.alive)).subscribe((openModal) => {
      this.showMoveDialog = openModal;
    });

    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 5
      },
      {
        breakpoint: '768px',
        numVisible: 3
      },
      {
        breakpoint: '560px',
        numVisible: 1
      }
    ];

    const idStr = this._route.snapshot.paramMap.get('id');
    if (idStr !== undefined && idStr !== null) {
      const id = parseInt(idStr);
      this._animalRepository.getById(id).pipe(takeWhile(() => this.alive)).subscribe((animal) => {
        this.animal = animal;
        this.fromOtherShelter = animal?.animalShelter.id !== this._animalSheltersRepository.getActiveAnimalShelter()?.id;
        this.filteredCoatTypes = animal?.coatType?.filter(x => x !== null && x !== '');
        if (animal?.motherId) {
          this._animalRepository.getById(animal.motherId).pipe(take(1)).subscribe((motherAnimal) => {
            this.motherAnimal = motherAnimal;
          });
        }
        if (animal?.previousBookNumbers && animal?.previousBookNumbers.length > 0) {
          this.previousBookNumbersWithShelter = [];
          this._animalSheltersRepository.getAnimalShelters().pipe(take(1)).subscribe((animalShelters) => {
            animal?.previousBookNumbers.forEach((entry: any) => {
              const shelter = animalShelters.find((shelter) => shelter.id === entry.animalShelterId);
              this.previousBookNumbersWithShelter.push({
                bookNumber: entry.bookNumber,
                shelterName: shelter ? shelter.title : null
              });
            });
          });
        }
      });
    }

    this.animalEditMenuItems = [
      {
        items: [
          {
            label: 'Verschieben',
            icon: 'pi pi-upload',
            command: () => this.toggleMoveDialog()
          }
        ]
      }
    ];
  }

  toggleEditDialog() {
    this._animalEditService.toggleEditDialog({all: true});
  }

  onTabOpenChange(event: any) {
    if (event.index === 3) {
      this.animalDetailsTimelineComponent.loadData();
    }

    if (this.selectedTabIndex !== 1) {
      if (this.whereaboutsComponent?.hasUnsavedData) {
        this.showConfirmDialog(undefined, event.index);
        return;
      }
    }


    this.selectedTabIndex = event.index;
  }

  ngOnDestroy(): void {
    this.alive = false;
    this.showEditDialog = false;
  }

  protected readonly getAnimalHintKey = getAnimalHintKey;
  protected readonly getCharacteristicsEnumKey = getCharacteristicsEnumKey;

  private toggleMoveDialog() {
    this._animalEditService.toggleMoveDialog();
  }

  onSyncWithWebsiteChange(id: number, $event: InputSwitchOnChangeEvent) {
    this._animalRepository.setSyncWithWebsite(id, $event.checked);
  }

  onSanctuaryChange(id: number, $event: InputSwitchOnChangeEvent) {
    this._animalRepository.setSanctuary(id, $event.checked);
  }

  onFosterHomeWantedChange(id: number, $event: InputSwitchOnChangeEvent) {
    this._animalRepository.setFosterHomeWanted(id, $event.checked);
  }

  onSponsoredChange(id: number, $event: InputSwitchOnChangeEvent) {
    this._animalRepository.setSponsored(id, $event.checked);
  }

  onOutdoorChange(id: number, $event: InputSwitchOnChangeEvent) {
    this._animalRepository.setOutdoor(id, $event.checked);
  }

  canDeactivate(): Promise<boolean> {
    if (this.whereaboutsComponent?.hasUnsavedData) {
      return new Promise<boolean>((resolve) => {
        this.showConfirmDialog(resolve);
      });
    }

    return Promise.resolve(true);
  }

  showConfirmDialog(resolve?: (value: boolean) => void, nextTabIndex?: number) {
    this._confirmationService.confirm({
      message: this._translocoService.translate('confirmDialog.message.unsavedData'),
      header: this._translocoService.translate('confirmDialog.header.confirmation'),
      icon: 'pi pi-exclamation-triangle',
      acceptButtonStyleClass: "p-button-danger p-button-text",
      rejectButtonStyleClass: "p-button-text p-button-text",
      acceptLabel: this._translocoService.translate('confirmDialog.acceptLabel.save'),
      rejectLabel: this._translocoService.translate('confirmDialog.acceptLabel.reject'),
      accept: () => {
        this.whereaboutsComponent.saveNewWhereabout();
        if (resolve) resolve(true);
        if (nextTabIndex !== undefined) this.selectedTabIndex = nextTabIndex;
      },
      reject: () => {
        this.whereaboutsComponent.cancelNewWhereabout();
        if (resolve) resolve(true);
        if (nextTabIndex !== undefined) this.selectedTabIndex = nextTabIndex;
      }
    });
  }

}
