import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { TokenService } from '../services/token.service';
import { AnimalRepository } from 'src/app/modules/animals/states/animal.repository';
import { filter, take, switchMap, first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(
    private readonly _router: Router,
    private readonly _authService: AuthService,
    private readonly _tokenService: TokenService,
    private readonly _animalRepository: AnimalRepository
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | boolean | UrlTree {
    return this._tokenService.getAccessToken() 
      ? this.checkTokenAndProceed(next, state) 
      : this.redirectToLogin(state);
  }

  private checkTokenAndProceed(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const token = this._tokenService.getAccessToken();

    if (token && this._tokenService.isTokenExpired(token)) {
      return this._tokenService.renewToken().pipe(
        switchMap(newToken => newToken ? this.executeGuardLogic(next, state) : this.redirectToLogin(state))
      );
    }

    return this.executeGuardLogic(next, state);
  }
  private executeGuardLogic(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this._animalRepository.getAnimals$().pipe(
      filter(animals => animals.length > 0),
      first(),
      switchMap(animals => this.checkAccess(next, animals, state))
    );
  }
  

  private checkAccess(next: ActivatedRouteSnapshot, animals: any[], state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this._authService.isLoggedIn$.pipe(
      filter(val => val !== null),
      take(1),
      switchMap(isLoggedIn => {
        if (!isLoggedIn) {
          return this.redirectToLogin(state);
        }
  
        if (next.paramMap.has('id')) {
          const id = +next.paramMap.get('id')!;
          if (isNaN(id) || !animals.some(animal => Number(animal.id) === id)) {
            return of(this._router.createUrlTree(['/home']));
          }
        }
  
        return of(true);
      })
    );
  }
  

  private redirectToLogin(state: RouterStateSnapshot): Observable<UrlTree> {
    return of(this._router.createUrlTree(['/login'], { queryParams: { returnUrl: state.url } }));
  }
}
