import { createStore, select, withProps } from '@ngneat/elf';
import { withRequestsCache, withRequestsStatus } from '@ngneat/elf-requests';
import { Injectable } from "@angular/core";
import { AnimalModel } from "../models/animal.model";
import { AnimalOriginModel } from "../models/animal-origin.model";
import { RestService } from "../../../shared/services/rest.service";
import { Observable, of } from 'rxjs';
import { tap, switchMap, catchError, take, map } from "rxjs/operators";
import { TranslocoService } from '@jsverse/transloco';
import { ToastService } from 'src/app/shared/services/toast.service';

export interface AnimalState {
  animals: Record<number, AnimalModel>;
}

export const store = createStore(
  { name: 'animals' },
  withProps<AnimalState>({ animals: {} }),
  withRequestsCache<'animals'>(),
  withRequestsStatus<'animals'>()
);

@Injectable({ providedIn: 'root' })
export class AnimalRepository {
  private loadedSearchTerms = new Set<string>();

  constructor(
    private _restService: RestService,
    private _toast: ToastService,
    private _translateService: TranslocoService
  ) {}
  
  getAnimals$(): Observable<AnimalModel[]> {
    return store.pipe(
      select(state => {
        return Object.values(state.animals);
      })
    );
  }

  getById(animalId: number): Observable<AnimalModel | undefined> {
    if (!animalId) return of(undefined);
  
    return store.pipe(
      select(state => state.animals[animalId]),
      switchMap(cachedAnimal => {
        if (cachedAnimal && cachedAnimal.hasFullData) {
          return of(cachedAnimal);
        }

        return this._restService.httpGet(`/animals/${animalId}`).pipe(
          take(1),
          tap(response => {
            store.update(state => ({
              ...state,
              animals: {
                ...state.animals,
                [animalId]: { ...response, hasFullData: true }
              }
            }));
          }),
          catchError(() => of(undefined))
        );
      })
    );
  }

  getByShelter(shelterId: number): void {
    this._restService.httpGet(`/animals?short=true&pagination=false&animalShelter.id=${shelterId}`)
      .pipe(take(1))
      .subscribe(data => {
        const updatedAnimals = data.reduce((acc: Record<number, AnimalModel>, animal: AnimalModel) => {
          acc[animal.id] = { ...animal, hasFullData: false };
          return acc;
        }, {});

        store.update(state => ({
          ...state,
          animals: updatedAnimals
        }));
      });
  }

  searchAnimals(searchTerm: string, page: number = 1): Observable<AnimalModel[]> {
    return this._restService.httpGet(`/animals?page=${page}&search=${searchTerm}`).pipe(
      take(1),
      catchError(() => of([]))
    );
  }

  searchAnimalsAndUpdateStore(searchTerm: string, shelterId: number, page: number = 1): void {
    if (this.loadedSearchTerms.has(searchTerm)) return;
    this.loadedSearchTerms.add(searchTerm);

    this._restService.httpGet(`/animals?page=${page}&animalShelter.id=${shelterId}&search=${searchTerm}`)
      .pipe(take(1))
      .subscribe(data => {
        const updatedAnimals = data.reduce((acc: any, animal: any) => {
          acc[animal.id] = animal;
          return acc;
        }, {} as Record<number, AnimalModel>);

        store.update(state => ({
          ...state,
          animals: { ...state.animals, ...updatedAnimals }
        }));
      });
  }

  addAnimal(animal: AnimalModel): void {
    store.update(state => {
      if (state.animals[animal.id]) {
        return state; // 🔹 Falls vorhanden, gibt es keine Änderung am State
      }

      return {
        ...state,
        animals: {
          ...state.animals,
          [animal.id]: animal
        }
      };
    });
  }

  create(payload: any): Observable<number> {
    return this._restService.httpPost('/animals', payload).pipe(
      tap(response => store.update(state => ({
        ...state,
        animals: { ...state.animals, [response.id]: response }
      }))),
      map(response => response.id)
    );
  }

  update(id: number, payload: Partial<AnimalModel>): Observable<AnimalModel> {
    return this._restService.httpPut(`/animals/${id}`, payload).pipe(
      tap(response => store.update(state => ({
        ...state,
        animals: {
          ...state.animals,
          [id]: { ...response }
        }
      })))
    );
  }

  updateAnimalStore(id: number, changes: Partial<AnimalModel>): void {
    store.update(state => ({
      ...state,
      animals: {
        ...state.animals,
        [id]: { ...state.animals[id], ...changes }
      }
    }));
  }

  delete(id: number): Observable<void> {
    return this._restService.httpDelete(`/animals/${id}`).pipe(
      tap(() => store.update(state => {
        const updatedAnimals = { ...state.animals };
        delete updatedAnimals[id];
        return { ...state, animals: updatedAnimals };
      })),
      map(() => void 0)
    );
  }

  addOrigin(animalId: number, newOrigin: AnimalOriginModel): void {
    this.getById(animalId).pipe(take(1)).subscribe(animal => {
      if (animal) {
        const updatedOrigins = [...(animal.origins || []), newOrigin];

        this.update(animalId, { origins: updatedOrigins }).subscribe({
          next: () => this._toast.showSuccess(this._translateService.translate('animalEdit.toast.originAddedSuccess')),
          error: err => console.error('Error adding origin:', err)
        });
      } else {
        console.error(`Animal with ID ${animalId} not found`);
      }
    });
  }

  updateAnimalField(id: number, field: keyof AnimalModel, value: boolean): void {
    this._restService.httpPut(`/animals/${id}`, { [field]: value }).pipe(take(1)).subscribe(() => {
      store.update(state => ({
        ...state,
        animals: { ...state.animals, [id]: { ...state.animals[id], [field]: value } }
      }));
      this._toast.showSuccess(this._translateService.translate('animalEdit.toast.saveSuccess'));
    });
  }

  setSyncWithWebsite(id: number, checked: boolean): void {
    this.updateAnimalField(id, 'syncWithWebsite', checked);
  }

  setSanctuary(id: number, checked: boolean): void {
    this.updateAnimalField(id, 'sanctuary', checked);
  }

  setFosterHomeWanted(id: number, checked: boolean): void {
    this.updateAnimalField(id, 'fosterHomeWanted', checked);
  }

  setSponsored(id: number, checked: boolean): void {
    this.updateAnimalField(id, 'sponsored', checked);
  }

  setOutdoor(id: number, checked: boolean): void {
    this.updateAnimalField(id, 'outdoor', checked);
  }
}
