<div class="card w-full contact-container">
  <div class="header-container">
    <div class="alphabet-nav">
      <button 
        *ngFor="let letter of alphabet" 
        (click)="filterByLetter(letter)" 
        [class.active]="selectedLetter === letter && !searchedContacts.length"
        [class.isDisabled]="searchedContacts.length"
        [disabled]="(!groupedContacts[letter] || groupedContacts[letter].length === 0) || searchedContacts.length">
        {{ letter }}
      </button>
    </div>
    <div class="input-container">
      <input 
        type="text" 
        pInputText 
        [value]="searchText"
        (input)="onSearchChange($event)"
        placeholder="{{ 'contacts.searchContact' | transloco }}"
        spellcheck="false" autocomplete="off"
      />
      <i 
        *ngIf="searchText" 
        class="pi pi-times clear-icon"
        (click)="clearSearch()">
      </i>
    </div>
    <p-button
      label="{{ 'contacts.createNewContact' | transloco }}"
      iconPos="left"
      icon="pi pi-plus"
      class="p-button-rounded flex"
      (click)="toggleCreateContact(true)"
    ></p-button>
  </div>

  <div class="contact-header">
    <span>{{ 'contacts.gender' | transloco }}</span>
    <span>{{ 'contacts.lastName' | transloco }}</span>
    <span>{{ 'contacts.firstName' | transloco }}</span>
    <span>{{ 'contacts.company' | transloco }}</span>
    <span>{{ 'contacts.street' | transloco }}</span>
    <span>{{ 'contacts.city' | transloco }}</span>
    <span>{{ 'contacts.zipCode' | transloco }}</span>
    <span>{{ 'contacts.birthday' | transloco }}</span>
    <span>{{ 'contacts.birthPlace' | transloco }}</span>
    <span></span>
  </div>

  <div class="contact-list">
    <p-scroller #scroller [items]="searchedContacts.length ? searchedContacts : filteredContacts" [itemSize]="50" class="full-height">
      <ng-template let-contact pTemplate="item">
        <div class="contact-item"
          [id]="'contact-' + contact.id"
          [ngClass]="{ 'highlight': highlightedContactId === contact.id }"
          (click)="toggleEditContact(true, contact)"
        >
          <i class="pi pi-user"></i>
          <span>{{ contact.gender ? ('salutation.' + contact.gender | transloco) : '-' }}</span>
          <span>{{ contact.lastName || '-' }}</span>
          <span>{{ contact.firstName || '-' }}</span>
          <span>{{ contact.companyName || '-' }}</span>
          <span>{{ contact.street || '-' }}</span>
          <span>{{ contact.city || '-' }}</span>
          <span>{{ contact.zipCode || '-' }}</span>
          <span>{{ contact.birthday ? (contact.birthday | date: 'dd.MM.yyyy') : '-' }}</span>
          <span>{{ contact.birthPlace || '-' }}</span>
          <span class="flex justify-content-end"><i class="pi pi-pencil"></i></span>
        </div>
      </ng-template>
    </p-scroller>
  </div>
</div>




<p-dialog [(visible)]="openCreateContactModal"
  [style]="{ width: '90vw', height: '90vh' }"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  (onHide)="toggleCreateContact(false)"
>
  <ng-template pTemplate="header">
    <h3>{{ 'animalAdd.origin.edit' | transloco }}</h3>
  </ng-template>
  <ng-template pTemplate="content">
    <app-choose-contact 
      [onlyCreateContact]="true"
      [newContact]="newContact"
      (newContactUpdated)="onContactUpdate($event)"
      (validationStatusChanged)="validationStatusChanged = $event"
    >
    </app-choose-contact>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="flex">
      <div class="flex-1">
        <button class="p-button p-button-sm" (click)="toggleCreateContact(false)">
          {{ 'modal.cancel' | transloco }}
        </button>
        <button class="p-button p-button-sm" (click)="saveNewContact()" [disabled]="!validationStatusChanged">
          {{ 'modal.save' | transloco }}
        </button>
      </div>
    </div>
  </ng-template>
</p-dialog>
<p-dialog [(visible)]="openEditContactModal"
  [style]="{ width: '90vw', height: '90vh' }"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  (onHide)="toggleEditContact(false)"
>
  <ng-template pTemplate="header">
    <h3>{{ 'animalAdd.origin.edit' | transloco }}</h3>
  </ng-template>
  <ng-template pTemplate="content">
    <app-choose-contact 
      [onlyEditContact]="true"
      [newContact]="newContact"
      (newContactUpdated)="onContactUpdate($event)"
      (validationStatusChanged)="validationStatusChanged = $event"
    >
    </app-choose-contact>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="flex">
      <div class="flex-1">
        <button class="p-button p-button-sm" (click)="toggleEditContact(false)">
          {{ 'modal.cancel' | transloco }}
        </button>
        <button class="p-button p-button-sm" (click)="saveUpdateContact()" [disabled]="!validationStatusChanged">
          {{ 'modal.save' | transloco }}
        </button>
      </div>
    </div>
  </ng-template>
</p-dialog>
