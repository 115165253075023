import {take, takeWhile} from 'rxjs';
import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {AnimalRepository} from "../states/animal.repository";
import {AnimalEditService} from "../services/animal-edit.service";
import {AnimalModel} from "../models/animal.model";
import {AnimalShelterModel} from "../models/animal-shelter.model";
import {AnimalSheltersRepository} from "../states/animal-shelter.repository";
import {Router} from "@angular/router";
import {HelperService} from "../../../shared/services/helper.service";


@Component({
  selector: 'app-animal-move-dialog',
  templateUrl: './animal-move-dialog.component.html',
  styleUrls: ['./animal-move-dialog.component.scss']
})
export class AnimalMoveDialogComponent implements OnInit, OnDestroy {
  @Input() animal: AnimalModel | undefined;

  alive: boolean = true;
  moveDialogVisible: boolean = false;
  shelters: AnimalShelterModel[] | undefined;
  selectedShelter: AnimalShelterModel | undefined;
  selectedDate: Date;

  constructor(
    private readonly _animalEditService: AnimalEditService,
    private readonly _animalRepository: AnimalRepository,
    private readonly _animalShelterRepository: AnimalSheltersRepository,
    private readonly _router: Router,
    private readonly _helperService: HelperService
  ) {
    this.selectedDate = new Date();
  }

  ngOnInit(): void {
    this._animalEditService.moveDialogVisible.pipe(takeWhile(() => this.alive)).subscribe((openModal) => {
      this.moveDialogVisible = openModal;
    });

    this._animalShelterRepository.getAnimalShelters().pipe(take(1)).subscribe(shelters => {
      this.shelters = shelters;
    });

    this.selectedShelter = this.animal?.animalShelter;
  }

  closeModal() {
    this._animalEditService.toggleMoveDialog();
  }

  saveChanges() {
    if (this.animal === undefined) {
      this.closeModal();

      return;
    }

    this.selectedDate = this._helperService.normalizeDate(this.selectedDate);

    this._animalRepository.update(this.animal.id, {
      animalShelter: this.selectedShelter,
      shelterChangeDate: this.selectedDate
    }).subscribe({
      next: () => {
        if (this.animal) {
          this._animalRepository.getByShelter(this.animal?.animalShelter.id);
          this._router.navigate(['/tiere']);
        }
      },
      error: err => console.error('Fehler beim Aktualisieren:', err)
    });
    

    this.closeModal();
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
