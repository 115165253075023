<ng-container *ngFor="let animalOrigItem of animalOrigItems | keyvalue: lastEntryFirst; let i = index">
  <div
    class="card details-view"
    *ngIf="
      !isEditingMode
  ">
    <div class="grid">
      <div class="col-12" *ngIf="!isEditingMode">
        <div class="flex">
          <div class="flex-1 p-4 text-xl font-semibold">
            {{ 'animalOrigin.general.headline' | transloco }}
            <ng-container *ngIf="animalOrigItem.value.location">
              | {{ animalOrigItem.value.location }}
            </ng-container>
          </div>
        </div>
      </div>
      <div class="col-12">
        <p-accordion [activeIndex]="i === 0 ? [0, 1, 2] : []" [multiple]="true">
          <p-accordionTab>
            <ng-template pTemplate="header">
              <div class="flex justify-content-between w-full align-items-center">
                <span class="flex-order-0 p-accordion-custom-header-text">{{ 'animalDetails.general.headline' | transloco }}</span>
                <button class="p-button p-button-sm" (click)="toggleEditDialog(true, 'general', animalOrigItem.value.id)">
                  <i class="pi pi-pencil mr-1"></i>
                  {{ 'edit' | transloco }}
                </button>
              </div>
            </ng-template>
            <ng-template pTemplate="content">
              <ng-container>
                <ng-container *ngTemplateOutlet="generalForm; context: { key: animalOrigItem.key, animalOrigItem: animalOrigItem.value}"></ng-container>
              </ng-container>
            </ng-template>
          </p-accordionTab>
          <p-accordionTab>
            <ng-template pTemplate="header">
              <div class="flex justify-content-between w-full align-items-center">
                <span class="flex-order-0 p-accordion-custom-header-text">{{ 'animalDetails.general.givingContact' | transloco }}</span>
                <button class="p-button p-button-sm" (click)="toggleEditDialog(true, 'givingContact', animalOrigItem.value.id, (animalOrigItem.value.givingContact?.id))">
                  <i class="pi pi-pencil mr-1"></i>
                  {{ 'edit' | transloco }}
                </button>
              </div>
            </ng-template>
            <ng-template pTemplate="content">
              <ng-container *ngIf="!animalOrigItem.value.givingContact">
                <span>{{ 'contacts.noContactExist' | transloco }}</span>
              </ng-container>
              <ng-container *ngIf="animalOrigItem.value.givingContact && contacts[animalOrigItem.value.givingContact?.id]">
                <ng-container *ngTemplateOutlet="contactForm; context: { contact: contacts[animalOrigItem.value.givingContact.id]}"></ng-container>
              </ng-container>
            </ng-template>
          </p-accordionTab>
          <p-accordionTab *ngIf="animalOrigItem.value.reasonForGiving === 'delivery'">
            <ng-template pTemplate="header">
              <div class="flex justify-content-between w-full align-items-center">
                <span class="flex-order-0 p-accordion-custom-header-text">{{ 'animalDetails.general.owningContact' | transloco }}</span>
                <button class="p-button p-button-sm" (click)="toggleEditDialog(true, 'owningContact', animalOrigItem.value.id, (animalOrigItem.value.owningContact?.id))">
                  <i class="pi pi-pencil mr-1"></i>
                  {{ 'edit' | transloco }}
                </button>
              </div>
            </ng-template>
            <ng-template pTemplate="content">
              <ng-container *ngIf="!animalOrigItem.value.owningContact">
                <span>{{ 'contacts.noContactExist' | transloco }}</span>
              </ng-container>
              <ng-container *ngIf="animalOrigItem.value.owningContact && contacts[animalOrigItem.value.owningContact?.id]">
                <ng-container *ngTemplateOutlet="contactForm; context: { contact: contacts[animalOrigItem.value.owningContact.id]}"></ng-container>
              </ng-container>
            </ng-template>
          </p-accordionTab>
        </p-accordion>
      </div>
    </div>
  </div>
</ng-container>


<ng-template #generalForm let="key" let-animalOrigItem="animalOrigItem">
  <div class="grid">
    <div class="col-6" *ngIf="isEditingMode || animalOrigItem?.location">
      <label class="py-1" for="location">
        {{ 'animalAdd.origin.location' | transloco }}
      </label>
      <input
        type="text"
        class="w-full p-inputtext-sm"
        [class.no-border]="!isEditingMode"
        pInputText
        [(ngModel)]="animalOrigItem.location"
        id="location"
        [disabled]="!isEditingMode"
      />
    </div>
    <ng-container  *ngIf="animalOrigItem?.competentAuthority?.id && !isEditingMode">
        <div class="col-12">
        <label class="py-1" for="competentAuthorityId">
          {{ 'animalAdd.origin.competentAuthority' | transloco }}
          <span *ngIf="isEditingMode" class="required">*</span>
        </label>
        <input *ngIf="!isEditingMode"
          type="text"
          class="w-full p-inputtext-sm"
          [class.no-border]="!isEditingMode"
          pInputText
          [value]="cityName[animalOrigItem?.competentAuthority?.id ?? '']"
          disabled
        />
      </div>
      </ng-container>
      <ng-container *ngIf="isEditingMode && (selectedOptionReasonTransfer?.value === 'found-animal' || selectedOptionReasonTransfer?.value === 'take-away')">
        <div class="col-6">
        <label class="py-1" for="competentAuthorityId">
          {{ 'animalAdd.origin.competentAuthority' | transloco }}
          <span *ngIf="isEditingMode" class="required">*</span>
        </label>
        <p-dropdown
          *ngIf="isEditingMode"
          class="p-inputdropdown-sm w-full"
          id="competentAuthorityId"
          [options]="originCompetentAuthorityOptions"
          optionLabel="name"
          optionValue="id"
          [(ngModel)]="editingItem.competentAuthority.id"
          [autoDisplayFirst]="false"
          [required]="true"
          appendTo="body"
        ></p-dropdown>
      </div>
      </ng-container>
    <div class="col-6" *ngIf="isEditingMode || animalOrigItem?.reasonForGiving">
      <label class="py-1" for="originReasonForTransfer">
        {{ 'animalAdd.origin.reasonForTransfer' | transloco }}
        <span *ngIf="isEditingMode" class="required">*</span>
      </label>
      <p-dropdown
        class="p-inputdropdown-sm w-full"
        id="originReasonForTransfer"
        [options]="originReasonForTransfer"
        [(ngModel)]="selectedOptionReasonTransfer"
        (ngModelChange)="validateGeneralForm()"
        optionLabel="name"
        [autoDisplayFirst]="false"
        appendTo="body"
        [disabled]="!isEditingMode"
      ></p-dropdown>
    </div>
    <div class="col-6">
      <label class="py-1" for="dateOfReceipt">
        {{ 'animalAdd.origin.dateOfReceipt' | transloco }}
        <span class="required">*</span>
      </label>
      <p-calendar class="w-full p-inputcalendar-sm"
        id="dateOfReceipt"
        dateFormat="dd.mm.yy" [showIcon]="true"
        [(ngModel)]="animalOrigItem.dateOfReceipt"
        (ngModelChange)="validateGeneralForm()"
        appendTo="body"
        [disabled]="!isEditingMode"
      ></p-calendar>
    </div>
    <div class="col-12" *ngIf="isEditingMode || animalOrigItem?.specialInformation">
      <label class="py-1" for="specialInformation">{{
        'animalAdd.origin.specialInformation' | transloco
      }}</label>
      <textarea
        pInputText
        class="p-inputtext-sm w-full"
        area
        [(ngModel)]="animalOrigItem.specialInformation"
        id="specialInformation"
        rows="5"
        [disabled]="!isEditingMode"
      ></textarea>
    </div>
  </div>
  <div class="grid">
    <div class="col-6"  *ngIf="isEditingMode || animalOrigItem?.conditionOfCare?.length">
      <label class="py-1" for="conditionBad">{{
        'animalAdd.origin.conditionOfCare.headline' | transloco
      }}</label>
      <div class="flex flex-wrap gap-3 p-2">
        <div class="flex align-items-center">
          <p-radioButton
            inputId="conditionBad"
            id="conditionBad"
            value="bad"
            [(ngModel)]="animalOrigItem.conditionOfCare"
            [disabled]="!isEditingMode"
          ></p-radioButton>
          <label for="conditionBad" class="ml-2">{{
            'animalAdd.origin.conditionOfCare.bad' | transloco
          }}</label>
        </div>
        <div class="flex align-items-center">
          <p-radioButton
            inputId="conditionMedium"
            id="conditionMedium"
            value="medium"
            [(ngModel)]="animalOrigItem.conditionOfCare"
            [disabled]="!isEditingMode"
          ></p-radioButton>
          <label for="conditionMedium" class="ml-2">{{
            'animalAdd.origin.conditionOfCare.medium' | transloco
          }}</label>
        </div>
        <div class="flex align-items-center">
          <p-radioButton
            inputId="conditionGood"
            id="conditionGood"
            value="good"
            [(ngModel)]="animalOrigItem.conditionOfCare"
            [disabled]="!isEditingMode"
          ></p-radioButton>
          <label for="conditionGood" class="ml-2">{{
            'animalAdd.origin.conditionOfCare.good' | transloco
          }}</label>
        </div>
      </div>
    </div>
    <div class="col-6" *ngIf="isEditingMode || animalOrigItem?.nutritionalStatus?.length">
      <label class="py-1" for="nutritionBad">
        {{ 'animalAdd.origin.nutritionalStatus.headline' | transloco }}
      </label>
      <div class="flex flex-wrap gap-3 p-2">
        <div class="flex align-items-center">
          <p-radioButton
            inputId="nutritionBad"
            id="nutritionBad"
            value="bad"
            [(ngModel)]="animalOrigItem.nutritionalStatus"
            [disabled]="!isEditingMode"
          ></p-radioButton>
          <label for="nutritionBad" class="ml-2">{{
            'animalAdd.origin.nutritionalStatus.bad' | transloco
          }}</label>
        </div>
        <div class="flex align-items-center">
          <p-radioButton
            inputId="nutritionMedium"
            id="nutritionMedium"
            value="medium"
            [(ngModel)]="animalOrigItem.nutritionalStatus"
            [disabled]="!isEditingMode"
          ></p-radioButton>
          <label for="nutritionMedium" class="ml-2">{{
            'animalAdd.origin.nutritionalStatus.medium' | transloco
          }}</label>
        </div>
        <div class="flex align-items-center">
          <p-radioButton
            inputId="nutritionGood"
            id="nutritionGood"
            value="good"
            [(ngModel)]="animalOrigItem.nutritionalStatus"
            [disabled]="!isEditingMode"
          ></p-radioButton>
          <label for="nutritionGood" class="ml-2">{{
            'animalAdd.origin.nutritionalStatus.good' | transloco
          }}</label>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="isEditingMode || animalOrigItem?.otherComments">
      <label class="py-1" for="originOtherComments">{{
        'animalAdd.origin.otherComments' | transloco
      }}</label>
      <textarea
        pInputText
        area
        class="p-inputtext-sm w-full"
        [(ngModel)]="animalOrigItem.otherComments"
        id="originOtherComments"
        rows="5"
        [disabled]="!isEditingMode"
      ></textarea>
    </div>
  </div>
</ng-template>


<ng-template #contactForm let-contact="contact">
  <ng-container>
    <div class="grid">
      <div class="col-5" *ngIf="isEditingMode || contact.gender">
        <label class="py-1" for="personSalutation">
          {{ 'animalAdd.origin.person.salutation' | transloco }}
        </label>
        <p-dropdown
          class="p-inputdropdown-sm w-full"
          [options]="originSubmittedPersonSalutation"
          optionLabel="name"
          optionValue="value"
          [autoDisplayFirst]="false"
          [(ngModel)]="contact.gender"
          id="personSalutation"
          appendTo="body"
          [disabled]="!isEditingMode"
        >
        </p-dropdown>
      </div>
      <div class="col-7" *ngIf="isEditingMode || contact.idCardNumber">
        <label class="py-1" for="personIdCardNumber">
          {{ 'animalAdd.origin.person.idCardNumber' | transloco }}
        </label>
        <input
          type="text"
          class="w-full p-inputtext-sm"
          [class.no-border]="!isEditingMode"
          [value]="contact.idCardNumber"
          id="personIdCardNumber"
          pInputText
          [disabled]="!isEditingMode"
        />
      </div>
      <div class="col-12" *ngIf="isEditingMode || contact.firstName">
        <label class="py-1" for="personFirstName">
          {{ 'animalAdd.origin.person.firstName' | transloco }}
        </label>
        <input type="text" class="w-full p-inputtext-sm"
          [class.no-border]="!isEditingMode"
          [value]="contact.firstName"
          id="personFirstName"
          pInputText
          [disabled]="!isEditingMode"
        />
      </div>
      <div class="col-12" *ngIf="isEditingMode || contact.lastName">
        <label class="py-1" for="personLastName">
          {{ 'animalAdd.origin.person.lastName' | transloco }}
        </label>
        <input type="text" class="w-full p-inputtext-sm"
          [class.no-border]="!isEditingMode"
          [value]="contact.lastName"
          id="personLastName"
          pInputText
          [disabled]="!isEditingMode"
        />
      </div>
      <div class="col-12" *ngIf="isEditingMode || contact.companyName">
        <label class="py-1" for="companyName">
          {{ 'animalAdd.origin.person.companyName' | transloco }}
        </label>
        <input type="text" class="w-full p-inputtext-sm"
          [class.no-border]="!isEditingMode"
          [value]="contact.companyName"
          id="companyName"
          pInputText
          [disabled]="!isEditingMode"
        />
      </div>
      <div class="col-12" *ngIf="isEditingMode || contact.street">
        <label class="py-1" for="personStreet">
          {{ 'animalAdd.origin.person.street' | transloco }}
        </label>
        <input type="text" class="w-full p-inputtext-sm"
          [class.no-border]="!isEditingMode"
          [value]="contact.street"
          id="personStreet"
          pInputText
          [disabled]="!isEditingMode"
        />
      </div>
      <div class="col-6" *ngIf="isEditingMode || contact.zipCode">
        <label class="py-1" for="personZipCode">
          {{ 'animalAdd.origin.person.zipCode' | transloco }}
        </label>
        <input
          type="text"
          class="w-full p-inputtext-sm"
          [class.no-border]="!isEditingMode"
          [value]="contact.zipCode"
          id="personZipCode"
          pInputText
          [disabled]="!isEditingMode"
        />
      </div>
      <div class="col-12" *ngIf="isEditingMode || contact.city">
        <label class="py-1" for="personCity">
          {{ 'animalAdd.origin.person.city' | transloco }}
        </label>
        <input
          type="text"
          class="w-full p-inputtext-sm"
          [class.no-border]="!isEditingMode"
          [value]="contact.city"
          id="personCity"
          pInputText
          [disabled]="!isEditingMode"
        />
      </div>
      <div class="col-6" *ngIf="isEditingMode || contact.mobilePhoneNumber">
        <label class="py-1" for="personMobileNumber">
          {{ 'animalAdd.origin.person.mobileNumber' | transloco }}
        </label>
        <input
          type="text"
          class="w-full p-inputtext-sm"
          [class.no-border]="!isEditingMode"
          [value]="contact.mobilePhoneNumber"
          id="personMobileNumber"
          pInputText
          [disabled]="!isEditingMode"
        />
      </div>
      <div class="col-4" *ngIf="isEditingMode || contact.mobilePhoneNumberName">
        <label class="py-1" for="personMobileNumberName">
          {{ 'animalAdd.origin.person.mobileNumberName' | transloco }}
        </label>
        <input
          type="text"
          class="w-full p-inputtext-sm"
          [class.no-border]="!isEditingMode"
          [value]="contact.mobilePhoneNumberName"
          id="personMobileNumberName"
          pInputText
          [disabled]="!isEditingMode"
        />
      </div>
      <div class="col-6" *ngIf="isEditingMode || contact.mobilePhoneNumber2">
        <label class="py-1" for="personMobileNumber2">
          {{ 'animalAdd.origin.person.mobileNumber2' | transloco }}
        </label>
        <input
          type="text"
          class="w-full p-inputtext-sm"
          [class.no-border]="!isEditingMode"
          [value]="contact.mobilePhoneNumber2"
          id="personMobileNumber2"
          pInputText
          [disabled]="!isEditingMode"
        />
      </div>
      <div class="col-4" *ngIf="isEditingMode || contact.mobilePhoneNumber2Name">
        <label class="py-1" for="personMobileNumber2Name">
          {{ 'animalAdd.origin.person.mobileNumber2Name' | transloco }}
        </label>
        <input
          type="text"
          class="w-full p-inputtext-sm"
          [class.no-border]="!isEditingMode"
          [value]="contact.mobilePhoneNumber2Name"
          id="personMobileNumber2Name"
          pInputText
          [disabled]="!isEditingMode"
        />
      </div>
      <div class="col-6" *ngIf="isEditingMode || contact.phoneNumber">
        <label class="py-1" for="personPhoneNumber">
          {{ 'animalAdd.origin.person.phoneNumber' | transloco }}
        </label>
        <input
          type="text"
          class="w-full p-inputtext-sm"
          [class.no-border]="!isEditingMode"
          [value]="contact.phoneNumber"
          id="personPhoneNumber"
          pInputText
          [disabled]="!isEditingMode"
        />
      </div>
 

      <div class="col-6" *ngIf="isEditingMode || contact.birthday">
        <label class="py-1" for="personBirthday">
          {{ 'animalAdd.origin.person.birthday' | transloco }}
        </label>
        <input
          type="text"
          class="w-full p-inputtext-sm"
          [class.no-border]="!isEditingMode"
          [value]="contact.birthday | date: 'dd.MM.yyyy'"
          id="personBirthday"
          pInputText
          [disabled]="!isEditingMode"
        />
      </div>
      <div class="col-12" *ngIf="isEditingMode || contact.birthPlace">
        <label class="py-1" for="personBirthPlace">
          {{ 'animalAdd.origin.person.birthPlace' | transloco }}
        </label>
        <input
          type="text"
          class="w-full p-inputtext-sm"
          [class.no-border]="!isEditingMode"
          [value]="contact.birthPlace"
          id="personBirthPlace"
          pInputText
          [disabled]="!isEditingMode"
        />
      </div>
      <div class="col-12" *ngIf="isEditingMode || contact.email">
        <label class="py-1" for="personEmail">
          {{ 'animalAdd.origin.person.email' | transloco }}
        </label>
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon" [pTooltip]="'animalAdd.origin.person.email' | transloco" tooltipPosition="top">
            <a class="absolute" *ngIf="contact.email" [href]="'mailto:' + contact.email">
              <i class="pi pi-envelope"></i>
            </a>
          </span>
          <input
            type="text"
            class="w-full p-inputtext-sm"
            [class.no-border]="!isEditingMode"
            [value]="contact.email"
            id="personEmail"
            pInputText
            [disabled]="!isEditingMode"
          />
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>


<p-dialog [(visible)]="openEdit"
  [style]="{ width: '90vw', height: '90vh' }"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  (onHide)="toggleEditDialog(false, '', 0, '')"
>
  <ng-template pTemplate="header">
    <h3>{{ 'animalAdd.origin.edit' | transloco }}</h3>
  </ng-template>
  <ng-template pTemplate="content">
    <ng-container *ngIf="editType === 'general'">
      <ng-container *ngTemplateOutlet="generalForm; context: { key: editDialogOriginKey, animalOrigItem: editingItem}"></ng-container>
    </ng-container>
    <ng-container *ngIf="editType === 'givingContact' || editType === 'owningContact'">
      <app-choose-contact 
        [newContact]="editingItem"
        [isExistingContact]="existingContact"
        (newContactUpdated)="onContactUpdate($event)" 
        (validationStatusChanged)="onValidationStatusChanged($event)"
        (existingContactSelected)="onExistingContactSelected($event)"
        (existingContactUpdated)="onExistingContactUpdated($event)"
      >
      </app-choose-contact>
    </ng-container>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="flex">
      <div class="flex-1">
        <button class="p-button p-button-sm" (click)="toggleEditDialog(false, '', 0, '')">
          {{ 'modal.cancel' | transloco }}
        </button>
        <button class="p-button p-button-sm" (click)="save(editingItem)" [disabled]="!validationStatusChanged">
          {{ 'modal.save' | transloco }}
        </button>
      </div>
    </div>
  </ng-template>
</p-dialog>
