import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {AnimalRepository} from "./states/animal.repository";
import {AnimalService} from "./services/animal.service";
import {debounceTime, Subject, Subscription, take} from "rxjs";
import {AnimalModel} from "./models/animal.model";
import {AnimalAddService} from "./services/animal-add.service";
import {AnimalFilter} from "./animal-filters/animal-filter.model";
import {DataView, DataViewPaginatorState} from 'primeng/dataview';
import {AnimalWhereaboutsRepository} from "./states/animal-whereabouts.repository";
import {AnimalOriginRepository} from "./states/animal-origin.repository";
import { AnimalSheltersRepository } from './states/animal-shelter.repository';

@Component({
  selector: 'app-animal-overview',
  templateUrl: './animal-overview.component.html',
  styleUrls: ['./animal-overview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AnimalOverviewComponent implements OnInit, OnDestroy {
  @ViewChild('dv') dataView!: DataView;
  alive: boolean = true;
  animals: AnimalModel[] = [];
  filteredAnimals: AnimalModel[] = [];
  selectedFilterSubscription: Subscription;
  layout: "list" | "grid" = 'grid';
  categoryFilters: AnimalFilter[] = [];
  searchTerm: string = '';
  searchTerm$ = new Subject<string>();
  rowCount: number = 3;
  addDialogVisible: boolean = false;
  whereabouts: any = new Map<number, any>();
  origins: any;

  constructor(
    private readonly _animalRepository: AnimalRepository,
    private readonly _animalAddService: AnimalAddService,
    private readonly _animalWhereaboutsRepository: AnimalWhereaboutsRepository,
    private readonly _animalOriginRepository: AnimalOriginRepository,
    public animalService: AnimalService,
    private _animalSheltersRepository: AnimalSheltersRepository
  ) {
    this.selectedFilterSubscription = this.animalService.selectedFilters$.subscribe(() => {
      this.resetPage();
      this.filterAnimals();
    });
  }

  ngOnInit(): void {
    this.searchTerm = this.animalService.searchTerm;

    this._animalAddService.addDialogVisible.subscribe((openModal) => {
      this.addDialogVisible = openModal;
    });

    this._animalRepository.getAnimals$().subscribe((animals: AnimalModel[]) => {
      this.animals = animals;
      this.categoryFilters = this.animalService.getFilterCategories();
      this.filterAnimals();
      this.getVisibleAnimals();
    });

    this.searchTerm$
    .pipe(debounceTime(1000))
    .subscribe(searchTerm => {
      const activeAnimalShelter = this._animalSheltersRepository.getActiveAnimalShelter()?.id;
      if (typeof activeAnimalShelter === 'number') {
        this._animalRepository.searchAnimalsAndUpdateStore(searchTerm, activeAnimalShelter);
      }
    });

    this._animalOriginRepository.getAll().pipe(take(1)).subscribe({
      next: (origins) => {
        if (origins && origins.length > 0) {
          this.origins = origins.reduce((acc: any, origin: any) => {
            acc.set(origin.animal?.id, origin);
            return acc;
          }, new Map<number, typeof origins[0]>());
        }
      }
    });


  }

  getVisibleAnimals(): number[] {
    if (!this.dataView) {
      return [];
    }

    const startIndex = this.dataView.first ?? 0;
    const endIndex = startIndex + this.rowCount;
    return this.filteredAnimals.slice(startIndex, endIndex).map(animal => animal.id);
  }

  loadVisibleWhereabouts(): void {
    const visibleAnimalIds = this.getVisibleAnimals();
    if (!visibleAnimalIds || visibleAnimalIds.length === 0) {
      return;
    }
    visibleAnimalIds.forEach((animalId) => {
      this._animalWhereaboutsRepository.getByAnimalId(animalId).pipe(take(1)).subscribe((whereabouts) => {
        if (Array.isArray(whereabouts) && whereabouts.length > 0) {
          const latestWhereabout = whereabouts.reduce((prev, current) => (prev.id > current.id ? prev : current));
          this.whereabouts.set(animalId, latestWhereabout);
        }
      });
    });
  }



  handleSearch(event: Event): void {
    if (!event.target) {
      return;
    }
    this.searchTerm = (event.target as HTMLInputElement).value;
    this.animalService.searchTerm = this.searchTerm;
    this.resetPage();

    this.filterAnimals();
    this.searchTerm$.next(this.searchTerm);
  }

  filterAnimals(): void {
    const selectedFilters = this.animalService.getSelectedFilters();

    if (selectedFilters.length > 0) {
      this.filteredAnimals = this.animals.filter(animal =>
        selectedFilters.some(filter => animal.category?.toLowerCase() === filter.id.toLowerCase())
      );
    } else {
      this.filteredAnimals = [...this.animals];
    }

    if (this.searchTerm !== '') {
      this.searchTerm = this.searchTerm.toLowerCase();

      this.filteredAnimals = this.filteredAnimals.filter(animal =>
        animal.displayName?.toLowerCase().includes(this.searchTerm) ||
        animal.nameNew?.toLowerCase().includes(this.searchTerm) ||
        animal.nameOld?.toLowerCase().includes(this.searchTerm) ||
        animal.name?.toLowerCase().includes(this.searchTerm) ||
        animal.bookNumber?.toLowerCase().includes(this.searchTerm) ||
        animal.transponderNumber?.toLowerCase().includes(this.searchTerm) ||
        animal.petPassportNumber?.toLowerCase().includes(this.searchTerm) ||
        animal.oldBookNumber?.toLowerCase().includes(this.searchTerm)
      );
    }

    this.filteredAnimals.sort((a, b) => {
      const dateA = a.createdAt ? new Date(a.createdAt).getTime() : 0;
      const dateB = b.createdAt ? new Date(b.createdAt).getTime() : 0;
      return dateB - dateA;
    });

    this.animalService.setSelectedFilters(selectedFilters);
    this.loadVisibleWhereabouts();
  }

  changeLayout(event: any): void {
    this.layout = event.layout;

    switch (event.layout) {
      case 'grid':
        this.rowCount = 3;
        break;
      case 'list':
        this.rowCount = 10;
        break;
    }
    this.loadVisibleWhereabouts();
  }

  onPageChange(event: DataViewPaginatorState): void {
    const page = Math.floor((event.first ?? 0) / (event.rows ?? this.rowCount));
    this.animalService.currentPage = page;
    this.loadVisibleWhereabouts();
  }

  resetPage(): void {
    if (!this.dataView) {
      return;
    }
    this.animalService.currentPage = 0;
    this.dataView.first = 0;
  }

  ngOnDestroy(): void {
    this.alive = false;
    this.selectedFilterSubscription.unsubscribe();
  }

  toggleAddDialog(): void {
    this._animalAddService.toggleAddDialog(true);
  }
}
