import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {first, take} from 'rxjs';
import {AnimalSheltersRepository} from "../../../modules/animals/states/animal-shelter.repository";
import {UserRepository} from "../../../modules/user/states/user.repository";
import {AnimalShelterModel} from "../../../modules/animals/models/animal-shelter.model";
import { AnimalRepository } from 'src/app/modules/animals/states/animal.repository';
import { Router } from '@angular/router';

@Component({
  selector: 'app-client-switcher',
  templateUrl: './client-switcher.component.html',
  styleUrls: ['./client-switcher.component.scss']
})
export class ClientSwitcherComponent implements OnInit{
  shelters: AnimalShelterModel[] = [];
  selectedShelter: AnimalShelterModel = {} as AnimalShelterModel

  constructor(
    private _animalShelterRepository: AnimalSheltersRepository,
    private _userRepository: UserRepository,
    private _animalRepository: AnimalRepository,
    private _cdr: ChangeDetectorRef,
    private router: Router
  ) {
  }


  ngOnInit() {
    this._animalShelterRepository.getAnimalShelters().pipe(
      first(shelters => shelters.length > 0)
    ).subscribe(shelters => {
      this.shelters = shelters;
      this._userRepository.getActiveUser().pipe(take(1)).subscribe(user => {
        if (user && user.defaultAnimalShelter !== null) {
          const foundShelter = shelters.find(shelter => shelter.id === user.defaultAnimalShelter?.id);
          if (foundShelter) {
            this.selectedShelter = foundShelter;
          }
          this._cdr.detectChanges();
        }
      });
    });
  }
  

  onShelterChange($event: any) {
    const animalShelter: AnimalShelterModel = $event.value;
    this._animalShelterRepository.setActiveAnimalShelter(animalShelter);
    this._animalRepository.getByShelter(animalShelter.id);
    this._userRepository.getActiveUser().pipe(take(1)).subscribe(user => {
      if (user !== undefined) {
        this._userRepository.updateUser(user.id, {
          defaultAnimalShelter: {id: $event.value.id}
        });
      }
    });
    this.router.navigate(['/home']);
  }
}
