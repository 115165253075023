import {Injectable} from '@angular/core';
import {environment} from 'src/environment/environment';
import {TokenService} from './token.service';

@Injectable({
  providedIn: 'root'
})
export class AssetService {
  public _baseUrl: string;

  constructor(
    private readonly tokenService: TokenService
  ) {
    this._baseUrl = environment.apiUrl;
  }

  public getAssetUrl(url: string) {
    return `${this._baseUrl}${url.replace(new RegExp('^/api'), '')}?x-auth-token=${this.tokenService.getAccessToken()}`;
  }
}
