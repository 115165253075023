import {HttpClient} from '@angular/common/http';

import {Injectable, isDevMode, NgModule} from '@angular/core';
import { DefaultFallbackStrategy, DefaultInterceptor, DefaultMissingHandler, DefaultTranspiler, Translation, TRANSLOCO_CONFIG, TRANSLOCO_FALLBACK_STRATEGY, TRANSLOCO_INTERCEPTOR, TRANSLOCO_LOADER, TRANSLOCO_MISSING_HANDLER, TRANSLOCO_TRANSPILER, TranslocoConfig, translocoConfig, TranslocoLoader, TranslocoModule } from '@jsverse/transloco';


@Injectable({providedIn: 'root'})
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {
  }

  getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
  }
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: ['de', 'en'],
        defaultLang: 'de',
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      })
    },
    {provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader},
    { provide: TRANSLOCO_TRANSPILER, useClass: DefaultTranspiler },
    { provide: TRANSLOCO_MISSING_HANDLER, useClass: DefaultMissingHandler },
    { provide: TRANSLOCO_INTERCEPTOR, useClass: DefaultInterceptor },
    { provide: TRANSLOCO_FALLBACK_STRATEGY, useClass: DefaultFallbackStrategy }
  ]
})
export class TranslocoRootModule {
}
