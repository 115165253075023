import { createStore } from '@ngneat/elf';
import {
  withEntities,
  withActiveId,
  updateEntities,
  addEntities,
  setEntities,
  upsertEntities,
  selectEntity,
  selectAllEntities
} from '@ngneat/elf-entities';
import { withRequestsCache, withRequestsStatus } from '@ngneat/elf-requests';
import { Injectable } from '@angular/core';
import { RestService } from 'src/app/shared/services/rest.service';
import { ContactModel } from '../models/contact.model';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

const storeName = 'contacts';

export const store = createStore(
  { name: storeName },
  withEntities<ContactModel>(),
  withActiveId(),
  withRequestsCache<typeof storeName>(),
  withRequestsStatus<typeof storeName>()
);

@Injectable({ providedIn: 'root' })
export class ContactRepository {
  constructor(private _restService: RestService) {
    store.reset();
  }

  fetchData() {
    this._restService.httpGet('/contacts')
      .pipe(take(1))
      .subscribe((contacts: ContactModel[]) => {
        this.setAll(contacts);
      });
  }

  getAll(): Observable<ContactModel[]> {
    return store.pipe(
      selectAllEntities(),
      take(1),
      switchMap((contacts) => {
        if (contacts.length > 0) { 
          return of(contacts);
        } else {
          return this._restService.httpGet('/contacts').pipe(
            tap((fetchedContacts: ContactModel[]) => {
              this.setAll(fetchedContacts);
            })
          );
        }
      })
    );
  }
  
  

  getById(contactId: number | undefined): Observable<ContactModel> {
    if (contactId === null || contactId === undefined) {
      return of({} as ContactModel);
    }

    return store.pipe(
      selectEntity(contactId),
      take(1),
      switchMap((contact) => {
        if (contact) {
          return of(contact);
        } else {
          return this._restService.httpGet(`/contacts/${contactId}`).pipe(
            tap((fetchedContact: ContactModel) => {
              store.update(upsertEntities(fetchedContact));
            })
          );
        }
      })
    );
  }

  setAll(contacts: ContactModel[]) {
    if (!Array.isArray(contacts)) {
      console.error('Fehler: contacts ist kein Array!', contacts);
      return;
    }

    const validContacts = contacts.filter(contact => contact?.id !== undefined);

    if (validContacts.length === 0) {
      console.warn('Keine gültigen Kontakte zum Setzen gefunden.');
      return;
    }

    store.update(setEntities(validContacts));
  }

  create(payload: any) {
    return this._restService.httpPost('/contacts', payload)
      .pipe(
        tap((response) => {
          if (response && response.id) {
            store.update(addEntities(response));
          }
        })
      );
  }

  searchContacts(searchTerm: string): Observable<ContactModel[]> {
    return this._restService.httpGet(`/contacts?search=${searchTerm}`);
  }

  update(payload: any, contactId: number | undefined) {
    if (contactId === null || contactId === undefined) {
      return of({} as ContactModel);
    }
    return this._restService.httpPut('/contacts/' + contactId, payload)
      .pipe(
        tap((contact) => {
          if (contact && contact.id) {
            store.update(updateEntities(contactId, contact));
          }
        })
      );
  }
}
