<div class="grid nested-grid">
  <div class="col-12">
    <div class="card flex justify-content-end">
      <p-button
        label="{{ 'dashboard.newAnimal' | transloco }}"
        styleClass="mb-2"
        (onClick)="toggleAddDialog()"
      >
        <i class="pi pi-plus mr-1"></i>
      </p-button>
    </div>
  </div>
  <div class="col-12">
          <div class="card p-1 pt-3">
            <h3 class="p-1 text-center">{{ 'dashboard.search.headline' | transloco }}</h3>
              <div class="flex flex-row w-full justify-content-center mb-2 px-3">
                <div class="p-inputgroup">
                  <input type="text" class="w-full" pInputText [(ngModel)]="searchTerm"
                    (input)="onSearchInput()" [ngModelOptions]="{standalone: true}" placeholder="Suche nach Tier"
                    spellcheck="false" autocomplete="off"
                  />
                  <app-barcode-scanner (barcodeScanned)="handleBarcodeScanned($event)"></app-barcode-scanner>
                </div>
              </div>
              <div class="flex-grow-scroll" *ngIf="animalSearchResult.length">
                  <p-dataView [value]="animalSearchResult" [paginator]="true" [rows]="5" sortField="lastModified">
                    <ng-template pTemplate="header" class="p-1">
                      <div class="flex flex-column md:flex-row md:justify-content-between font-semibold">
                        <div class="flex-1 flex align-items-center justify-content-start w-7rem max-w-7rem"></div>
                        <div
                          class="flex-1 flex align-items-center justify-content-start p-2">{{ 'dashboard.search.name' | transloco }}
                        </div>
                        <div
                          class="flex-1 flex align-items-center justify-content-start p-2">{{ 'dashboard.search.shelter' | transloco }}
                        </div>
                        <div class="flex-1 flex align-items-center justify-content-start"></div>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="listItem" let-animal>
                      <div class="animal-item flex flex-row w-full align-items-stretch">
                        <div class="flex flex-shrink-0 min-w-8rem w-8rem min-h-8rem h-8rem p-1 relative">
                          <app-animal-image-container [animal]="animal" [preview]="true"></app-animal-image-container>
                        </div>
                        <div class="flex-1 flex align-items-center justify-content-start p-2">
                          {{ animal?.displayName }}
                          <app-animal-in-stock-icon [animal]="animal"></app-animal-in-stock-icon>
                        </div>
                        <div class="flex-1 flex align-items-center justify-content-start">
                          {{ animal.shelter.title }}
                        </div>
                        <div class="flex-1 flex align-items-center justify-content-start">
                          <p-button label="{{'dashboard.newAnimals.details' | transloco}}" class="p-0 m-1"
                                    (onClick)="openAnimal(animal)" icon="pi pi-eye"/>
                        </div>
                      </div>
                    </ng-template>
                  </p-dataView>
              </div>

          </div>
  </div>
  <div class="col-12">
    <div class="card p-1 pt-3">
      <h3 class="p-1 text-center" [ngStyle]="{'margin-bottom': '20px'}">
        {{ 'dashboard.newAnimals.headline' | transloco }}
      </h3>
      <div class="grid font-bold text-lg border-bottom-1 surface-border pb-2">
        <div class="col-6">
          <div class="grid p-3">
            <div class="col-2 p-2 text-center">{{ 'dashboard.newAnimals.image' | transloco }}</div>
            <div class="col-3 p-2 text-left">{{ 'dashboard.newAnimals.name' | transloco }}</div>
            <div class="col-3 p-2 text-left">{{ 'dashboard.newAnimals.whereabout' | transloco }}</div>
            <div class="col-2 p-2 text-left">{{ 'dashboard.newAnimals.updatedAt' | transloco }}</div>
            <div class="col-2 p-2 text-left"></div>
          </div>
        </div>
        <div class="col-6">
          <div class="grid p-3">
            <div class="col-2 p-2 text-center">{{ 'dashboard.newAnimals.image' | transloco }}</div>
            <div class="col-3 p-2 text-left">{{ 'dashboard.newAnimals.name' | transloco }}</div>
            <div class="col-3 p-2 text-left">{{ 'dashboard.newAnimals.whereabout' | transloco }}</div>
            <div class="col-2 p-2 text-left">{{ 'dashboard.newAnimals.updatedAt' | transloco }}</div>
            <div class="col-2 p-2 text-left"></div>
          </div>
        </div>
      </div>

      <p-dataView [value]="newAnimals" [paginator]="true" [rows]="12" sortField="lastModified" layout="grid">
        <ng-template pTemplate="gridItem" let-animal>
          <div class="col-12 md:col-6 lg:col-6 xl:col-6 p-2">
            <div class="animal-item cursor-pointer grid w-full align-items-stretch border-round-lg shadow-2 p-3 h-full"
              [routerLink]="['/tiere', animal.id]"
            >
              <div class="col-2 flex align-items-center justify-content-center">
                <div class="relative min-w-8rem w-8rem min-h-8rem h-8rem">
                  <app-animal-image-container [animal]="animal" [preview]="true"></app-animal-image-container>
                </div>
              </div>
              <div class="col-3 flex align-items-center justify-content-start">
                {{ animal?.displayName }}
                <app-animal-in-stock-icon [animal]="animal"></app-animal-in-stock-icon>
              </div>
              <div class="col-3 flex align-items-center justify-content-start">
                <span *ngIf="!animal.inStock && whereabouts?.get(animal.id)?.type">
                  {{ whereabouts.get(animal.id)?.location }}
                  <ng-container *ngIf="whereabouts.get(animal.id)?.location && whereabouts.get(animal.id)?.date">
                    -
                  </ng-container>
                  {{ whereabouts.get(animal.id)?.date | date: getDateFormat() }}
                  <ng-container *ngIf="(whereabouts.get(animal.id)?.location || whereabouts.get(animal.id)?.date)
                                        && whereabouts.get(animal.id)?.type">
                    -
                  </ng-container>
                  {{ ('dashboard.newAnimals.whereabouts.' + whereabouts.get(animal.id)?.type) | transloco }}
                </span>
              </div>
              <div class="col-2 flex align-items-center justify-content-start">
                {{ animal.updatedAt | date: getDateFormat() }}
              </div>

              <div class="col-2 flex align-items-center justify-content-center">
                <p-button label="{{'dashboard.newAnimals.details' | transloco}}" class="p-0 m-1 w-full" size="small"
                          [routerLink]="['/tiere', animal.id]" icon="pi pi-eye"/>
              </div>
            </div>
          </div>
        </ng-template>
      </p-dataView>
    </div>
  </div>
</div>
<ng-container *ngIf="addDialogVisible">
  <app-animal-add-dialog></app-animal-add-dialog>
</ng-container>
