export enum AnimalMediaCategory {
  MEDICAL = 'medical',
  ORIGIN = 'origin',
  LUCKY_GUY = 'lucky_guy',
}


export function getAnimalMediaCategoryEnumKey(value: AnimalMediaCategory): string | undefined {
  return Object.keys(AnimalMediaCategory).find((key) => AnimalMediaCategory[key as keyof typeof AnimalMediaCategory] === value);
}
