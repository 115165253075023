import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from "@angular/core";
import {AnimalModel} from "../models/animal.model";
import {AnimalMediaModel} from "../models/animal-media.model";
import {BehaviorSubject, take, takeWhile} from "rxjs";
import {AnimalVideoRepository} from "../states/animal-video.repository";
import {AnimalRepository} from '../states/animal.repository';
import {ToastService} from 'src/app/shared/services/toast.service';
import {TranslocoService} from '@jsverse/transloco';
import {ConfirmationService} from "primeng/api";
import {AnimalMediaCategoryModel} from '../models/animal-media-category.model';
import {AnimalImageService} from '../services/animal-image.service';
import { HelperService } from 'src/app/shared/services/helper.service';
import { MediaDownloadService } from 'src/app/shared/services/media-download.service';


@Component({
  selector: 'app-animal-details-videos',
  templateUrl: './animal-details-videos.component.html',
  styleUrls: ['./animal-details-videos.component.scss']
})
export class AnimalDetailsVideosComponent implements OnInit, OnDestroy {
  @Input() animal: AnimalModel | undefined;
  isAlive: boolean = true;
  videos: AnimalMediaModel[] | any = [];
  selectedVideo: AnimalMediaModel | undefined;
  isEditingMode: boolean = false;
  selectedImgToDelete: number[] = [];

  displayDialog: boolean = false;
  deleteLoading: boolean = false;
  mediaCategoryOptions: AnimalMediaCategoryModel[] = [];
  selectedVideosMap: { [id: number]: boolean } = {};
  selectedVideos: AnimalMediaModel[] = [];

  constructor(
    private readonly _animalVideosRepository: AnimalVideoRepository,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _animalImageService: AnimalImageService,
    private readonly _animalRepository: AnimalRepository,
    private readonly _translateService: TranslocoService,
    private readonly _toast: ToastService,
    private readonly _confirmationService: ConfirmationService,
    private readonly _helperService: HelperService,
    private readonly _mediaDownloadService: MediaDownloadService
  ) {
  }

  ngOnInit(): void {
    if (!this.animal) {
      return;
    }

    this._animalVideosRepository.getVideos(this.animal.id).pipe(take(1)).subscribe((videos: AnimalMediaModel[]) => {
      this.videos = videos;
      this.videos = this.videos.map((video: any) => ({
        ...video,
        translatedCategories: this.getTranslatedCategories(video.categories),
      }));
    });

    this._animalVideosRepository.videoAdded$.pipe(takeWhile(() => this.isAlive)).subscribe(newObj => {
      if (newObj) {
        this.videos.push(newObj);
      }
    });

    this.mediaCategoryOptions = this._animalImageService.getAnimalImageCategories();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this._animalVideosRepository.clearImageAdded();
  }

  openDialog(video: AnimalMediaModel) {
    if (video) {
      this.selectedVideo = JSON.parse(JSON.stringify(video));
      if (this.selectedVideo) {
        this.selectedVideo.date = this.selectedVideo.date
          ? new Date(this.selectedVideo.date)
          : null;
        this.displayDialog = true;
      }
    }
  }

  dummyTranslateFunction() {
    this._translateService.translate('messages.info');
    this._translateService.translate('messages.sharePointSync');
  }

  clickTogglePreview(video: AnimalMediaModel) {
    if (!this.animal) {
      return;
    }
    this.videos.forEach((img: AnimalMediaModel) => {
      if (img.id === video.id) {
        img.preview = !img.preview;
        if (this.animal) {
          this._animalRepository.getById(this.animal.id);
        }
      } else {
        img.preview = false;
      }
    });
    this._animalVideosRepository.updateVideo(video.id, {preview: video.preview}, this.animal.id);
    this._cdr.detectChanges();
  }


  deleteVideo(selectedVideo: AnimalMediaModel) {
    this._confirmationService.confirm({
      message: this._translateService.translate('delete_confirmation'),
      acceptLabel: this._translateService.translate('yes'),
      rejectLabel: this._translateService.translate('no'),
      header: '',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass: "p-button-danger p-button-text",
      rejectButtonStyleClass: "p-button-text p-button-text",
      acceptIcon: "none",
      rejectIcon: "none",

      accept: () => {
        this.deleteLoading = true;
        if (!this.animal) {
          return;
        }
        this._animalVideosRepository.deleteVideo(selectedVideo.id, this.animal.id).pipe(take(1)).subscribe(() => {
          this.deleteLoading = false;
          this.displayDialog = false;
          this.videos = this.videos.filter((video: AnimalMediaModel) => video.id !== selectedVideo.id);
          if (this.animal) {
            this._animalRepository.getById(this.animal.id);
          }
          this._toast.showSuccess(this._translateService.translate('toast.deleteSuccess'));
        });
      },
      reject: () => {
        // rejected
      }
    });
  }

  clickFileExport(video: AnimalMediaModel) {
    if (!this.animal) {
      return;
    }
    video.syncWithSharedFolder = !video.syncWithSharedFolder;
    this._animalVideosRepository.updateVideo(video.id, {syncWithSharedFolder: video.syncWithSharedFolder}, this.animal.id);
    this._cdr.detectChanges();
  }

  clickFileSync(video: AnimalMediaModel) {
    if (!this.animal) {
      return;
    }
    video.syncWithWebsite = !video.syncWithWebsite;
    this._animalVideosRepository.updateVideo(video.id, {syncWithWebsite: video.syncWithWebsite}, this.animal.id);
    this._cdr.detectChanges();
  }

  getTranslatedCategories(categories: string[] | null | undefined): string {
    if (!categories || categories.length === 0) {
      return '';
    }

    return categories
      .map(category => {
        const key = `animal.imageCategory.${category.toUpperCase()}`;
        return this._translateService.translate(key);
      })
      .join(', ');
  }

  toggleEditDialog() {
    this.displayDialog = false;
  }

  saveVideo(video: AnimalMediaModel) {
    if (!this.animal) {
      return;
    }

    this.videos = this.videos.map((img: AnimalMediaModel) => {
      if (img.id === video.id) {
        return {
          ...img,
          ...video,
          translatedCategories: this.getTranslatedCategories(video.categories)
        };
      } else {
        if (video.preview) {
          return {...img, preview: false};
        }
        return img;
      }
    });
    delete video.animal;
    if (video.date) {
      const date = this._helperService.normalizeDate(new Date(video.date));
      video.date = date;
    }

    this._animalVideosRepository.updateVideo(video.id, video, this.animal.id);

    this.displayDialog = false;
    this._cdr.detectChanges();
  }


  onVideoSelectChange(video: AnimalMediaModel): void {
    const selected = this.selectedVideosMap[video.id];
  
    if (selected && !this.selectedVideos.includes(video)) {
      this.selectedVideos.push(video);
    } else if (!selected) {
      this.selectedVideos = this.selectedVideos.filter(vid => vid.id !== video.id);
    }
  }

  downloadSelectedVideos(): void {
    const baseName = this.animal?.displayName?.replace(/\s+/g, '_').replace(/[^a-zA-Z0-9_-]/g, '') || 'video';
  
    this.selectedVideos.forEach((video: AnimalMediaModel, index: number) => {
      const extension = this.getFileExtension(video.contentUrl);
      const numberSuffix = this.selectedVideos.length > 1 ? `-${index + 1}` : '';
      const filename = `${baseName}${numberSuffix}.${extension}`;
  
      const url = video.contentUrl;
      this._mediaDownloadService.downloadMedia(url, filename, index * 500);
    });
  
    this.selectedVideosMap = {};
    this.selectedVideos = [];
  }

  getFileExtension(path: string): string {
    return path?.split('.').pop()?.split('?')[0] || 'mp4';
  }
}
