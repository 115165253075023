import { AnimalMediaModel } from '../../../modules/animals/models/animal-media.model';
import { ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { take } from 'rxjs';
import { AnimalModel } from 'src/app/modules/animals/models/animal.model';
import { AnimalImagesRepository } from 'src/app/modules/animals/states/animal-images.repository';
import {AnimalCategory} from "../../../modules/animals/models/animal-category.enum";
import { AssetService } from '../../services/asset.service';

@Component({
  selector: 'app-animal-image-container',
  templateUrl: './animal-image-container.component.html',
  styleUrls: ['./animal-image-container.component.scss']
})
export class AnimalImageContainerComponent implements OnInit, OnChanges {
  @Input() id?: number | undefined;
  @Input() variant?: string = '';
  @Input() preview?: boolean = false;
  @Input() animal: AnimalModel | undefined;
  @Input() iconBadge: boolean = true;

  imageUrl: string | null | undefined = '';
  isLoading: boolean = false;
  isAlive: boolean = true;

  constructor(
    private cdr: ChangeDetectorRef,
    private _animalImagesRepository: AnimalImagesRepository,
    private _assetService: AssetService
  ) { }


  ngOnInit(): void {
    if (this.animal) {
      this._animalImagesRepository.getImages(this.animal.id).pipe(take(1)).subscribe(() => {
        if (!this.preview && this.id) {
          this.getAnimalImage(this.id);
        } else {
          this.getPreviewImage();
        }
      });
    }
  }


  ngOnChanges(): void {
    if (!this.preview && this.id) {
      this.getAnimalImage(this.id);
    } else {
      this.getPreviewImage();
    }
  }

  getAnimalImage(imageId: number) {
    if (!imageId) {
      return;
    }
  
    this._animalImagesRepository.getImageById(imageId).pipe(take(1)).subscribe((image: AnimalMediaModel | null) => {
      if (image && this.variant && image.variants[this.variant]?.url) {
        this.imageUrl = this._assetService.getAssetUrl(image.variants[this.variant].url);
      } else {
        this.imageUrl = this.getFallbackImage();
      }
      this.cdr.detectChanges();
    });
  }
  

  getPreviewImage() {
    if (!this.animal?.previewImageId) {
      this.imageUrl = this.getFallbackImage();
      return;
    }
  
    this.isLoading = false;
    this.variant = 'thumbnail';
    this.getAnimalImage(this.animal.previewImageId);
    this.cdr.detectChanges();
  }
  

  getFallbackImage(): string {
    const category = this.animal?.category?.toLowerCase();
    switch (category) {
      case AnimalCategory.BIRD:
        return './assets/images/animals/default_bird.png';
      case AnimalCategory.CAT:
        return './assets/images/animals/default_cat.png';
      case AnimalCategory.DOG:
        return './assets/images/animals/default_dog.png';
        case AnimalCategory.FARM_ANIMAL:
        return './assets/images/animals/default_pig.png';
      case AnimalCategory.REPTILE:
        return './assets/images/animals/default_reptile.png';
      case AnimalCategory.SMALL_ANIMAL:
        return './assets/images/animals/default_small_animal.png';
      case AnimalCategory.WILD_ANIMAL:
        return './assets/images/animals/default_wild_animal.png';
      case AnimalCategory.HORSE:
        return './assets/images/animals/default_horse.png';
      default:
        return './assets/images/animals/default_dog.png';
    }
  }
}

