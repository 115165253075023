import {createStore, select, withProps} from '@ngneat/elf';
import {withRequestsCache, withRequestsStatus} from '@ngneat/elf-requests';
import {Injectable} from "@angular/core";
import {RestService} from "../../../shared/services/rest.service";
import {tap, switchMap, catchError, take} from "rxjs/operators";
import {AnimalWhereaboutModel} from '../models/animal-whereabout.model';
import {Observable, of} from 'rxjs';



export interface AnimalWhereaboutsState {
  whereabouts: Record<number, AnimalWhereaboutModel[]>;
}

export const store = createStore(
  { name: 'animalWhereabouts' },
  withProps<AnimalWhereaboutsState>({ whereabouts: {} }),
  withRequestsCache<'animalWhereabouts'>(),
  withRequestsStatus<'animalWhereabouts'>()
);





@Injectable({providedIn: 'root'})
export class AnimalWhereaboutsRepository {
  constructor(
    private _restService: RestService
  ) {
    // silence is golden
  }

  getByAnimalId(animalId: number): Observable<AnimalWhereaboutModel[]> {
    if (!animalId) {
      return of([]);
    }
  
    return store.pipe(
      select(state => state.whereabouts[animalId] ?? null),
      take(1),
      tap(cachedWhereabouts => {
      }),
      switchMap(cachedWhereabouts => {
        if (cachedWhereabouts !== null) {
          return of(cachedWhereabouts);
        }
        return this._restService.httpGet(`/animal_whereabouts?animal.id=${animalId}`).pipe(
          tap(response => {
            if (!response || !Array.isArray(response)) {
              return;
            }
            store.update(state => ({
              ...state,
              whereabouts: {
                ...state.whereabouts,
                [animalId]: response
              }
            }));
          }),
          catchError(error => {
            return of([]);
          })
        );
      })
    );
  }

  getAll() {
    return this._restService.httpGet('/animal_whereabouts')
      .pipe(
        tap((whereabouts: AnimalWhereaboutModel[]) => {
          if (whereabouts && whereabouts.length > 0) {
            this.setAll(whereabouts);
          }
        })
      );
  }

  setAll(animalWhereabouts: AnimalWhereaboutModel[]) {
    const updatedWhereabouts = animalWhereabouts.reduce((acc, entry) => {
      const animalId = entry.animal?.id;
      if (animalId) {
        acc[animalId] = [...(acc[animalId] || []), entry];
      }
      return acc;
    }, {} as Record<number, AnimalWhereaboutModel[]>);
  
    store.update(state => ({
      ...state,
      whereabouts: {
        ...state.whereabouts,
        ...updatedWhereabouts
      }
    }));
  }


  create(payload: any) {
    return this._restService.httpPost('/animal_whereabouts', payload)
      .pipe(
        tap((response: any) => {
 
          const animalId = response.animal.id;
  
          store.update(state => ({
            ...state,
            whereabouts: {
              ...state.whereabouts,
              [animalId]: [...(state.whereabouts[animalId] || []), response as AnimalWhereaboutModel]
            }
          }));
        })
      );
  }
  
  
  
  

  update(id: number, payload: any) {
    return this._restService.httpPut(`/animal_whereabouts/${id}`, payload)
      .pipe(
        tap((response: any) => { 

          const animalId = response.animal.id;
  
          store.update(state => ({
            ...state,
            whereabouts: {
              ...state.whereabouts,
              [animalId]: state.whereabouts[animalId]?.map(entry =>
                entry.id === id ? (response as AnimalWhereaboutModel) : entry
              ) || []
            }
          }));
        })
      );
  }
  
  

  delete(id: number, animalId: number) {
    return this._restService.httpDelete(`/animal_whereabouts/${id}`)
      .pipe(
        tap(() => {
          store.update(state => ({
            ...state,
            whereabouts: {
              ...state.whereabouts,
              [animalId]: state.whereabouts[animalId]?.filter(entry => entry.id !== id) || []
            }
          }));
        })
      );
  }
  
}
