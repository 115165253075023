import {Injectable} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  private readonly _menuItems: MenuItem[] = [];

  openChangelogBehaviorSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
    this._menuItems = [
      {
        label: 'Dashboard',
        icon: 'custom-icon home',
        routerLink: ['/']
      },
      {
        label: 'Tiere',
        icon: 'custom-icon animal',
        routerLink: ['/tiere']
      },
      {
        label: 'Kontakte',
        icon: 'pi pi-custom pi-address-book',
        routerLink: ['/kontakte']
      }
    ]
  }

  getMenuItems() {
    return this._menuItems;
  }
}
