import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from "@angular/core";
import {AnimalModel} from "../models/animal.model";
import {AnimalMediaModel} from "../models/animal-media.model";
import {BehaviorSubject, take, takeWhile} from "rxjs";
import {AnimalImagesRepository} from "../states/animal-images.repository";
import {AnimalRepository} from '../states/animal.repository';
import {ToastService} from 'src/app/shared/services/toast.service';
import {TranslocoService} from '@jsverse/transloco';
import {ConfirmationService} from "primeng/api";
import {AnimalMediaCategoryModel} from '../models/animal-media-category.model';
import {AnimalImageService} from '../services/animal-image.service';
import { HelperService } from 'src/app/shared/services/helper.service';
import { MediaDownloadService } from 'src/app/shared/services/media-download.service';


@Component({
  selector: 'app-animal-details-images',
  templateUrl: './animal-details-images.component.html',
  styleUrls: ['./animal-details-images.component.scss']
})
export class AnimalDetailsImagesComponent implements OnInit, OnDestroy {

  @Input() animal: AnimalModel | undefined;
  isAlive: boolean = true;
  images: AnimalMediaModel[] | any = [];
  selectedImage: AnimalMediaModel | undefined;
  isEditingMode: boolean = false;
  selectedImgToDelete: number[] = [];

  displayDialog: boolean = false;
  deleteLoading: boolean = false;
  imageCategoryOptions: AnimalMediaCategoryModel[] = [];

  selectedImages: AnimalMediaModel[] = [];
  selectedImagesMap: { [id: number]: boolean } = {};

  constructor(
    private readonly _animalImagesRepository: AnimalImagesRepository,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _animalImageService: AnimalImageService,
    private readonly _animalRepository: AnimalRepository,
    private readonly _translateService: TranslocoService,
    private readonly _toast: ToastService,
    private readonly _confirmationService: ConfirmationService,
    private readonly _helperService: HelperService,
    private readonly _mediaDownloadService: MediaDownloadService
  ) {
  }

  ngOnInit(): void {
    if (!this.animal) {
      return;
    }

    this._animalImagesRepository.getImages(this.animal.id).pipe(take(1)).subscribe((images: AnimalMediaModel[]) => {
      this.images = images;
      this.images = this.images.map((image: any) => ({
        ...image,
        translatedCategories: this.getTranslatedCategories(image.categories),
      }));
    });

    this._animalImagesRepository.imageAdded$.pipe(takeWhile(() => this.isAlive)).subscribe(newImage => {
      if (newImage) {
        this.images.push(newImage);
      }
    });

    this.imageCategoryOptions = this._animalImageService.getAnimalImageCategories();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this._animalImagesRepository.clearImageAdded();
  }

  openDialog(image: AnimalMediaModel) {
    if (image) {
      this.selectedImage = JSON.parse(JSON.stringify(image));
      if (this.selectedImage) {
        this.selectedImage.date = this.selectedImage.date
          ? new Date(this.selectedImage.date)
          : null;
        this.displayDialog = true;
      }
    }
  }

  dummyTranslateFunction() {
    this._translateService.translate('messages.info');
    this._translateService.translate('messages.sharePointSync');
  }

  clickTogglePreview(image: AnimalMediaModel) {
    if (!this.animal) {
      return;
    }
    this.images.forEach((img: AnimalMediaModel) => {
      if (img.id === image.id) {
        img.preview = !img.preview;
        if (this.animal) {
          this._animalRepository.getById(this.animal.id);
        }
      } else {
        img.preview = false;
      }
    });
    this._animalImagesRepository.updateImage(image.id, {preview: image.preview}, this.animal.id);
    this._cdr.detectChanges();
  }


  deleteImage(selectedImage: AnimalMediaModel) {
    this._confirmationService.confirm({
      message: this._translateService.translate('delete_confirmation'),
      acceptLabel: this._translateService.translate('yes'),
      rejectLabel: this._translateService.translate('no'),
      header: '',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass: "p-button-danger p-button-text",
      rejectButtonStyleClass: "p-button-text p-button-text",
      acceptIcon: "none",
      rejectIcon: "none",

      accept: () => {
        this.deleteLoading = true;
        if (!this.animal) {
          return;
        }
        this._animalImagesRepository.deleteImage(selectedImage.id, this.animal.id).pipe(take(1)).subscribe(() => {
          this.deleteLoading = false;
          this.displayDialog = false;
          this.images = this.images.filter((image: AnimalMediaModel) => image.id !== selectedImage.id);
          if (this.animal) {
            this._animalRepository.getById(this.animal.id);
          }
          this._toast.showSuccess(this._translateService.translate('toast.deleteSuccess'));
        });
      },
      reject: () => {
        // rejected
      }
    });
  }

  clickFileExport(image: AnimalMediaModel) {
    if (!this.animal) {
      return;
    }
    image.syncWithSharedFolder = !image.syncWithSharedFolder;
    this._animalImagesRepository.updateImage(image.id, {syncWithSharedFolder: image.syncWithSharedFolder}, this.animal.id);
    this._cdr.detectChanges();
  }

  clickFileSync(image: AnimalMediaModel) {
    if (!this.animal) {
      return;
    }
    image.syncWithWebsite = !image.syncWithWebsite;
    this._animalImagesRepository.updateImage(image.id, {syncWithWebsite: image.syncWithWebsite}, this.animal.id);
    this._cdr.detectChanges();
  }

  getTranslatedCategories(categories: string[] | null | undefined): string {
    if (!categories || categories.length === 0) {
      return '';
    }

    return categories
      .map(category => {
        const key = `animal.imageCategory.${category.toUpperCase()}`;
        return this._translateService.translate(key);
      })
      .join(', ');
  }

  toggleEditDialog() {
    this.displayDialog = false;
  }

  saveImage(image: AnimalMediaModel) {
    if (!this.animal) {
      return;
    }

    this.images = this.images.map((img: AnimalMediaModel) => {
      if (img.id === image.id) {
        return {
          ...img,
          ...image,
          translatedCategories: this.getTranslatedCategories(image.categories)
        };
      } else {
        if (image.preview) {
          return {...img, preview: false};
        }
        return img;
      }
    });
    delete image.animal;
    if (image.date) {
      const date = this._helperService.normalizeDate(new Date(image.date));
      image.date = date;
    }
    this._animalImagesRepository.updateImage(image.id, image, this.animal.id);

    this.displayDialog = false;
    this._cdr.detectChanges();
  }

  onImageSelectChange(image: AnimalMediaModel): void {
    const selected = this.selectedImagesMap[image.id];
  
    if (selected && !this.selectedImages.includes(image)) {
      this.selectedImages.push(image);
    } else if (!selected) {
      this.selectedImages = this.selectedImages.filter(img => img.id !== image.id);
    }
  }

  downloadSelectedImages(): void {
    const baseName = this.animal?.displayName || 'media';
  
    this.selectedImages.forEach((image, index) => {
      const extension = this.getFileExtension(image.contentUrl);
      const numberSuffix = this.selectedImages.length > 1 ? `-${index + 1}` : '';
      const filename = `${baseName}${numberSuffix}.${extension}`;
  
      const url = image.contentUrl || `/api/assets/${image.contentUrl}`;
      this._mediaDownloadService.downloadMedia(url, filename, index * 500); // optional delay
    });
    this.selectedImagesMap = {};
    this.selectedImages = [];
  }
  
  getFileExtension(filePath: string): string {
    return filePath.split('.').pop() || 'jpg';
  }
  
}
